import { css } from '@emotion/react';

export const agreementDialogHeaderCx = css`
  display: flex;
  align-content: center;
  padding: 0;
  margin-bottom: 1rem;

  .agreement-dialog-icon {
    display: flex;
    align-items: center;
    padding: 0.5rem;
  }

  .agreement-dialog-title {
    display: flex;
    align-items: center;
    margin: 0 0 0 0.5rem;
    color: #00acf0;
    font-weight: 700;
    font-size: 1rem;
  }
`;
