import { SVGProps } from "react";

const Document = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="#00ACF0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.25186 1.55142C3.02682 1.77647 2.90039 2.08169 2.90039 2.39995V9.59995C2.90039 9.91821 3.02682 10.2234 3.25186 10.4485C3.47691 10.6735 3.78213 10.8 4.10039 10.8H8.90039C9.21865 10.8 9.52387 10.6735 9.74892 10.4485C9.97396 10.2234 10.1004 9.91821 10.1004 9.59995V4.44835C10.1003 4.13012 9.97385 3.82494 9.74879 3.59995L7.70039 1.55155C7.4754 1.32649 7.17022 1.20002 6.85199 1.19995H4.10039C3.78213 1.19995 3.47691 1.32638 3.25186 1.55142ZM4.27613 5.57569C4.38865 5.46317 4.54126 5.39995 4.70039 5.39995H8.30039C8.45952 5.39995 8.61213 5.46317 8.72465 5.57569C8.83718 5.68821 8.90039 5.84082 8.90039 5.99995C8.90039 6.15908 8.83718 6.31169 8.72465 6.42422C8.61213 6.53674 8.45952 6.59995 8.30039 6.59995H4.70039C4.54126 6.59995 4.38865 6.53674 4.27613 6.42422C4.1636 6.31169 4.10039 6.15908 4.10039 5.99995C4.10039 5.84082 4.1636 5.68821 4.27613 5.57569ZM4.27613 7.97569C4.38865 7.86317 4.54126 7.79995 4.70039 7.79995H8.30039C8.45952 7.79995 8.61213 7.86317 8.72465 7.97569C8.83718 8.08821 8.90039 8.24082 8.90039 8.39995C8.90039 8.55908 8.83718 8.71169 8.72465 8.82422C8.61213 8.93674 8.45952 8.99995 8.30039 8.99995H4.70039C4.54126 8.99995 4.38865 8.93674 4.27613 8.82422C4.1636 8.71169 4.10039 8.55908 4.10039 8.39995C4.10039 8.24082 4.1636 8.08821 4.27613 7.97569Z" />
    </svg>
  );
};

export default Document;
