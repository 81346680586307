import { SVGProps } from 'react';
const LoansExceedsIncome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={328}
    height={110}
    viewBox='0 0 328 110'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M328.99 0H0V111.56H328.99V0Z' fill='#F9DD60' />
    <g opacity={0.4}>
      <path
        d='M145.62 25.2202V13.0802C145.62 8.98221 142.298 5.66016 138.2 5.66016H126.06C121.962 5.66016 118.64 8.98221 118.64 13.0802V25.2202C118.64 29.3181 121.962 32.6402 126.06 32.6402H138.2C142.298 32.6402 145.62 29.3181 145.62 25.2202Z'
        fill='#F6C957'
      />
      <path
        d='M184.18 25.2302V13.0902C184.18 8.9922 180.858 5.67015 176.76 5.67015H164.62C160.522 5.67015 157.2 8.9922 157.2 13.0902V25.2302C157.2 29.3281 160.522 32.6502 164.62 32.6502H176.76C180.858 32.6502 184.18 29.3281 184.18 25.2302Z'
        fill='#F6C957'
      />
      <path
        d='M166.14 60.5805V48.4405C166.14 44.3426 162.818 41.0205 158.72 41.0205H146.58C142.482 41.0205 139.16 44.3426 139.16 48.4405V60.5805C139.16 64.6785 142.482 68.0005 146.58 68.0005H158.72C162.818 68.0005 166.14 64.6785 166.14 60.5805Z'
        fill='#F6C957'
      />
      <path
        d='M125.19 59.2397V47.0997C125.19 43.0017 121.868 39.6797 117.77 39.6797H105.63C101.532 39.6797 98.2099 43.0017 98.2099 47.0997V59.2397C98.2099 63.3376 101.532 66.6597 105.63 66.6597H117.77C121.868 66.6597 125.19 63.3376 125.19 59.2397Z'
        fill='#F6C957'
      />
      <path
        d='M107.15 94.5902V82.4502C107.15 78.3522 103.828 75.0302 99.73 75.0302H87.59C83.4921 75.0302 80.17 78.3522 80.17 82.4502V94.5902C80.17 98.6881 83.4921 102.01 87.59 102.01H99.73C103.828 102.01 107.15 98.6881 107.15 94.5902Z'
        fill='#F6C957'
      />
      <path
        d='M147.2 94.5902V82.4502C147.2 78.3522 143.878 75.0302 139.78 75.0302H127.64C123.542 75.0302 120.22 78.3522 120.22 82.4502V94.5902C120.22 98.6881 123.542 102.01 127.64 102.01H139.78C143.878 102.01 147.2 98.6881 147.2 94.5902Z'
        fill='#F6C957'
      />
      <path
        d='M184.01 94.6002V82.4602C184.01 78.3622 180.688 75.0402 176.59 75.0402H164.45C160.352 75.0402 157.03 78.3622 157.03 82.4602V94.6002C157.03 98.6981 160.352 102.02 164.45 102.02H176.59C180.688 102.02 184.01 98.6981 184.01 94.6002Z'
        fill='#F6C957'
      />
      <path
        d='M65.79 25.2202V13.0802C65.79 8.98221 62.468 5.66016 58.37 5.66016H46.23C42.1321 5.66016 38.81 8.98221 38.81 13.0802V25.2202C38.81 29.3181 42.1321 32.6402 46.23 32.6402H58.37C62.468 32.6402 65.79 29.3181 65.79 25.2202Z'
        fill='#F6C957'
      />
      <path
        d='M27.88 25.2202V13.0802C27.88 8.98221 24.558 5.66016 20.46 5.66016H8.32001C4.22205 5.66016 0.900005 8.98221 0.900005 13.0802V25.2202C0.900005 29.3181 4.22205 32.6402 8.32001 32.6402H20.46C24.558 32.6402 27.88 29.3181 27.88 25.2202Z'
        fill='#F6C957'
      />
      <path
        d='M105.82 25.2202V13.0802C105.82 8.98221 102.498 5.66016 98.4001 5.66016H86.2601C82.1621 5.66016 78.8401 8.98221 78.8401 13.0802V25.2202C78.8401 29.3181 82.1621 32.6402 86.2601 32.6402H98.4001C102.498 32.6402 105.82 29.3181 105.82 25.2202Z'
        fill='#F6C957'
      />
      <path
        d='M86.4399 59.2397V47.0997C86.4399 43.0017 83.1179 39.6797 79.0199 39.6797H66.8799C62.782 39.6797 59.4599 43.0017 59.4599 47.0997V59.2397C59.4599 63.3376 62.782 66.6597 66.8799 66.6597H79.0199C83.1179 66.6597 86.4399 63.3376 86.4399 59.2397Z'
        fill='#F6C957'
      />
      <path
        d='M48.16 60.5698V48.4298C48.16 44.3318 44.838 41.0098 40.74 41.0098H28.6C24.5021 41.0098 21.18 44.3318 21.18 48.4298V60.5698C21.18 64.6677 24.5021 67.9898 28.6 67.9898H40.74C44.838 67.9898 48.16 64.6677 48.16 60.5698Z'
        fill='#F6C957'
      />
      <path
        d='M28.78 94.6002V82.4602C28.78 78.3622 25.458 75.0402 21.36 75.0402H9.22003C5.12208 75.0402 1.80003 78.3622 1.80003 82.4602V94.6002C1.80003 98.6981 5.12208 102.02 9.22003 102.02H21.36C25.458 102.02 28.78 98.6981 28.78 94.6002Z'
        fill='#F6C957'
      />
      <path
        d='M67.49 93.2602V81.1202C67.49 77.0222 64.1679 73.7002 60.07 73.7002H47.93C43.832 73.7002 40.51 77.0222 40.51 81.1202V93.2602C40.51 97.3581 43.832 100.68 47.93 100.68H60.07C64.1679 100.68 67.49 97.3581 67.49 93.2602Z'
        fill='#F6C957'
      />
    </g>
    <g opacity={0.4}>
      <path
        d='M284.15 26.1704V14.0303C284.15 9.93239 280.828 6.61036 276.73 6.61036H264.59C260.492 6.61036 257.17 9.93239 257.17 14.0303V26.1704C257.17 30.2683 260.492 33.5904 264.59 33.5904H276.73C280.828 33.5904 284.15 30.2683 284.15 26.1704Z'
        fill='#F6C957'
      />
      <path
        d='M322.71 26.1704V14.0303C322.71 9.93239 319.388 6.61036 315.29 6.61036H303.15C299.052 6.61036 295.73 9.93239 295.73 14.0303V26.1704C295.73 30.2683 299.052 33.5903 303.15 33.5903H315.29C319.388 33.5903 322.71 30.2683 322.71 26.1704Z'
        fill='#F6C957'
      />
      <path
        d='M304.68 61.5307V49.3907C304.68 45.2927 301.358 41.9707 297.26 41.9707H285.12C281.022 41.9707 277.7 45.2927 277.7 49.3907V61.5307C277.7 65.6287 281.022 68.9507 285.12 68.9507H297.26C301.358 68.9507 304.68 65.6287 304.68 61.5307Z'
        fill='#F6C957'
      />
      <path
        d='M263.72 60.1899V48.0499C263.72 43.9519 260.398 40.6299 256.3 40.6299H244.16C240.062 40.6299 236.74 43.9519 236.74 48.0499V60.1899C236.74 64.2878 240.062 67.6099 244.16 67.6099H256.3C260.398 67.6099 263.72 64.2878 263.72 60.1899Z'
        fill='#F6C957'
      />
      <path
        d='M245.68 95.5503V83.4103C245.68 79.3124 242.358 75.9903 238.26 75.9903H226.12C222.022 75.9903 218.7 79.3124 218.7 83.4103V95.5503C218.7 99.6483 222.022 102.97 226.12 102.97H238.26C242.358 102.97 245.68 99.6483 245.68 95.5503Z'
        fill='#F6C957'
      />
      <path
        d='M285.73 95.5403V83.4004C285.73 79.3024 282.408 75.9803 278.31 75.9803H266.17C262.072 75.9803 258.75 79.3024 258.75 83.4004V95.5403C258.75 99.6383 262.072 102.96 266.17 102.96H278.31C282.408 102.96 285.73 99.6383 285.73 95.5403Z'
        fill='#F6C957'
      />
      <path
        d='M322.54 95.5403V83.4004C322.54 79.3024 319.218 75.9803 315.12 75.9803H302.98C298.882 75.9803 295.56 79.3024 295.56 83.4004V95.5403C295.56 99.6383 298.882 102.96 302.98 102.96H315.12C319.218 102.96 322.54 99.6383 322.54 95.5403Z'
        fill='#F6C957'
      />
      <path
        d='M204.32 26.1803V14.0403C204.32 9.94239 200.998 6.62035 196.9 6.62035H184.76C180.662 6.62035 177.34 9.94239 177.34 14.0403V26.1803C177.34 30.2783 180.662 33.6004 184.76 33.6004H196.9C200.998 33.6004 204.32 30.2783 204.32 26.1803Z'
        fill='#F6C957'
      />
      <path
        d='M166.41 26.1804V14.0404C166.41 9.9424 163.088 6.62034 158.99 6.62034H146.85C142.752 6.62034 139.43 9.9424 139.43 14.0404V26.1804C139.43 30.2783 142.752 33.6004 146.85 33.6004H158.99C163.088 33.6004 166.41 30.2783 166.41 26.1804Z'
        fill='#F6C957'
      />
      <path
        d='M244.35 26.1703V14.0303C244.35 9.9324 241.028 6.61035 236.93 6.61035H224.79C220.692 6.61035 217.37 9.9324 217.37 14.0303V26.1703C217.37 30.2683 220.692 33.5903 224.79 33.5903H236.93C241.028 33.5903 244.35 30.2683 244.35 26.1703Z'
        fill='#F6C957'
      />
      <path
        d='M224.97 60.1899V48.0499C224.97 43.9519 221.648 40.6299 217.55 40.6299H205.41C201.312 40.6299 197.99 43.9519 197.99 48.0499V60.1899C197.99 64.2878 201.312 67.6099 205.41 67.6099H217.55C221.648 67.6099 224.97 64.2878 224.97 60.1899Z'
        fill='#F6C957'
      />
      <path
        d='M186.69 61.52V49.38C186.69 45.282 183.368 41.96 179.27 41.96H167.13C163.032 41.96 159.71 45.282 159.71 49.38V61.52C159.71 65.6179 163.032 68.94 167.13 68.94H179.27C183.368 68.94 186.69 65.6179 186.69 61.52Z'
        fill='#F6C957'
      />
      <path
        d='M167.31 95.5404V83.4004C167.31 79.3024 163.988 75.9804 159.89 75.9804H147.75C143.652 75.9804 140.33 79.3024 140.33 83.4004V95.5404C140.33 99.6383 143.652 102.96 147.75 102.96H159.89C163.988 102.96 167.31 99.6383 167.31 95.5404Z'
        fill='#F6C957'
      />
      <path
        d='M206.02 94.2104V82.0704C206.02 77.9724 202.698 74.6503 198.6 74.6503H186.46C182.362 74.6503 179.04 77.9724 179.04 82.0704V94.2104C179.04 98.3083 182.362 101.63 186.46 101.63H198.6C202.698 101.63 206.02 98.3083 206.02 94.2104Z'
        fill='#F6C957'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M221 81.6101L221.85 82.5001C222.85 83.5601 221.98 86.0901 219.85 88.1301C217.72 90.1701 215.17 90.9801 214.14 89.9201L213.3 89.0001L221 81.5801'
      fill='#F6C957'
    />
    <path
      d='M222.22 83.1797L220.85 81.7597L220.92 81.6797L222.14 82.9397C222.171 83.0181 222.198 83.0983 222.22 83.1797Z'
      fill='#F09537'
    />
    <path
      d='M222.28 83.6397L220.65 81.9997L220.72 81.9297L222.27 83.5297C222.265 83.5829 222.265 83.6365 222.27 83.6897'
      fill='#F09537'
    />
    <path
      d='M222.24 84.1701L220.36 82.2301L220.47 82.1201L222.26 84.0001C222.265 84.0633 222.265 84.1269 222.26 84.1901'
      fill='#F09537'
    />
    <path
      d='M222.1 84.7404L220 82.5704L220.13 82.4404L222.13 84.5404L222.07 84.7404'
      fill='#F09537'
    />
    <path
      d='M219.55 82.9997L219.7 82.8497L218.33 81.4297L218.18 81.5797L219.55 82.9997Z'
      fill='#F09537'
    />
    <path
      d='M221.85 85.4006L219.55 83.0006L219.7 82.8506L221.94 85.1706C221.921 85.2478 221.89 85.3218 221.85 85.3906'
      fill='#F09537'
    />
    <path
      d='M219.08 83.4599L219.25 83.2999L217.88 81.8799L217.71 82.0399L219.08 83.4599Z'
      fill='#F09537'
    />
    <path
      d='M221.54 86L219.08 83.45L219.25 83.29L221.66 85.79L221.54 86Z'
      fill='#F09537'
    />
    <path
      d='M218.59 83.9301L218.78 83.7501L217.41 82.3301L217.22 82.5101L218.59 83.9301Z'
      fill='#F09537'
    />
    <path
      d='M221.17 86.6L218.59 83.93L218.78 83.75L221.31 86.38C221.269 86.4569 221.222 86.5305 221.17 86.6Z'
      fill='#F09537'
    />
    <path
      d='M218.09 84.4098L218.29 84.2198L216.92 82.7998L216.72 82.9898L218.09 84.4098Z'
      fill='#F09537'
    />
    <path
      d='M220.74 87.1697L218.09 84.4097L218.29 84.2197L220.92 86.9997L220.74 87.2197'
      fill='#F09537'
    />
    <path
      d='M217.57 84.9103L217.79 84.7003L216.42 83.2803L216.2 83.4903L217.57 84.9103Z'
      fill='#F09537'
    />
    <path
      d='M220.27 87.7102L217.57 84.9102L217.79 84.7002L220.47 87.4902L220.27 87.7102Z'
      fill='#F09537'
    />
    <path
      d='M217 85.4303L217.23 85.2003L215.86 83.7803L215.63 84.0103L217 85.4303Z'
      fill='#F09537'
    />
    <path
      d='M219.75 88.2402L217 85.4302L217.23 85.2002L220 88.0002L219.88 88.1202L219.77 88.2302'
      fill='#F09537'
    />
    <path
      d='M216.52 85.9198L216.74 85.7198L215.37 84.2998L215.16 84.4998L216.52 85.9198Z'
      fill='#F09537'
    />
    <path
      d='M219.21 88.7097L216.52 85.9197L216.74 85.7197L219.44 88.5197L219.21 88.7097Z'
      fill='#F09537'
    />
    <path
      d='M216 86.41L216.2 86.21L214.83 84.79L214.63 84.99L216 86.41Z'
      fill='#F09537'
    />
    <path
      d='M218.65 89.13L216 86.41L216.2 86.21L218.88 89L218.65 89.16'
      fill='#F09537'
    />
    <path
      d='M215.54 86.8803L215.72 86.7003L214.35 85.2803L214.17 85.4603L215.54 86.8803Z'
      fill='#F09537'
    />
    <path
      d='M218.07 89.5102L215.54 86.8802L215.72 86.7002L218.3 89.3702L218.07 89.5102Z'
      fill='#F09537'
    />
    <path
      d='M215.06 87.33L215.23 87.17L213.87 85.75L213.7 85.91L215.06 87.33Z'
      fill='#F09537'
    />
    <path
      d='M217.48 89.8299L215.06 87.3299L215.23 87.1699L217.7 89.7199L217.48 89.8299Z'
      fill='#F09537'
    />
    <path
      d='M214.61 87.7702L214.76 87.6202L213.39 86.2002L213.24 86.3502L214.61 87.7702Z'
      fill='#F09537'
    />
    <path
      d='M216.86 90.1001L214.61 87.7701L214.76 87.6201L217.07 90.0201L216.86 90.1001Z'
      fill='#F09537'
    />
    <path
      d='M216.21 90.3006L214.21 88.1906L214.34 88.0605L216.44 90.2405L216.24 90.3006'
      fill='#F09537'
    />
    <path
      d='M215.65 90.3804L213.84 88.5104L213.95 88.4004L215.84 90.4004H215.65'
      fill='#F09537'
    />
    <path
      d='M215.13 90.3704L213.58 88.7604L213.66 88.6904L215.29 90.3804H215.13'
      fill='#F09537'
    />
    <path
      d='M214.64 90.2399L213.39 88.9999L213.47 88.9199L214.86 90.3699L214.64 90.2899'
      fill='#F09537'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M221 81.6099C220 80.5499 217.42 81.3499 215.29 83.3999C213.16 85.4499 212.29 87.9699 213.29 89.0299C214.29 90.0899 216.87 89.2899 218.99 87.2499C221.11 85.2099 221.99 82.6799 220.99 81.6099H221Z'
      fill='#F9DD60'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M221 81.6099C220 80.5499 217.42 81.3499 215.29 83.3999C213.16 85.4499 212.29 87.9699 213.29 89.0299C214.29 90.0899 216.87 89.2899 218.99 87.2499C221.11 85.2099 221.99 82.6799 220.99 81.6099'
      fill='#F9DD60'
    />
    <path
      d='M213.53 89.2302L215.44 83.2302C215.637 83.0425 215.844 82.8656 216.06 82.7002L213.92 89.3802C213.785 89.3324 213.654 89.2721 213.53 89.2002'
      fill='#FAEFC3'
    />
    <path
      d='M214.7 89.4997L217.09 81.9997C217.437 81.797 217.798 81.6199 218.17 81.4697L215.66 89.3197C215.335 89.4186 214.999 89.4757 214.66 89.4897'
      fill='#FAEFC3'
    />
    <path
      d='M216 89.2204L218.51 81.3504C218.73 81.2841 218.954 81.2306 219.18 81.1904L216.71 88.9004C216.48 89.0218 216.243 89.1286 216 89.2204Z'
      fill='#FAEFC3'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M220.23 82.3599C220.94 83.0999 220.15 85.0299 218.45 86.6699C216.75 88.3099 214.79 89.0299 214.07 88.2899C213.35 87.5499 214.15 85.6099 215.85 83.9799C217.55 82.3499 219.51 81.6099 220.23 82.3599Z'
      fill='#F09537'
    />
    <mask
      id='mask0_2800_4010'
      style={{
        maskType: 'luminance',
      }}
      maskUnits='userSpaceOnUse'
      x={213}
      y={82}
      width={8}
      height={7}
    >
      <path
        d='M219.99 83.1137C219.991 82.8913 219.932 82.7717 219.869 82.7062L219.99 83.1137ZM219.99 83.1137C219.99 83.3392 219.926 83.635 219.781 83.987M219.99 83.1137L219.781 83.987M219.781 83.987C219.492 84.6895 218.915 85.5266 218.103 86.3101M219.781 83.987L218.103 86.3101M218.103 86.3101C217.291 87.0935 216.433 87.6399 215.719 87.9034M218.103 86.3101L215.719 87.9034M215.719 87.9034C215.362 88.0353 215.063 88.0879 214.837 88.0799M215.719 87.9034L214.837 88.0799M214.837 88.0799C214.613 88.0721 214.494 88.0083 214.428 87.9412M214.837 88.0799L214.428 87.9412M214.428 87.9412C214.365 87.8757 214.305 87.7564 214.305 87.534M214.428 87.9412L214.305 87.534M214.305 87.534C214.306 87.3083 214.369 87.012 214.515 86.6595M214.305 87.534L214.515 86.6595M214.515 86.6595C214.805 85.9559 215.384 85.1191 216.196 84.3408M214.515 86.6595L216.196 84.3408M216.196 84.3408C217.009 83.5613 217.868 83.0119 218.582 82.746M216.196 84.3408L218.582 82.746M218.582 82.746C218.94 82.6128 219.238 82.5594 219.464 82.5671M218.582 82.746L219.464 82.5671M219.464 82.5671C219.686 82.5747 219.804 82.6383 219.869 82.7061L219.464 82.5671Z'
        fill='white'
        stroke='white'
      />
    </mask>
    <g mask='url(#mask0_2800_4010)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M220.33 82.5295C219.61 81.7795 217.65 82.5295 215.95 84.1395C214.25 85.7495 213.46 87.7095 214.18 88.4595C214.9 89.2095 216.86 88.4595 218.56 86.8495C220.26 85.2395 221.06 83.2695 220.33 82.5295Z'
        fill='#F9DD60'
      />
    </g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M220.27 82.4997C220.95 83.2097 220.2 85.0497 218.59 86.5997C217.901 87.2753 217.103 87.8298 216.23 88.2397C216.698 86.1869 217.484 84.2198 218.56 82.4097C219.3 82.1397 219.93 82.1497 220.27 82.4997Z'
      fill='#F6C957'
    />
    <path
      d='M195.09 139.05C188.29 157.18 184.73 185.56 182.5 197.51L131.86 184.74C145.054 164.459 150.892 140.266 148.4 116.2L184.23 125.2L208 65.3L224.76 62.54C226.39 67.29 220.25 88.04 218.88 92.6C217.82 96.09 204.66 113.53 195.09 139.05Z'
      fill='#FFC6AC'
    />
    <path
      d='M131.115 113.891L158.893 3.70855C159.363 1.84492 161.254 0.71502 163.118 1.18486L215.169 14.3075C217.033 14.7774 218.163 16.669 217.693 18.5327L189.915 128.715C189.445 130.579 187.553 131.709 185.69 131.239L133.638 118.116C131.775 117.646 130.645 115.755 131.115 113.891Z'
      fill='#231F20'
      stroke='#231F20'
    />
    <path
      d='M158.408 3.58632L130.63 113.769C130.093 115.9 131.385 118.064 133.516 118.601L185.568 131.724C187.699 132.261 189.862 130.969 190.4 128.837L218.178 18.6549C218.715 16.5235 217.423 14.3601 215.292 13.8227L163.24 0.700034C161.109 0.162685 158.945 1.45492 158.408 3.58632Z'
      fill='#006790'
    />
    <path
      d='M159.924 8.86172L133.307 114.438C133.141 115.097 133.54 115.765 134.199 115.932L186.231 129.049C186.889 129.215 187.558 128.816 187.724 128.157L214.341 22.5809C214.507 21.9222 214.108 21.2536 213.449 21.0875L161.417 7.96972C160.758 7.80365 160.09 8.20302 159.924 8.86172Z'
      fill='#3EAFCE'
    />
    <path
      opacity={0.39}
      d='M141.75 93.1701L200.49 70.3301L195.83 87.2301L135.93 113.56L141.75 93.1701Z'
      fill='white'
    />
    <path
      opacity={0.39}
      d='M145.52 81.0897L203.98 59.7197L200.84 68.1497L141.99 91.5797L145.52 81.0897Z'
      fill='white'
    />
    <path
      d='M156.12 12.6297L150.64 34.3497L141.37 14.2397C140.75 12.8834 140.609 11.3566 140.97 9.90968C141.274 8.69858 141.918 7.59993 142.827 6.74393C143.736 5.88793 144.871 5.31048 146.099 5.07999C147.326 4.84951 148.593 4.97567 149.751 5.44352C150.908 5.91137 151.908 6.7013 152.63 7.71968L156.12 12.6297Z'
      fill='#FFC6AC'
    />
    <path
      d='M156.12 12.63L150.64 34.35L148.21 29.08L155.17 11.29L156.12 12.63Z'
      fill='#F09537'
    />
    <path
      d='M139.74 71.3801C139.74 71.3801 130.47 71.1401 128.86 77.5901C127.64 82.4801 130.41 88.1801 135.41 89.6901C138.87 90.6901 144.08 89.6901 145.19 85.3701C146.19 81.4401 144.35 77.2501 140.5 74.6101L141.22 71.7601L139.74 71.3801Z'
      fill='#FFC6AC'
    />
    <path
      d='M137 70.9102C135.094 69.9302 133.58 68.3275 132.71 66.3683C131.841 64.4091 131.668 62.2113 132.22 60.1402C134.35 49.7802 144.43 50.1402 144.43 50.1402L146.52 50.5502L145.12 56.1102C152.59 60.4302 149.21 77.7102 137 70.9102Z'
      fill='#FFC6AC'
    />
    <path
      d='M151.06 46.3897C150.594 47.5548 149.83 48.5773 148.845 49.3552C147.861 50.1332 146.689 50.6395 145.448 50.8237C144.206 51.0078 142.938 50.8633 141.77 50.4047C140.602 49.946 139.574 49.1892 138.79 48.2097C136.68 45.5897 135.6 42.4197 137.32 38.8797C142.24 28.8197 150.79 33.7597 150.79 33.7597L150.21 36.0597C150.21 36.0597 153.21 39.6797 151.31 45.6897C151.23 45.9297 151.15 46.1597 151.06 46.3897Z'
      fill='#FFC6AC'
    />
    <path
      d='M211.5 16.7106C211.379 17.1916 211.118 17.6258 210.75 17.9585C210.382 18.2912 209.924 18.5074 209.433 18.58C208.942 18.6525 208.441 18.578 207.993 18.3659C207.545 18.1538 207.169 17.8136 206.914 17.3883C206.659 16.963 206.535 16.4716 206.559 15.9762C206.583 15.4808 206.753 15.0035 207.048 14.6047C207.343 14.2059 207.749 13.9033 208.216 13.7353C208.683 13.5673 209.189 13.5413 209.67 13.6606C210.316 13.8236 210.872 14.236 211.215 14.8077C211.558 15.3793 211.66 16.0636 211.5 16.7106Z'
      fill='#231F20'
    />
    <path
      d='M204.41 16.2201C205.128 16.2201 205.71 15.6381 205.71 14.9201C205.71 14.2021 205.128 13.6201 204.41 13.6201C203.692 13.6201 203.11 14.2021 203.11 14.9201C203.11 15.6381 203.692 16.2201 204.41 16.2201Z'
      fill='#231F20'
    />
    <path
      d='M179.54 10.0004L196.54 14.2804C196.868 14.3475 197.21 14.2853 197.493 14.1067C197.777 13.9282 197.98 13.6471 198.061 13.3221C198.143 12.997 198.095 12.6532 197.929 12.3622C197.763 12.0713 197.491 11.8557 197.17 11.7604L180.17 7.48043C179.836 7.39556 179.482 7.44692 179.185 7.6232C178.889 7.79949 178.675 8.08626 178.59 8.42043C178.547 8.58661 178.537 8.75974 178.562 8.92969C178.586 9.09965 178.644 9.26299 178.733 9.41015C178.821 9.55731 178.938 9.68532 179.077 9.78668C179.215 9.88804 179.373 9.96071 179.54 10.0004Z'
      fill='#231F20'
    />
    <path
      d='M209.43 44.2698C207.5 40.9098 195.74 49.0398 210.25 70.9898L224.76 62.5398C222.28 59.7298 215.45 54.7698 209.43 44.2698Z'
      fill='#FFC6AC'
    />
    <path
      d='M142.1 53.9297C142.1 53.9297 152.2 57.4297 148.1 67.9297'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M150 34.8203C150 34.8203 157.15 49.2703 145.18 50.5203'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M138.06 73.5098C138.06 73.5098 148.16 77.0098 144.06 87.5098'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M203.56 76.54C203.56 76.54 208.22 60.85 213.35 58.21'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
    >
      <path
        d='M196.924 35.4605L155.552 25.9091C151.21 24.9065 146.876 27.6142 145.874 31.9569L136.248 73.6502C135.245 77.9929 137.953 82.3261 142.296 83.3287L183.668 92.8802C188.01 93.8827 192.344 91.1751 193.346 86.8323L202.972 45.139C203.974 40.7963 201.267 36.4631 196.924 35.4605Z'
        fill='#3EAFCE'
      />
    </g>
    <path
      d='M194.973 32.2242L153.601 22.6728C149.258 21.6702 144.925 24.3779 143.923 28.7206L134.297 70.4139C133.294 74.7566 136.002 79.0898 140.345 80.0924L181.716 89.6438C186.059 90.6464 190.392 87.9387 191.395 83.596L201.021 41.9027C202.023 37.56 199.316 33.2268 194.973 32.2242Z'
      fill='white'
    />
    <path
      d='M165 74.8405L181.46 78.5605C182.198 78.7661 182.973 78.8109 183.73 78.6919C184.487 78.5728 185.21 78.2927 185.85 77.8705C186.818 77.2242 187.541 76.2719 187.904 75.166C188.266 74.06 188.247 72.8644 187.85 71.7705C185.183 63.1905 182.517 54.6138 179.85 46.0405C179.05 43.4505 178.31 40.8505 177.38 38.3105C177.211 37.8343 176.947 37.3978 176.603 37.028C176.259 36.6582 175.843 36.3629 175.38 36.1605C172.56 34.9605 170.47 35.6305 168.8 37.4505C162.8 43.9638 156.8 50.4705 150.8 56.9705C149.01 58.9105 147.17 60.8205 145.45 62.8305C145.004 63.3411 144.675 63.9432 144.487 64.5946C144.298 65.2459 144.255 65.9306 144.36 66.6005C144.487 67.7261 144.979 68.7791 145.761 69.5988C146.543 70.4185 147.572 70.96 148.69 71.1405L165 74.8405Z'
      fill='#D52E57'
    />
    <path
      d='M163.44 72.5997L179.63 76.2697C180.357 76.4734 181.12 76.5172 181.866 76.3981C182.611 76.279 183.322 75.9998 183.95 75.5797C184.91 74.9505 185.629 74.016 185.992 72.9272C186.355 71.8384 186.34 70.659 185.95 69.5797C183.317 61.1397 180.693 52.6997 178.08 44.2597C177.29 41.7197 176.56 39.1497 175.64 36.6597C175.47 36.1882 175.204 35.7568 174.86 35.3921C174.516 35.0274 174.101 34.7373 173.64 34.5397C170.86 33.3597 168.8 34.0197 167.16 35.8097L149.42 54.9997C147.65 56.9097 145.85 58.7897 144.15 60.7597C143.714 61.2659 143.392 61.8604 143.208 62.5025C143.023 63.1447 142.979 63.8192 143.08 64.4797C143.197 65.5959 143.677 66.6428 144.446 67.4595C145.216 68.2761 146.233 68.8173 147.34 68.9997L163.44 72.5997Z'
      fill='#EE7988'
    />
    <path
      d='M173.44 48.1602C172.387 51.7802 171.34 55.3935 170.3 59.0002C170.188 59.5055 170.045 60.0032 169.87 60.4902C169.684 60.9511 169.338 61.3289 168.894 61.5533C168.451 61.7777 167.941 61.8335 167.46 61.7102C166.984 61.6096 166.561 61.3388 166.27 60.9485C165.979 60.5583 165.84 60.0753 165.88 59.5902C166.03 58.1902 166.29 56.8002 166.52 55.4002C166.98 52.5602 167.45 49.7202 167.9 46.8802C168.28 44.5002 170.74 43.3202 172.53 44.7002C173.042 45.0955 173.41 45.6493 173.574 46.2752C173.739 46.9011 173.692 47.564 173.44 48.1602Z'
      fill='#D52E57'
    />
    <path
      d='M165.83 69.1402C165.125 68.9539 164.52 68.5017 164.141 67.8786C163.762 67.2555 163.639 66.5099 163.798 65.7983C163.958 65.0867 164.386 64.4644 164.994 64.062C165.602 63.6596 166.343 63.5084 167.06 63.6402C167.765 63.8265 168.37 64.2786 168.749 64.9017C169.128 65.5248 169.251 66.2704 169.092 66.9821C168.932 67.6937 168.504 68.316 167.896 68.7184C167.288 69.1208 166.547 69.2719 165.83 69.1402Z'
      fill='#D52E57'
    />
    <path
      d='M172.48 47.3597L169.35 58.2297C169.234 58.7338 169.09 59.2312 168.92 59.7197C168.733 60.1793 168.386 60.5557 167.943 60.7799C167.5 61.004 166.991 61.0608 166.51 60.9397C166.032 60.8412 165.606 60.5713 165.314 60.1808C165.021 59.7903 164.881 59.3062 164.92 58.8197C165.08 57.4197 165.34 56.0297 165.56 54.6297C166.02 51.7897 166.5 48.9497 166.95 46.1097C167.32 43.7297 169.79 42.5497 171.58 43.9297C172.087 44.3224 172.45 44.8715 172.613 45.4918C172.776 46.1121 172.729 46.7687 172.48 47.3597Z'
      fill='white'
    />
    <path
      d='M164.88 68.3404C164.175 68.1541 163.57 67.7019 163.191 67.0788C162.812 66.4557 162.689 65.7101 162.848 64.9985C163.008 64.2869 163.436 63.6646 164.044 63.2622C164.652 62.8598 165.393 62.7086 166.11 62.8404C166.815 63.0267 167.42 63.4788 167.799 64.1019C168.178 64.725 168.301 65.4706 168.142 66.1822C167.982 66.8939 167.554 67.5162 166.946 67.9186C166.338 68.321 165.597 68.4721 164.88 68.3404Z'
      fill='white'
    />
    <path
      d='M111.67 96.6005L105.49 111.17C106.118 110.019 107.013 109.034 108.1 108.3C108.1 108.3 109.1 100.81 111.92 96.9005C113.45 94.8105 115.43 93.9005 117.61 95.3505L118.36 103.63L118.99 107.12C118.99 107.12 120.29 110.06 120.14 111.7C119.99 113.34 119.24 114.76 116.85 116.7C115.232 118.28 113.791 120.03 112.55 121.92L59 127.63V127.45C61.1068 112.777 65.373 98.4954 71.66 85.0705L71.9 84.5805C76.74 74.9505 82.83 78.2905 82.83 78.2905C82.83 78.2905 85.66 72.8705 90.48 73.9605C91.4154 74.1826 92.2922 74.6029 93.0512 75.1929C93.8103 75.7828 94.4339 76.5288 94.88 77.3805L96.73 75.0005C100.93 69.4705 109.39 74.7405 106.96 81.3605L104.6 87.7705L117.78 63.0005C118.043 62.4752 118.406 62.0068 118.85 61.622C119.294 61.2372 119.809 60.9435 120.366 60.7578C120.923 60.5721 121.511 60.4979 122.097 60.5396C122.683 60.5812 123.255 60.7378 123.78 61.0005C124.305 61.2631 124.774 61.6266 125.158 62.0703C125.543 62.514 125.837 63.0291 126.023 63.5862C126.208 64.1434 126.283 64.7317 126.241 65.3175C126.199 65.9033 126.043 66.4752 125.78 67.0005L111.67 96.6005Z'
      fill='#FFC6AC'
    />
    <path
      d='M118.36 103.63C118.13 102.44 118.44 98.3405 117.66 95.9805C116.88 95.5105 115.73 96.6205 115.54 97.8205C115.14 100.45 116.69 103.13 118.36 103.63Z'
      fill='#F5B49C'
    />
    <path
      d='M92.13 84.1097C92.25 83.8297 92.4 83.5297 92.57 83.2197L92.13 84.1097Z'
      fill='#F09537'
    />
    <path
      d='M81.5801 80.94C81.5801 80.94 84.4001 72.87 89.5801 73.87C94.7601 74.87 95.0001 77.5 95.0001 77.5'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M93.4 81.1903C93.4 81.1903 96.61 72.1903 101.51 72.5103C106.41 72.8303 109.31 78.3703 106.08 84.1603'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M68.05 93.1898C68.05 93.1898 73.05 74.6398 82.84 78.1898'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M103.59 89.6702L117.1 64.3102C117.1 64.3102 119.71 59.0402 123.79 61.0102'
      stroke='#D5825B'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default LoansExceedsIncome;
