import * as React from 'react';
import { SVGProps } from 'react';
const PrivacyPolicy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={51}
    height={51}
    viewBox='0 0 51 51'
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#C0F9FC'
        d='M34.5 48h-21C6.05 48 0 41.95 0 34.5v-21C0 6.05 6.05 0 13.5 0h21C41.9 0 48 6.05 48 13.5v21C48 41.95 41.95 48 34.5 48Z'
      />
      <path
        fill='#8CE6F4'
        d='M33 45H14.95c-6.9 0-12.5-5.6-12.5-12.45v-.05c0-6.9 5.6-12.5 12.5-12.5H33c6.9 0 12.45 5.6 12.45 12.45v.05C45.45 39.4 39.9 45 33 45Z'
      />
      <path
        fill='#11748E'
        d='M36.75 3.4H11.2c-2.15 0-3.9 1.75-3.9 3.9v39.15c1.85.95 3.95 1.5 6.15 1.5H34.5c2.2 0 4.3-.55 6.15-1.5V7.35c0-2.15-1.75-3.95-3.9-3.95Z'
      />
      <path
        fill='#00ACF0'
        d='M35.75 4.95h-23.6c-1.8 0-3.25 1.45-3.25 3.25v38.95c1.4.5 2.95.8 4.5.8h21.1c1.6 0 3.1-.3 4.5-.8V8.25c0-1.8-1.45-3.3-3.25-3.3Z'
      />
      <path
        fill='#fff'
        d='M13.45 47.95h5.4l16.9-43h-23.6c-1.8 0-3.25 1.45-3.25 3.25v38.95c1.45.55 2.95.8 4.55.8Z'
        opacity={0.15}
      />
      <path
        fill='#2291AA'
        d='M34.5 43H14.15c-.2 0-.35-.05-.55-.1-.15-.05-.35-.2-.45-.3-.15-.15-.25-.3-.3-.45-.05-.15-.1-.35-.1-.55V21.15c0-.15.05-.25.1-.4.05-.1.1-.25.2-.3l8.8-8.75c.2-.2.45-.3.7-.3h12c.2 0 .35.05.55.1.15.05.35.2.45.3.15.15.25.3.3.45.05.15.1.35.1.55v28.75c0 .2-.05.35-.1.55-.05.15-.2.35-.3.45-.15.15-.3.25-.45.3-.2.1-.4.15-.6.15Z'
      />
      <path
        fill='#fff'
        d='M33.7 42.1H13.35c-.2 0-.35-.05-.55-.1-.15-.05-.35-.2-.45-.3-.15-.15-.25-.3-.3-.45-.05-.15-.1-.35-.1-.55V20.25c0-.15.05-.25.1-.4.05-.1.1-.25.2-.3L21 10.8c.2-.2.45-.3.7-.3h12c.2 0 .35.05.55.1.15.05.35.2.45.3.15.15.25.3.3.45.05.15.1.35.1.55v28.75c0 .2-.05.35-.1.55-.05.15-.2.35-.3.45-.15.15-.3.25-.45.3-.15.1-.35.15-.55.15Z'
      />
      <path
        fill='#BCBEC0'
        d='M12.1 20.1h7.95c.2 0 .4-.05.55-.1.2-.05.35-.2.5-.3.15-.15.25-.3.3-.45.05-.15.1-.35.1-.55v-8c0-.05 0-.1-.05-.1 0-.05-.05-.05-.1-.05h-.1c-.05 0-.1.05-.1.05l-9.2 9.15c-.05.05-.05.05-.05.1v.1c0 .05.05.05.1.1s.05.05.1.05ZM31.9 23.55H15.45v2H31.9v-2ZM31.9 27.4H15.45v2H31.9v-2ZM31.9 31.3H15.45v2H31.9v-2ZM31.9 35.2H15.45v2H31.9v-2ZM31.9 20h-7.2c-.25 0-.25 0-.25-.25 0-1.15.5-2.05 1.35-2.75l.6-.45c.05-.05.1-.05.2 0 .95.8 2.3.8 3.25 0 .05-.05.1-.05.15-.05.95.55 1.65 1.35 1.9 2.5.05.35.1.65 0 1Z'
      />
      <path
        fill='#BCBEC0'
        d='M28.2 16.7c-1.15 0-2.05-.9-2.05-2.05s.95-2.05 2.05-2.05c1.15 0 2.05.9 2.05 2.05s-.9 2.05-2.05 2.05Z'
      />
      <path
        fill='#11748E'
        d='M28.8 6.95h-9.65c-.85 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6h9.65c.85 0 1.6.7 1.6 1.6 0 .9-.75 1.6-1.6 1.6Z'
      />
      <path
        fill='#2891B5'
        d='M28.3 29.35c0-.35.1-.55.45-.7 3.35-1.45 6.65-2.95 10-4.4.25-.1.65-.1.9 0 3.35 1.45 6.7 2.95 10 4.45.2.1.4.35.4.5 0 2.9.05 5.8-.05 8.7-.1 3.9-1.9 7-4.7 9.55-1.55 1.4-3.35 2.45-5.35 3-.45.15-1.05.15-1.5 0-5.4-1.8-8.85-5.5-9.95-11.1-.35-1.75-.15-8.45-.2-10Zm19.4 1.45c0-.2-.2-.5-.35-.6-2.6-1.2-5.25-2.35-7.85-3.5-.2-.1-.5-.05-.65 0-2.55 1.1-5.1 2.25-7.65 3.4-.35.15-.5.35-.5.75v4.8c0 .9-.05 1.85.1 2.75.8 4.8 3.65 7.9 8.15 9.6.15.05.4.05.55 0 4.15-1.5 7-4.3 7.95-8.65.35-1.55.3-7.3.25-8.55Z'
      />
      <path
        fill='#3EAFCE'
        d='M47.85 30.7c0-.2-.2-.5-.35-.6-2.65-1.2-5.3-2.4-8-3.55-.2-.1-.5-.05-.7 0L31 30c-.35.15-.5.35-.5.8v4.9c0 .95-.05 1.9.1 2.8.8 4.9 3.75 8 8.3 9.75.15.05.4.05.6 0 4.2-1.55 7.1-4.4 8.1-8.85.35-1.55.3-7.45.25-8.7Z'
      />
      <path
        fill='#5DCEE8'
        d='M47.85 31.35c0-.2-.2-.5-.35-.55-2.55-1.15-5.15-2.3-7.7-3.4-.2-.1-.45-.05-.65 0l-7.5 3.3c-.35.15-.5.35-.5.75v4.75c0 .9-.05 1.85.1 2.7.75 4.7 3.6 7.75 8 9.4.15.05.4.05.55 0 4.05-1.5 6.85-4.25 7.8-8.5.35-1.6.3-7.25.25-8.45Z'
      />
      <path
        fill='#7EAC54'
        d='M32.45 37.35 38.1 43l9.15-9.15-.35-.95-8.8 5.4-6-1.9.35.95Z'
      />
      <path
        fill='#98CB72'
        d='m37.75 42.05.35.95 9.15-9.15-.35-.95-9.15 9.15Z'
      />
      <path
        fill='#B8DD82'
        d='m32.1 36.4 5.65 5.65 9.15-9.15-2.35-2.35-6.8 6.8-3.3-3.3-2.35 2.35Z'
      />
      <path
        fill='#EFFFB7'
        d='M41.8 33.85 40.2 39l1.8-1.75 1.6-5.3-1.8 1.9ZM40.6 35.05 39 40l.8-.75 1.6-5.05-.8.85ZM34.45 34.05l3.3 3.3 6.8-6.8-6.75 7-3.35-3.5Z'
      />
      <path fill='#3EAFCE' d='m43.1 26.05-.5 1.65 1.5.65.5-1.7-1.5-.6Z' />
      <path
        fill='#8CE6F4'
        d='m42.25 28.7-1.45 4.85 3.5-3.1.25-.8-2.3-.95ZM39.35 42.55 38.3 47.1s.6.5 1.15.55c0 0 1.6-.5 2.75-1.5l1.95-8.4-4.8 4.8Z'
      />
      <g clipPath='url(#b)'>
        <path
          fill='#C0F9FC'
          d='M34.5 48h-21C6.05 48 0 41.95 0 34.5v-21C0 6.05 6.05 0 13.5 0h21C41.9 0 48 6.05 48 13.5v21C48 41.95 41.95 48 34.5 48Z'
        />
        <path
          fill='#8CE6F4'
          d='M33 45H14.95c-6.9 0-12.5-5.6-12.5-12.45v-.05c0-6.9 5.6-12.5 12.5-12.5H33c6.9 0 12.45 5.6 12.45 12.45v.05C45.45 39.4 39.9 45 33 45Z'
        />
        <path
          fill='#11748E'
          d='M36.75 3.4H11.2c-2.15 0-3.9 1.75-3.9 3.9v39.15c1.85.95 3.95 1.5 6.15 1.5H34.5c2.2 0 4.3-.55 6.15-1.5V7.35c0-2.15-1.75-3.95-3.9-3.95Z'
        />
        <path
          fill='#00ACF0'
          d='M35.75 4.95h-23.6c-1.8 0-3.25 1.45-3.25 3.25v38.95c1.4.5 2.95.8 4.5.8h21.1c1.6 0 3.1-.3 4.5-.8V8.25c0-1.8-1.45-3.3-3.25-3.3Z'
        />
        <path
          fill='#fff'
          d='M13.45 47.95h5.4l16.9-43h-23.6c-1.8 0-3.25 1.45-3.25 3.25v38.95c1.45.55 2.95.8 4.55.8Z'
          opacity={0.15}
        />
        <path
          fill='#2291AA'
          d='M34.5 43H14.15c-.2 0-.35-.05-.55-.1-.15-.05-.35-.2-.45-.3-.15-.15-.25-.3-.3-.45-.05-.15-.1-.35-.1-.55V21.15c0-.15.05-.25.1-.4.05-.1.1-.25.2-.3l8.8-8.75c.2-.2.45-.3.7-.3h12c.2 0 .35.05.55.1.15.05.35.2.45.3.15.15.25.3.3.45.05.15.1.35.1.55v28.75c0 .2-.05.35-.1.55-.05.15-.2.35-.3.45-.15.15-.3.25-.45.3-.2.1-.4.15-.6.15Z'
        />
        <path
          fill='#fff'
          d='M33.7 42.1H13.35c-.2 0-.35-.05-.55-.1-.15-.05-.35-.2-.45-.3-.15-.15-.25-.3-.3-.45-.05-.15-.1-.35-.1-.55V20.25c0-.15.05-.25.1-.4.05-.1.1-.25.2-.3L21 10.8c.2-.2.45-.3.7-.3h12c.2 0 .35.05.55.1.15.05.35.2.45.3.15.15.25.3.3.45.05.15.1.35.1.55v28.75c0 .2-.05.35-.1.55-.05.15-.2.35-.3.45-.15.15-.3.25-.45.3-.15.1-.35.15-.55.15Z'
        />
        <path
          fill='#BCBEC0'
          d='M12.1 20.1h7.95c.2 0 .4-.05.55-.1.2-.05.35-.2.5-.3.15-.15.25-.3.3-.45.05-.15.1-.35.1-.55v-8c0-.05 0-.1-.05-.1 0-.05-.05-.05-.1-.05h-.1c-.05 0-.1.05-.1.05l-9.2 9.15c-.05.05-.05.05-.05.1v.1c0 .05.05.05.1.1s.05.05.1.05ZM31.9 23.55H15.45v2H31.9v-2ZM31.9 27.4H15.45v2H31.9v-2ZM31.9 31.3H15.45v2H31.9v-2ZM31.9 35.2H15.45v2H31.9v-2ZM31.9 20h-7.2c-.25 0-.25 0-.25-.25 0-1.15.5-2.05 1.35-2.75l.6-.45c.05-.05.1-.05.2 0 .95.8 2.3.8 3.25 0 .05-.05.1-.05.15-.05.95.55 1.65 1.35 1.9 2.5.05.35.1.65 0 1Z'
        />
        <path
          fill='#BCBEC0'
          d='M28.2 16.7c-1.15 0-2.05-.9-2.05-2.05s.95-2.05 2.05-2.05c1.15 0 2.05.9 2.05 2.05s-.9 2.05-2.05 2.05Z'
        />
        <path
          fill='#11748E'
          d='M28.8 6.95h-9.65c-.85 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6h9.65c.85 0 1.6.7 1.6 1.6 0 .9-.75 1.6-1.6 1.6Z'
        />
        <path
          fill='#2891B5'
          d='M28.3 29.35c0-.35.1-.55.45-.7 3.35-1.45 6.65-2.95 10-4.4.25-.1.65-.1.9 0 3.35 1.45 6.7 2.95 10 4.45.2.1.4.35.4.5 0 2.9.05 5.8-.05 8.7-.1 3.9-1.9 7-4.7 9.55-1.55 1.4-3.35 2.45-5.35 3-.45.15-1.05.15-1.5 0-5.4-1.8-8.85-5.5-9.95-11.1-.35-1.75-.15-8.45-.2-10Zm19.4 1.45c0-.2-.2-.5-.35-.6-2.6-1.2-5.25-2.35-7.85-3.5-.2-.1-.5-.05-.65 0-2.55 1.1-5.1 2.25-7.65 3.4-.35.15-.5.35-.5.75v4.8c0 .9-.05 1.85.1 2.75.8 4.8 3.65 7.9 8.15 9.6.15.05.4.05.55 0 4.15-1.5 7-4.3 7.95-8.65.35-1.55.3-7.3.25-8.55Z'
        />
        <path
          fill='#3EAFCE'
          d='M47.85 30.7c0-.2-.2-.5-.35-.6-2.65-1.2-5.3-2.4-8-3.55-.2-.1-.5-.05-.7 0L31 30c-.35.15-.5.35-.5.8v4.9c0 .95-.05 1.9.1 2.8.8 4.9 3.75 8 8.3 9.75.15.05.4.05.6 0 4.2-1.55 7.1-4.4 8.1-8.85.35-1.55.3-7.45.25-8.7Z'
        />
        <path
          fill='#5DCEE8'
          d='M47.85 31.35c0-.2-.2-.5-.35-.55-2.55-1.15-5.15-2.3-7.7-3.4-.2-.1-.45-.05-.65 0l-7.5 3.3c-.35.15-.5.35-.5.75v4.75c0 .9-.05 1.85.1 2.7.75 4.7 3.6 7.75 8 9.4.15.05.4.05.55 0 4.05-1.5 6.85-4.25 7.8-8.5.35-1.6.3-7.25.25-8.45Z'
        />
        <path
          fill='#7EAC54'
          d='M32.45 37.35 38.1 43l9.15-9.15-.35-.95-8.8 5.4-6-1.9.35.95Z'
        />
        <path
          fill='#98CB72'
          d='m37.75 42.05.35.95 9.15-9.15-.35-.95-9.15 9.15Z'
        />
        <path
          fill='#B8DD82'
          d='m32.1 36.4 5.65 5.65 9.15-9.15-2.35-2.35-6.8 6.8-3.3-3.3-2.35 2.35Z'
        />
        <path
          fill='#EFFFB7'
          d='M41.8 33.85 40.2 39l1.8-1.75 1.6-5.3-1.8 1.9ZM40.6 35.05 39 40l.8-.75 1.6-5.05-.8.85ZM34.45 34.05l3.3 3.3 6.8-6.8-6.75 7-3.35-3.5Z'
        />
        <path fill='#3EAFCE' d='m43.1 26.05-.5 1.65 1.5.65.5-1.7-1.5-.6Z' />
        <path
          fill='#8CE6F4'
          d='m42.25 28.7-1.45 4.85 3.5-3.1.25-.8-2.3-.95ZM39.35 42.55 38.3 47.1s.6.5 1.15.55c0 0 1.6-.5 2.75-1.5l1.95-8.4-4.8 4.8Z'
        />
      </g>
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h50.1v50.5H0z' />
      </clipPath>
      <clipPath id='b'>
        <path fill='#fff' d='M0 0h50.1v50.5H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default PrivacyPolicy;
