import { css } from '@emotion/react';

export const maxPlatformLoanInfoCx = css`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0.5rem 0.75rem;

  font-size: 0.875rem;

  color: #404040;
  background-color: #ededed;
  border-radius: 0.5rem;

  span {
    margin-left: 0.5rem;
  }

  a {
    color: #00acf0;
  }
`;
