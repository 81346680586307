import { css } from '@emotion/react';

export const agreementDialogCx = css`
  .MuiPaper-root {
    border-radius: 1rem;

    .MuiDialogContent-root {
      padding: 1.5rem 1rem;
    }
  }
`;
