import { SVGProps } from 'react';

const LoanFailed = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={104}
    height={104}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#FAE78D'
        d='M69.066 96h-42.04C12.112 96 0 83.9 0 69V27C0 12.1 12.112 0 27.026 0h42.04c14.815 0 27.026 12.1 27.026 27v42c0 14.9-12.111 27-27.026 27Z'
      />
      <path
        fill='#F9DD60'
        d='M66.065 90H29.93C16.117 90 4.906 78.8 4.906 65.1V65c0-13.8 11.211-25 25.024-25h36.135C79.878 40 90.99 51.2 90.99 64.9v.1c0 13.8-11.11 25-24.924 25Z'
      />
      <path
        fill='#17566B'
        d='M73.168 11H24.421c-4.104 0-7.507 3.4-7.507 7.5v75.6c3.103 1.3 6.506 2 10.01 2h41.84c4.204 0 8.208-1 11.811-2.7V18.5c0-4.1-3.303-7.5-7.407-7.5Z'
      />
      <path
        fill='#00ACF0'
        d='M71.269 14H26.225c-3.403 0-6.206 2.8-6.206 6.2v74.9c2.203.6 4.505.9 6.907.9h41.84c3.003 0 5.906-.5 8.609-1.4V20.2c.1-3.4-2.703-6.2-6.106-6.2Z'
      />
      <path
        fill='#fff'
        d='M26.226 14c-3.404 0-6.206 2.8-6.206 6.2v74.9c2.202.6 4.504.9 6.906.9h12.012l32.33-82H26.226Z'
        opacity={0.15}
      />
      <path
        fill='#17566B'
        d='M57.956 17.7H39.538c-1.702 0-3.003-1.4-3.003-3s1.402-3 3.003-3h18.418c1.701 0 3.003 1.4 3.003 3s-1.402 3-3.003 3Z'
      />
      <path
        fill='#2291AA'
        d='M66.464 82.3H34.033c-.3 0-.6-.1-.901-.2-.3-.1-.5-.3-.7-.5-.201-.2-.401-.5-.501-.7-.1-.3-.2-.6-.2-.9V47.5c0-.2 0-.4.1-.6.1-.2.2-.4.3-.5l14.013-13.9c.3-.3.701-.5 1.101-.5h19.119c.3 0 .6.1.9.2.3.1.501.3.701.5.2.2.4.5.5.7.1.3.201.6.201.9V80c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.501.4-.701.5-.2.1-.5.2-.801.2Z'
      />
      <path
        fill='#fff'
        d='M65.163 80.9H32.732c-.3 0-.6-.1-.901-.2-.3-.1-.5-.3-.7-.5-.2-.2-.401-.5-.501-.7-.1-.3-.2-.6-.2-.9V46.1c0-.2 0-.4.1-.6.1-.2.2-.4.3-.5l14.014-13.9c.3-.3.7-.5 1.1-.5h19.119c.3 0 .6.1.9.2.301.1.501.3.702.5.2.2.4.5.5.7.1.3.2.6.2.9v45.7c0 .3-.1.6-.2.9-.1.3-.3.5-.5.7-.2.2-.501.4-.701.5-.2.1-.5.2-.8.2Z'
      />
      <path
        fill='#BCBEC0'
        d='M30.73 45.9h12.612c.3 0 .6-.1.901-.2.3-.1.5-.3.8-.5.2-.2.401-.5.501-.7.1-.3.2-.6.2-.9V30.9c0-.1 0-.1-.1-.2 0-.1-.1-.1-.1-.1h-.2c-.1 0-.1 0-.2.1L30.53 45.3s-.1.1-.1.2v.2c0 .1.1.1.1.1.1 0 .2.1.2.1ZM62.358 51.3H36.133v3.1h26.225v-3.1ZM62.358 57.5H36.133v3.1h26.225v-3.1ZM62.358 63.7H36.133v3.1h26.225v-3.1ZM62.358 69.9H36.133V73h26.225v-3.1Z'
      />
      <path
        fill='#EE7988'
        d='M65.563 64.8H31.13a4.697 4.697 0 0 1-4.704-4.7v-1.6c0-2.6 2.102-4.7 4.704-4.7h34.433c2.603 0 4.705 2.1 4.705 4.7v1.6c0 2.6-2.102 4.7-4.705 4.7Z'
      />
      <path
        fill='#fff'
        d='M38.037 62.5c-.701 0-1.202-.1-1.702-.4-.5-.2-.8-.6-1.001-1-.2-.4-.4-1-.4-1.6 0-.6.1-1.2.4-1.6.2-.5.6-.8 1.101-1.1.5-.2 1.001-.4 1.702-.4.3 0 .6 0 .9.1l.901.3.3.3v.4l-.3.3c-.1.1-.3 0-.4 0-.4-.2-.8-.3-1.301-.3-.6 0-1.001.2-1.302.5-.3.3-.4.8-.4 1.4 0 .6.2 1.1.5 1.4.3.3.701.5 1.302.5.3 0 .6 0 1-.1v-1.1h-.7c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1.301c.3 0 .5.2.5.5v1.9c0 .1 0 .3-.1.4-.1.1-.2.2-.3.2-.2.1-.5.2-.8.2-.6.2-.901.2-1.201.2ZM41.439 62.5c-.2 0-.4-.1-.5-.2-.1-.2-.1-.3 0-.6L43.04 57c.1-.2.2-.3.3-.4.1-.1.301-.1.401-.1.2 0 .3 0 .4.1.1.1.2.2.3.4l2.103 4.7c.1.2.1.4 0 .6-.1.2-.3.2-.5.2s-.301 0-.401-.1c-.1-.1-.2-.2-.3-.4l-.3-.8H42.34l-.3.8c-.1.2-.2.3-.3.4 0 0-.101.1-.301.1Zm2.402-4.6-.9 2.2h1.901l-1-2.2ZM50.447 62.5c-.7 0-1.201-.1-1.702-.4-.5-.2-.8-.6-1-1-.201-.4-.401-1-.401-1.6 0-.6.1-1.2.4-1.6.2-.5.6-.8 1.101-1.1.5-.2 1.001-.4 1.702-.4.3 0 .6 0 .9.1l.902.3.3.3v.4l-.3.3c-.1.1-.3 0-.4 0-.401-.2-.802-.3-1.302-.3-.6 0-1.001.2-1.301.5-.3.3-.4.8-.4 1.4 0 .6.2 1.1.5 1.4.3.3.7.5 1.301.5.3 0 .6 0 1.001-.1v-1.1h-.7c-.301 0-.501-.2-.501-.5s.2-.5.5-.5h1.001c.3 0 .5.2.5.5v1.9c0 .1 0 .3-.1.4-.1.1-.2.2-.3.2-.2.1-.5.2-.8.2-.3.2-.601.2-.901.2ZM53.853 62.5c-.2 0-.4-.1-.5-.2-.1-.2-.1-.3 0-.6l2.101-4.7c.1-.2.2-.3.3-.4.1-.1.301-.1.401-.1.2 0 .3 0 .4.1.1.1.2.2.3.4l2.103 4.7c.1.2.1.4 0 .6-.1.2-.3.2-.5.2s-.301 0-.401-.1c-.1-.1-.2-.2-.3-.4l-.3-.8h-2.703l-.3.8c-.1.2-.2.3-.3.4 0 0-.1.1-.301.1Zm2.402-4.6-.9 2.2h1.901l-1-2.2ZM60.56 62.4c-.2 0-.4-.1-.5-.2-.1-.1-.2-.3-.2-.5v-4.6c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2.4 0 .6.2.6.7v4.1h2.203c.4 0 .6.2.6.5 0 .4-.2.5-.6.5H60.56v.2Z'
      />
      <path
        fill='#BB2854'
        d='M86.985 104c9.398 0 17.016-7.611 17.016-17s-7.618-17-17.016-17c-9.398 0-17.016 7.611-17.016 17s7.618 17 17.016 17Z'
      />
      <path
        fill='#EE7988'
        d='M85.282 102.4c9.398 0 17.016-7.61 17.016-17 0-9.389-7.618-17-17.016-17-9.398 0-17.016 7.611-17.016 17 0 9.39 7.618 17 17.016 17Z'
      />
      <path
        fill='#BB2854'
        d='M82.38 86.4c-.1-.1-.1 0 0 0l-5.305-5.3c-.3-.3-.4-.8-.2-1.2 0-.1.1-.2.2-.3l2.102-2.1c.4-.4 1-.4 1.401 0l2.302 2.3 2.903 2.9s.1 0 .1-.1l5.205-5.2c.3-.3.8-.4 1.201-.2.1 0 .2.1.3.2l2.103 2.1c.4.4.4 1 0 1.5l-5.205 5.2s-.1 0-.1.1l.5.5 4.704 4.7c.3.3.4.7.3 1.1 0 .1-.1.2-.2.3l-2.201 2.2c-.4.4-1.001.4-1.402 0l-1.201-1.2c-1.401-1.3-2.703-2.7-4.004-4l-.1-.1-.1.1-5.205 5.2c-.2.2-.4.3-.6.3-.3 0-.601 0-.801-.3-.701-.7-1.402-1.4-2.203-2.1-.4-.4-.4-1 0-1.4l5.206-5.2c.2.1.2 0 .3 0Z'
      />
      <path
        fill='#fff'
        d='m81.677 85.7-5.305-5.3c-.3-.3-.4-.8-.2-1.2 0-.1.1-.2.2-.3l2.102-2.1c.4-.4 1-.4 1.4 0l2.303 2.3L85.08 82s.1 0 .1-.1l5.205-5.2c.3-.3.8-.4 1.201-.2.1 0 .2.1.3.2l2.103 2.1c.4.4.4 1 0 1.5l-5.206 5.2s-.1 0-.1.1l.5.5 4.705 4.7c.3.3.4.7.3 1.1 0 .1-.1.2-.2.3l-2.202 2.2c-.4.4-1 .4-1.4 0l-1.202-1.2c-1.401-1.3-2.703-2.7-4.004-4l-.1-.1-.1.1-5.205 5.2c-.2.2-.4.3-.6.3-.301 0-.601 0-.801-.3-.701-.7-1.402-1.4-2.203-2.1-.4-.4-.4-1 0-1.4l5.205-5.2c.2.1.3.1.3 0Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h104v104H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default LoanFailed;
