const Arrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='white'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6484 20.0484C11.4234 20.2734 11.1182 20.3997 10.8 20.3997C10.4818 20.3997 10.1766 20.2734 9.95161 20.0484L2.75161 12.8484C2.52665 12.6234 2.40027 12.3182 2.40027 12C2.40027 11.6818 2.52665 11.3766 2.75161 11.1516L9.95161 3.9516C10.1779 3.73301 10.4811 3.61206 10.7957 3.61479C11.1103 3.61753 11.4113 3.74373 11.6338 3.96622C11.8563 4.18871 11.9825 4.48968 11.9852 4.80432C11.988 5.11895 11.867 5.42208 11.6484 5.6484L6.49681 10.8H20.4C20.7183 10.8 21.0235 10.9264 21.2485 11.1515C21.4736 11.3765 21.6 11.6817 21.6 12C21.6 12.3183 21.4736 12.6235 21.2485 12.8485C21.0235 13.0736 20.7183 13.2 20.4 13.2H6.49681L11.6484 18.3516C11.8734 18.5766 11.9998 18.8818 11.9998 19.2C11.9998 19.5182 11.8734 19.8234 11.6484 20.0484Z'
      />
    </svg>
  );
};

export default Arrow;
