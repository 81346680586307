import { SVGProps } from 'react';

const LoanInProgress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={105}
    height={101}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#C0F9FC'
        d='M69.1 96.2H27c-14.9 0-27-12.2-27-27V27C0 12.2 12.2 0 27 0h42.1c14.9 0 27 12.2 27 27v42.1c.1 14.9-12.1 27.1-27 27.1Z'
      />
      <path
        fill='#8CE6F4'
        d='M66.5 91.5H29.8c-13.8 0-25-11.2-25-25s11.2-25 25-25h36.8c13.8 0 25 11.2 25 25-.1 13.8-11.3 25-25.1 25Z'
      />
      <path
        fill='#2291AA'
        d='m69.198 74.402-43.9 15.4c-.4.1-.8.2-1.3.2-.4 0-.8-.1-1.2-.3-.4-.2-.7-.4-1-.8-.3-.3-.5-.7-.6-1.1l-15.5-44c-.1-.3-.1-.6-.1-.9 0-.3.1-.6.2-.9l12.3-25.4c.3-.5.7-1 1.3-1.2l25.8-9.1c.4-.1.8-.2 1.3-.2.4 0 .8.1 1.2.3.4.2.7.4 1 .8.3.3.5.7.6 1.1l21.8 61.9c.1.4.2.8.2 1.3 0 .4-.1.8-.3 1.2-.2.4-.4.7-.8 1-.3.3-.6.5-1 .7Z'
        opacity={0.25}
      />
      <path
        fill='#F6C957'
        d='m66.695 73-43.8 15.5c-.4.1-.8.2-1.3.2-.4 0-.8-.1-1.2-.3-.4-.2-.7-.4-1-.8-.3-.3-.5-.7-.6-1.1l-15.5-44c-.1-.3-.1-.6-.1-.9 0-.3.1-.6.2-.9l12.3-25.4c.3-.5.7-1 1.3-1.2l25.8-9.1c.4-.1.8-.2 1.3-.2.4 0 .8.1 1.2.3.4.2.7.4 1 .8.3.4.5.7.7 1.1l21.8 61.9c.1.4.2.8.2 1.3 0 .4-.1.8-.3 1.2-.2.4-.4.7-.8 1-.4.3-.8.5-1.2.6Z'
      />
      <path
        fill='#F09537'
        d='m3.598 42.1 17.1-6c.4-.1.8-.4 1.1-.7.3-.3.6-.6.8-1 .2-.4.3-.8.3-1.2 0-.4 0-.9-.2-1.3l-6-17.1c0-.1-.1-.2-.2-.2-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3.1-.1 0-.1.1-.2.2l-12.7 26.6c0 .1-.1.2 0 .3 0 .1.1.2.1.2.1.1.1.1.2.1.1.1.2.1.3.1ZM48.796 34.49l-35.37 12.457 1.495 4.245 35.37-12.458-1.495-4.244ZM51.67 42.812 16.3 55.27l1.496 4.244 35.37-12.458-1.495-4.244ZM54.675 51.195l-35.37 12.457 1.495 4.245 35.37-12.458-1.495-4.244ZM57.585 59.61l-35.37 12.458 1.495 4.245 35.37-12.458-1.495-4.244Z'
      />
      <path
        fill='#2291AA'
        d='M78.4 82.8H32c-.4 0-.9-.1-1.2-.2-.4-.2-.8-.4-1.1-.7-.3-.3-.5-.7-.7-1.1-.2-.4-.2-.8-.2-1.2V32.9c0-.3.1-.6.2-.9.1-.3.3-.5.5-.7l20.1-19.9c.4-.4 1-.7 1.6-.7h27.4c.4 0 .9.1 1.3.2.4.2.8.4 1.1.7.3.3.5.7.7 1.1.2.4.2.8.2 1.2v65.6c0 .4-.1.8-.2 1.2-.2.4-.4.8-.7 1.1-.3.3-.7.5-1.1.7-.6.2-1.1.3-1.5.3Z'
        opacity={0.25}
      />
      <path
        fill='#B8DD82'
        d='M81.2 79.1H34.7c-.4 0-.9-.1-1.2-.2-.4-.2-.8-.4-1.1-.7-.3-.3-.5-.7-.7-1.1-.2-.4-.2-.8-.2-1.2V29.2c0-.3.1-.6.2-.9.1-.3.3-.5.5-.7l20-19.9c.4-.4 1-.7 1.6-.7h27.4c.4 0 .9.1 1.3.2.4.2.8.4 1.1.7.2.3.4.7.6 1.1.2.4.2.8.2 1.2v65.6c0 .4-.1.8-.2 1.2-.2.4-.4.8-.7 1.1-.3.3-.7.5-1.1.7-.4.2-.8.3-1.2.3Z'
      />
      <path
        fill='#98CB72'
        d='M31.9 28.9H50c.4 0 .9-.1 1.3-.2.4-.2.8-.4 1.1-.7.3-.3.6-.7.7-1.1.2-.4.3-.8.3-1.3V7.5c0-.1 0-.2-.1-.3l-.2-.2h-.3c-.1 0-.2.1-.2.1L31.6 28c-.1.1-.1.1-.1.2v.3c0 .1.1.2.2.2 0 .1.1.2.2.2ZM77 36.7H39.5v4.5H77v-4.5ZM77 45.6H39.5v4.5H77v-4.5ZM77 54.5H39.5V59H77v-4.5ZM77 63.3H39.5v4.5H77v-4.5Z'
      />
      <path
        fill='#F6C957'
        d='M90.4 91.3c-1-1-2.1-2-3.1-3.1-5.2 3.6-11 4.6-17.1 2.8-4.5-1.4-8-4.2-10.6-8.2-5-8-3.4-18.5 3.8-24.8 7.1-6.2 17.8-6.2 25-.1 7.6 6.5 9.1 17.9 3.1 26.1.5.5 1 1 1.6 1.5l1.5 1.5h.1c.6-.6.7-.6 1.2 0 4 4 3.1 3.2 7.101 7.2 1.1 1.1 1.4 2.4 1 3.9-.4 1.5-1.5 2.4-3 2.7-1.3.2-2.5-.1-3.4-1.1-4.1-4.1-3.2-3.3-7.3-7.3-.4-.4-.4-.4.1-1.1Zm-14.5-4.6c7.6.1 14-6.2 14.2-13.9.1-7.8-6.1-14.2-13.9-14.3-7.8-.1-14.2 6.1-14.3 14-.2 7.7 6.1 14.1 14 14.2Z'
      />
      <path
        fill='#F1F2F2'
        d='M75.903 88.502c8.671 0 15.7-7.03 15.7-15.7 0-8.671-7.029-15.7-15.7-15.7-8.67 0-15.7 7.029-15.7 15.7 0 8.67 7.03 15.7 15.7 15.7Z'
        opacity={0.35}
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h104.2v101H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default LoanInProgress;
