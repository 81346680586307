import styled from '@emotion/styled';

export const SuccessWrapper = styled.div`
  background-color: white;
  min-height: 100vh;
  padding: 0 2rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;