import { css } from '@emotion/react';

export const otherPhoneCx = css`
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;

  .field-label {
    display: flex;
  }
`;
