export const initialState = {
  count: 0,
  isLoadingOverlayShown: false,
  isPhotoDialogShown: false,
  notificationDuration: 10000,
  webVersion: '0.0.1',
  errorDivPaddingTop: '10px',
  cameraAllowError:
    'Untuk melanjutkan proses pengajukan, mohon izinkan penggunaan kamera pada perangkat Anda.',
  jobTypes: [
    'pengusaha',
    'staf rumah tangga',
    'freelance',
    'pegawai swasta',
    'pegawai negeri',
  ],
  provinces: [],
  regencies: [],
  villages: [],
  subDistrict: [],
  invalidPhoneError1Flg: true,
  invalidPhoneError2Flg: true,
  invalidDataFlg: true,
  emptyFieldError: 'Field tidak boleh kosong',
  doubleSpaceError: 'Tidak boleh double spasi',
  symbolOrDigitOnlyError: 'Mohon gunakan nama asli',
  minimumBirthOfPlaceError: 'Data tempat lahir kurang dari 3 karakter',
  minimalCharError: 'Minimal 3 karakter',
  symbolOnlyError: 'Mohon isi alamat dengan huruf yang benar',
  mobileFieldError1: 'Mohon diisi dengan format 08xxxxx',
  mobileFieldMinLenError: 'Minimal 10 digit',
  mobileFieldError2:
    'Mohon diisi dengan format 021xxxxx atau dengan nomor daerah lainnya',
  mobileFieldError3:
    'Maaf, nomor yang anda masukkan tidak valid. Mohon masukkan nomor lainnya.',
  mobileFieldMinLenError2: 'Minimal 9 digit',
  dateFieldNumberError: 'Mohon masukkan tanggal yang valid. Contoh 1-31',
  txtWithDigitError: 'Referral harus angka atau huruf',
  sameMobileError: 'Nomor telepon harus berbeda',
  txtMinValue: 3,
  txtPattern: /^[a-zA-Z0-9. \-/,]*$/,
  symbolPattern: /^[^a-zA-Z0-9]+$/,
  doubleSpacePattern: /^.*\s{2,}.*$/,
  txtWithDigitPattern: /^[a-zA-Z0-9]*$/,
  firstLetterDigitPattern: /^[1-9]/,
  mobilePattern1: /^08/,
  mobilePattern2: /^021/,
  defaultDate: '1990-01-01',
  currentField: '',
  isFormFilled: true,
  isFormFilledReview: false,
  isReadTerm: false,
  otp_resend_time: 60,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  ktpLoaded: false,
  selfieLoaded: false,
};
