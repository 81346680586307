import { css } from '@emotion/react';

export const loanListTableCx = css`
  .chevron-icon {
    margin-left: 10px;
  }

  .go-to-button {
    padding: 6px 16px;
  }

  .loan-amount-cell {
    padding-right: 20px;
  }
`;
