import { SVGProps } from 'react';

const LoanSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={108}
    height={107}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <g clipPath='url(#b)'>
        <path
          fill='#C0F9FC'
          d='M77.285 104.147H30.624c-16.444 0-29.96-13.176-29.96-29.29V29.372C.664 13.342 14.18.166 30.624.166h46.661c16.53 0 29.96 13.176 29.96 29.205v45.487c.086 16.113-13.43 29.289-29.96 29.289Z'
        />
        <path
          fill='#8CE6F4'
          d='M78.75 98.441H29.766c-11.795 0-21.437-9.4-21.437-20.897v-6.881c0-11.498 9.642-20.897 21.437-20.897H78.75c11.794 0 21.436 9.399 21.436 20.897v6.881c0 11.498-9.642 20.897-21.436 20.897Z'
        />
        <path
          fill='#11748E'
          d='M82.19 11.58H27.78c-4.562 0-8.35 3.609-8.35 8.14v82.246a30.817 30.817 0 0 0 11.192 2.098h46.66c4.736 0 9.213-1.091 13.173-3.022V19.637c0-4.364-3.702-8.057-8.265-8.057Z'
        />
        <path
          fill='#00ACF0'
          d='M80.04 14.852H29.847c-3.788 0-6.887 3.02-6.887 6.713v81.574c2.41.671 4.993 1.007 7.662 1.007h46.661c3.358 0 6.63-.587 9.642-1.594V21.565c0-3.692-3.099-6.713-6.887-6.713Z'
        />
        <path
          fill='#fff'
          d='M29.848 14.852c-3.788 0-6.887 3.02-6.887 6.713v81.574c2.41.671 4.993 1.007 7.662 1.007h13.43L80.04 14.852H29.85Z'
          opacity={0.15}
        />
        <path
          fill='#11748E'
          d='M65.23 18.882H44.74c-1.808 0-3.357-1.51-3.357-3.273 0-1.762 1.55-3.273 3.357-3.273h20.49c1.808 0 3.358 1.51 3.358 3.273 0 1.762-1.55 3.273-3.358 3.273Z'
        />
        <path
          fill='#2291AA'
          d='M74.702 89.208H38.544c-.345 0-.69-.084-.947-.168-.345-.084-.603-.335-.861-.503-.259-.252-.43-.504-.517-.84-.086-.335-.172-.587-.172-.922V51.359c0-.252.086-.42.172-.672.086-.167.258-.42.344-.587l15.583-15.106c.344-.336.775-.504 1.291-.504h21.265c.344 0 .688.084.947.168.344.084.602.336.86.504.259.251.431.503.517.839.086.336.172.587.172.923v49.767c0 .335-.086.671-.172.923-.086.335-.344.587-.516.839-.259.252-.517.42-.861.504-.259.167-.603.251-.947.251Z'
        />
        <path
          fill='#fff'
          d='M73.24 87.615H37.084c-.345 0-.69-.084-.947-.168-.345-.084-.603-.336-.861-.504-.259-.252-.43-.503-.517-.84-.086-.335-.172-.587-.172-.922V49.765c0-.252.086-.42.172-.671.086-.168.258-.42.344-.588L50.685 33.4c.344-.336.775-.503 1.291-.503h21.35c.345 0 .69.083.948.167.344.084.602.336.86.504.259.252.431.503.517.84.086.335.172.587.172.922v49.767c0 .336-.086.671-.172.923-.086.336-.344.588-.516.84-.259.251-.517.419-.861.503-.344.252-.689.252-1.033.252Z'
        />
        <path
          fill='#D1D3D4'
          d='M34.93 49.513h14.12c.343 0 .688-.084 1.032-.167.344-.084.603-.336.861-.504.258-.252.43-.503.603-.84.172-.335.172-.587.172-.922V33.316c0-.084 0-.168-.086-.168 0-.084-.086-.084-.172-.168h-.173l-.172.084-16.357 15.862c-.086.084-.086.084-.086.168v.168c0 .084.086.084.172.167-.086.084 0 .084.086.084ZM70.052 55.473H40.867v3.44h29.185v-3.44ZM70.052 62.185H40.867v3.441h29.185v-3.44ZM70.052 68.984H40.867v3.441h29.185v-3.44ZM70.052 75.697H40.867v3.441h29.185v-3.44Z'
        />
      </g>
      <path
        fill='#7EAC54'
        d='M58.1 81.4 76 99.6l29.1-29.5-1.2-3.1L76 84.4l-19-6 1.1 3Z'
      />
      <path
        fill='#B8DD82'
        d='m57 78.4 17.9 18.1L104 67l-7.5-7.5-21.6 21.9-10.4-10.6-7.5 7.6Z'
      />
      <path
        fill='#7EAC54'
        d='m74.898 96.5 1.1 3.1 29.1-29.5-1.1-3.1-29.1 29.5Z'
      />
      <path
        fill='#D8ED91'
        d='m87.802 70.1-5.2 16.5 5.7-5.6 5.2-17-5.7 6.1ZM83.998 74l-5.1 15.9 2.6-2.4 5-16.2-2.5 2.7ZM64.5 70.8l10.4 10.6 21.6-21.9L75 82.1 64.5 70.8Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.664.166h106.667v106.667H.664z' />
      </clipPath>
      <clipPath id='b'>
        <path fill='#fff' d='M.664.166h106.667v106.667H.664z' />
      </clipPath>
    </defs>
  </svg>
);
export default LoanSuccess;
