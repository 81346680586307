import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={104}
    height={96}
    fill='none'
    {...props}
  >
    <path
      fill='#E6E7E8'
      d='M69.155 96h-42.04C12.2 96 .089 83.9.089 69V27C.089 12.1 12.2 0 27.115 0h42.04C83.97 0 96.181 12.1 96.181 27v42c0 14.9-12.111 27-27.026 27Z'
    />
    <path
      fill='#BCBEC0'
      d='M66.152 90H30.017C16.204 90 4.993 78.8 4.993 65.1V65c0-13.8 11.21-25 25.024-25h36.135c13.813 0 24.924 11.2 24.924 24.9v.1c0 13.8-11.11 25-24.924 25Z'
    />
    <path
      fill='#fff'
      stroke='#BCBEC0'
      strokeDasharray='4 4'
      strokeLinecap='round'
      d='M68.054 84.4H30.017c-4.304 0-7.807-3.5-7.807-7.8V18.8c0-4.3 3.503-7.8 7.807-7.8h38.037c4.304 0 7.808 3.5 7.808 7.8v57.8c-.1 4.3-3.504 7.8-7.808 7.8Z'
    />
    <path
      fill='#BCBEC0'
      d='M68.754 39.6H29.316V45h39.438v-5.4ZM47.634 27.5H29.316v5.4h18.318v-5.4ZM68.754 51.6H29.316V57h39.438v-5.4ZM68.754 63.6H29.316V69h39.438v-5.4Z'
    />
    <g clipPath='url(#a)'>
      <path
        fill='#A7A9AC'
        d='M87 95.057c9.34 0 16.912-7.572 16.912-16.913 0-9.341-7.572-16.914-16.913-16.914-9.34 0-16.913 7.573-16.913 16.914 0 9.34 7.572 16.913 16.913 16.913Z'
      />
      <path
        fill='#BCBEC0'
        d='M85.308 93.465c9.34 0 16.913-7.572 16.913-16.913 0-9.341-7.572-16.913-16.913-16.913-9.341 0-16.914 7.572-16.914 16.913 0 9.34 7.573 16.913 16.914 16.913Z'
      />
      <path
        fill='#BCBEC0'
        d='M83.119 75.257V67.895c0-.497.199-.796.696-.995.1 0 .2-.1.299-.1h2.984c.597 0 .995.399.995.996v7.462H95.456c.497 0 .795.198.994.696 0 .1.1.199.1.298v2.985c0 .597-.398.995-.995.995H88.192v7.362c0 .398-.198.796-.596.995-.1.1-.2.1-.398.1h-3.084a.997.997 0 0 1-.995-.995v-7.462H75.756c-.298 0-.497-.1-.696-.2-.199-.198-.398-.397-.398-.695v-2.985c0-.597.398-.995.995-.995h7.263c.1-.2.1-.1.199-.1Z'
      />
      <path
        fill='#fff'
        d='M83.119 74.263V66.901c0-.497.199-.796.696-.995.1 0 .2-.1.299-.1h2.984c.597 0 .995.399.995.996v7.462H95.456c.497 0 .795.198.994.696 0 .1.1.199.1.298v2.985c0 .597-.398.995-.995.995H88.192V86.6c0 .398-.198.796-.596.995-.1.1-.2.1-.398.1h-3.084a.997.997 0 0 1-.995-.995v-7.462H75.756c-.298 0-.497-.1-.696-.2-.199-.198-.398-.397-.398-.696v-2.984c0-.597.398-.995.995-.995h7.263c.1-.1.1-.1.199-.1Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M68.394 59.639h35.518v35.418H68.394z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
