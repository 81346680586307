const LimitBackground = (
  props: Omit<React.SVGProps<SVGSVGElement>, 'fill'>,
) => {
  return (
    <svg
      width={296}
      height={84}
      viewBox='0 0 296 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_13_6547)'>
        <path
          d='M320 -0.500305H8C3.58172 -0.500305 0 3.63275 0 8.73114V137.971C0 143.07 3.58172 147.203 8 147.203H320C324.418 147.203 328 143.07 328 137.971V8.73114C328 3.63275 324.418 -0.500305 320 -0.500305Z'
          fill='url(#paint0_linear_13_6547)'
        />
        <g opacity={0.6}>
          <g clipPath='url(#clip1_13_6547)'>
            <path
              d='M282.776 -0.0204468H278.25V4.53072H282.776V-0.0204468Z'
              fill='white'
            />
            <path
              d='M297.536 5.02441H293.01V9.57559H297.536V5.02441Z'
              fill='white'
            />
            <path
              d='M249.42 -2.88885H244.894V1.66232H249.42V-2.88885Z'
              fill='white'
            />
            <path
              d='M263.491 1.66125H258.965V6.21242H263.491V1.66125Z'
              fill='white'
            />
            <path
              d='M277.561 6.31012H273.035V10.8613H277.561V6.31012Z'
              fill='white'
            />
            <path
              d='M291.337 10.7614H286.811V15.3126H291.337V10.7614Z'
              fill='white'
            />
            <path
              d='M237.022 2.74939H232.496V7.30056H237.022V2.74939Z'
              fill='white'
            />
            <path
              d='M248.929 6.11255H244.402V10.6637H248.929V6.11255Z'
              fill='white'
            />
            <path
              d='M261.326 9.77246H256.799V14.3236H261.326V9.77246Z'
              fill='white'
            />
            <path
              d='M273.921 13.5311H269.395V18.0822H273.921V13.5311Z'
              fill='white'
            />
            <path
              d='M286.712 17.3887H282.186V21.9398H286.712V17.3887Z'
              fill='white'
            />
            <path
              d='M299.307 21.1476H294.781V25.6988H299.307V21.1476Z'
              fill='white'
            />
            <path
              d='M249.715 15.9051H245.189V20.4563H249.715V15.9051Z'
              fill='white'
            />
            <path
              d='M260.539 18.6747H256.013V23.2259H260.539V18.6747Z'
              fill='white'
            />
            <path
              d='M271.854 21.6421H267.328V26.1933H271.854V21.6421Z'
              fill='white'
            />
            <path
              d='M283.367 24.8074H278.84V29.3585H283.367V24.8074Z'
              fill='white'
            />
            <path
              d='M294.977 27.9728H290.451V32.5239H294.977V27.9728Z'
              fill='white'
            />
            <path
              d='M260.933 28.1705H256.407V32.7217H260.933V28.1705Z'
              fill='white'
            />
            <path
              d='M270.871 30.4456H266.344V34.9967H270.871V30.4456Z'
              fill='white'
            />
            <path
              d='M281.202 32.8198H276.676V37.3709H281.202V32.8198Z'
              fill='white'
            />
            <path
              d='M291.731 35.3915H287.205V39.9426H291.731V35.3915Z'
              fill='white'
            />
            <path
              d='M270.575 39.5458H266.049V44.097H270.575V39.5458Z'
              fill='white'
            />
            <path
              d='M279.726 41.4252H275.2V45.9763H279.726V41.4252Z'
              fill='white'
            />
            <path
              d='M289.27 43.3044H284.744V47.8555H289.27V43.3044Z'
              fill='white'
            />
            <path
              d='M299.012 45.3819H294.486V49.9331H299.012V45.3819Z'
              fill='white'
            />
            <path
              d='M278.84 50.2286H274.314V54.7798H278.84V50.2286Z'
              fill='white'
            />
            <path
              d='M287.401 51.6135H282.875V56.1646H287.401V51.6135Z'
              fill='white'
            />
            <path
              d='M296.257 53.196H291.731V57.7472H296.257V53.196Z'
              fill='white'
            />
            <path
              d='M285.827 60.0212H281.3V64.5724H285.827V60.0212Z'
              fill='white'
            />
            <path
              d='M293.895 61.2081H289.369V65.7592H293.895V61.2081Z'
              fill='white'
            />
            <path
              d='M291.731 69.2203H287.205V73.7715H291.731V69.2203Z'
              fill='white'
            />
            <path
              d='M299.406 70.1107H294.879V74.6619H299.406V70.1107Z'
              fill='white'
            />
            <path
              d='M296.65 77.8261H292.124V82.3773H296.65V77.8261Z'
              fill='white'
            />
          </g>
        </g>
        <path
          d='M291.689 91.5441C291.689 91.5441 285.769 92.4539 278.719 71.7755V52.7667L291.409 63.1809L291.689 91.5441Z'
          fill='#F5C7AF'
        />
        <path
          d='M266.32 68.008L280.28 64.5402L281.27 56.9944L266.47 61.0723L261.25 62.5815C260.69 65.5998 262.1 68.7679 266.32 68.008Z'
          fill='#E2984C'
        />
        <path
          d='M261.24 64.8399L264.34 64.0907C265.61 63.791 266.6 62.4317 266.45 61.0724L261.23 62.5815C261.09 62.5815 260.95 63.6411 261.23 64.8399H261.24Z'
          fill='#E2984C'
        />
        <path
          d='M270.69 75.0935L284.22 70.2663L284.5 62.5708L269.56 68.3077L265.05 70.1165C264.91 73.5843 266.88 76.1531 270.69 75.0935Z'
          fill='#F5C7AF'
        />
        <path
          d='M265.609 72.9849L267.869 72.3856C269.419 72.0859 270.269 70.4269 269.979 68.7679C269.979 68.618 269.839 68.4682 269.839 68.1685L265.049 70.1272C265.049 70.1272 264.909 71.6363 265.609 72.9956V72.9849Z'
          fill='#F5C7AF'
        />
        <path
          d='M294.229 52.7667L293.949 56.0847L299.019 60.9118C298.879 61.0616 298.599 54.2759 294.229 52.7667Z'
          fill='#F5C7AF'
        />
        <path
          d='M290.139 59.7023L290.419 54.8752C286.609 54.7254 283.229 57.8935 282.949 62.1212V62.4209L286.899 62.7206C288.589 62.7206 289.999 61.3613 290.139 59.7023Z'
          fill='#A2C97C'
        />
        <path
          d='M276.75 46.1308C276.75 46.1308 274.49 48.6995 277.88 52.6169C281.26 56.5342 283.1 58.5036 283.1 58.5036L287.61 67.4086L290.85 65.3001L285.49 54.1367L276.75 46.1415V46.1308Z'
          fill='#87AA5F'
        />
        <path
          d='M280.27 47.3402C281.68 48.9992 283.93 48.8494 284.78 47.3402L280.13 42.8128C279 43.8724 279 45.9809 280.27 47.3402Z'
          fill='#F5C7AF'
        />
        <path
          d='M287.738 -5.91852C288.868 -6.21821 289.998 -6.5179 291.258 -6.82829C293.938 -7.57751 295.768 -9.39704 296.478 -12.2548C296.758 -13.6141 297.038 -14.8235 297.328 -16.1721C297.468 -15.2624 297.748 -14.3633 297.888 -13.6034C298.028 -13.1538 298.168 -12.5438 298.308 -12.0942C299.008 -9.37563 300.708 -7.71665 303.388 -6.96743C304.658 -6.66774 305.788 -6.36806 307.048 -6.05766C305.778 -5.75798 304.508 -5.45829 303.238 -4.99806C301.268 -4.39868 299.568 -3.33907 298.728 -1.23056C298.308 -0.170948 298.028 0.87796 297.738 1.93757C297.598 2.68679 297.458 3.44671 297.318 4.35648C297.038 3.29687 296.898 2.3978 296.758 1.48804C296.618 0.738819 296.338 -0.0211048 296.058 -0.770323C295.358 -2.72899 294.088 -4.23813 292.248 -4.84821C290.698 -4.99805 289.148 -5.44759 287.738 -5.90782V-5.91852Z'
          fill='#F6E798'
        />
        <path
          d='M239.11 90.035C238.83 88.376 238.55 86.8669 238.12 85.2079C237.7 83.6987 237.27 82.3395 236.71 80.8303C235.44 78.1117 233.33 76.4527 230.65 75.7035C228.39 74.9543 226.28 74.4941 224.02 73.8947C224.02 73.8947 224.16 73.8947 224.3 73.7448C226.56 73.1455 228.95 72.6852 231.21 71.936C234.31 70.8764 236.43 68.7679 237.41 65.4499C238.11 63.1916 238.54 60.9225 239.1 58.5143C239.1 58.2146 239.24 58.0648 239.38 57.7651C239.52 58.2146 239.52 58.6748 239.66 59.1244C240.08 60.9332 240.51 62.8919 240.93 64.7114C241.92 68.4789 244.31 71.0477 247.84 72.1073C249.95 72.7066 252.07 73.1669 254.18 73.7663C254.32 73.7663 254.46 73.7663 254.6 73.9161C253.05 74.2158 251.64 74.5155 250.37 74.9757C248.96 75.4252 247.69 75.8855 246.42 76.335C243.46 77.6943 241.77 80.1025 240.92 83.2706C240.36 85.2293 239.93 87.1987 239.51 89.1573C239.23 89.6069 239.23 89.9066 239.09 90.0671L239.11 90.035Z'
          fill='#F6E798'
        />
        <path
          d='M213.459 58.1932C214.589 57.8935 215.719 57.5939 216.979 57.2835C219.659 56.5342 221.489 54.7147 222.199 51.857C222.479 50.4977 222.759 49.2882 223.049 47.9396C223.189 48.8494 223.469 49.7485 223.609 50.5084C223.749 50.9579 223.889 51.568 224.029 52.0175C224.729 54.7361 226.429 56.3951 229.099 57.1443C230.369 57.444 231.499 57.7437 232.759 58.0541C231.489 58.3538 230.219 58.6535 228.949 59.1137C226.979 59.7131 225.289 60.7727 224.439 62.8812C224.019 63.9408 223.739 64.9897 223.449 66.0493C223.309 66.7985 223.169 67.5585 223.029 68.4682C222.749 67.4086 222.609 66.5096 222.469 65.5998C222.329 64.8506 222.049 64.0907 221.769 63.3414C221.069 61.3828 219.799 59.8736 217.959 59.2635C216.549 58.9639 214.999 58.6642 213.449 58.2039L213.459 58.1932Z'
          fill='#F6E798'
        />
        <path
          d='M222.899 52.467C222.899 52.467 221.769 57.8935 217.539 58.0541C217.539 58.0541 223.039 59.1137 222.899 52.467Z'
          fill='white'
        />
        <path
          d='M268.959 68.0294L280.929 65.086L281.769 58.5571L268.969 62.0142L264.419 63.2986C264.059 65.9851 265.259 68.6823 268.969 68.0401L268.959 68.0294Z'
          fill='#E2984C'
        />
        <path
          d='M264.539 65.3429L267.169 64.7007C268.249 64.4439 269.079 63.2879 268.969 62.1427L264.419 63.4271C264.419 63.2986 264.299 64.3261 264.539 65.3536V65.3429Z'
          fill='#E2984C'
        />
        <path
          d='M248.739 28.0747L261.189 67.0126C262.029 69.6991 264.899 71.2404 267.409 70.3413L287.509 63.0418C290.019 62.1427 291.339 59.0709 290.499 56.3844L278.049 17.4464C277.209 14.76 274.339 13.2187 271.829 14.1178L251.729 21.4173C249.219 22.4448 247.899 25.3882 248.739 28.0747Z'
          fill='#87AA5F'
        />
        <path
          d='M249.339 26.7903L261.669 65.5998C262.509 68.2863 265.379 69.8276 267.889 68.9285L287.989 61.629C290.499 60.7299 291.939 57.6581 290.979 54.9716L278.649 16.0337C277.809 13.3472 274.939 11.8059 272.429 12.705L252.329 20.133C249.819 21.032 248.379 24.1038 249.339 26.7903Z'
          fill='#A2C97C'
        />
        <path
          d='M252.09 26.7903L263.7 63.1702C264.3 64.9683 266.09 65.9851 267.77 65.3429L286.08 58.6856C287.76 58.0434 288.59 56.1275 288.11 54.3294L276.5 17.821C275.9 16.0229 274.11 15.0061 272.43 15.6483L254.12 22.3057C252.32 23.0763 251.49 24.9921 252.09 26.7903Z'
          fill='#BEDB8B'
        />
        <path
          d='M287.04 58.6856L283.33 60.0984C282.37 56.6413 284.05 53.0558 287.28 51.7714L287.52 51.6429L288.6 55.1001C289.08 56.6413 288.36 58.1719 287.04 58.6856Z'
          fill='#A2C97C'
        />
        <path
          d='M263.58 64.4439L262.26 60.473C265.49 59.4455 268.84 61.2437 269.92 64.7008L270.16 64.9576L266.93 66.1136C265.49 66.6273 264.06 65.8567 263.58 64.4439Z'
          fill='#A2C97C'
        />
        <path
          d='M252.93 22.4341L256.64 21.0213C257.6 24.4784 255.92 28.064 252.69 29.3483L252.45 29.4768L251.37 26.0197C250.89 24.4784 251.61 22.8194 252.93 22.4341Z'
          fill='#A2C97C'
        />
        <path
          d='M276.269 16.6758L277.589 20.6467C274.359 21.6742 271.009 19.876 269.929 16.4189L269.809 16.1621L273.039 15.0061C274.359 14.4924 275.789 15.263 276.269 16.6758Z'
          fill='#A2C97C'
        />
        <path
          d='M269.319 48.3143C269.439 48.828 269.799 49.0849 270.399 48.828L277.219 46.27C277.699 46.1415 277.939 45.7562 277.699 45.114L276.739 42.0422C276.379 41.0147 275.899 40.3726 275.299 39.9979C274.699 39.6233 273.979 39.6126 273.149 39.8695C272.549 40.1264 272.069 40.5117 271.829 41.0254C271.589 41.5392 271.469 42.0529 271.469 42.8236C271.229 42.4383 270.749 42.1814 270.269 42.0529L268.359 41.6676C268.119 41.5392 267.759 41.6676 267.639 41.7961C267.519 41.9245 267.399 42.1814 267.519 42.5667C267.639 43.0805 267.639 43.2089 267.879 43.3373C267.999 43.4658 268.239 43.4658 268.479 43.5942L271.109 44.108C271.469 44.108 271.709 44.2364 271.829 44.4933C271.949 44.6217 272.069 44.8786 272.189 45.2639L272.429 46.4198L269.799 47.4473C269.319 47.4473 269.079 47.8326 269.319 48.3464V48.3143ZM273.629 45.8847L273.029 43.9581C272.669 42.8022 272.909 42.0315 273.869 41.6569C274.709 41.2716 275.429 41.7854 275.779 42.9413L276.379 44.8679L273.629 45.8954V45.8847Z'
          fill='white'
        />
        <path
          d='M264.419 40.6294C264.539 41.1432 264.899 41.2716 265.379 41.1432L271.959 38.7136C272.439 38.5851 272.559 38.1998 272.439 37.6861C272.319 37.1723 271.959 37.0439 271.479 37.1723L271.239 37.3007C271.479 37.0439 271.599 36.6586 271.719 36.2732C271.839 35.8879 271.719 35.3742 271.599 34.9889C271.479 34.4751 271.119 33.9614 270.759 33.7045C270.399 33.3192 269.919 33.1907 269.439 33.1907C268.959 33.1907 268.359 33.1907 267.759 33.4476C267.159 33.7045 266.679 33.9614 266.319 34.3467C265.959 34.732 265.719 35.2457 265.599 35.631C265.479 36.1448 265.479 36.6585 265.719 37.1723C265.839 37.5576 266.079 37.9429 266.319 38.3282C266.559 38.5851 266.919 38.842 267.279 38.842L265.009 39.7411C264.409 39.7411 264.289 40.1264 264.409 40.6401L264.419 40.6294ZM266.929 37.1723C266.809 36.6585 266.809 36.2732 267.049 35.8879C267.289 35.5026 267.649 35.1173 268.369 34.9889C268.969 34.732 269.569 34.732 269.929 34.8604C270.289 34.9889 270.649 35.3742 270.769 35.8879C270.889 36.4017 270.889 36.787 270.649 37.1723C270.409 37.5576 270.049 37.9429 269.329 38.0714C268.729 38.3282 268.249 38.3282 267.769 38.1998C267.409 37.9429 267.049 37.5576 266.929 37.1723Z'
          fill='white'
        />
        <path
          d='M256.159 16.9327L259.749 57.7865C259.989 60.6015 262.379 62.7849 265.019 62.528L286.079 60.4837C288.709 60.2268 290.749 57.6688 290.509 54.7147L286.919 13.8609C286.679 11.046 284.289 8.86255 281.649 9.11943L260.589 11.1637C257.839 11.4206 255.919 13.9786 256.159 16.9327Z'
          fill='#87AA5F'
        />
        <path
          d='M256.999 15.7768L260.589 56.6306C260.829 59.4455 263.219 61.629 265.859 61.3721L287.039 59.3278C289.669 59.0709 291.709 56.5129 291.469 53.5588L287.879 12.705C287.639 9.89005 285.249 7.70661 282.609 7.96348L261.549 10.0078C258.679 10.3931 256.759 12.9511 256.999 15.7768Z'
          fill='#A2C97C'
        />
        <path
          d='M259.629 16.5474L262.979 54.8432C263.099 56.7697 264.779 58.1718 266.449 57.915L285.719 55.9884C287.519 55.86 288.829 54.0618 288.589 52.2744L285.239 13.9786C285.119 12.0521 283.439 10.65 281.769 10.9068L262.499 12.8334C260.819 12.9618 259.509 14.6315 259.629 16.5474Z'
          fill='#BEDB8B'
        />
        <path
          d='M286.68 56.2453L282.85 56.6306C282.61 53.0451 285.12 49.9733 288.47 49.588H288.71L289.07 53.3019C289.07 54.7147 287.99 55.9884 286.68 56.2453Z'
          fill='#A2C97C'
        />
        <path
          d='M262.62 55.8707L262.26 51.6429C265.61 51.5145 268.48 54.0725 268.84 57.6581V57.915L265.37 58.3003C264.05 58.5572 262.74 57.4012 262.62 55.8707Z'
          fill='#A2C97C'
        />
        <path
          d='M261.429 12.4481L265.259 12.0628C265.499 15.6484 262.989 18.7201 259.639 19.1055H259.399L259.039 15.3915C259.039 13.8502 259.999 12.5766 261.429 12.4481Z'
          fill='#A2C97C'
        />
        <path
          d='M285.359 12.705L285.719 16.9327C282.369 17.0611 279.499 14.5031 279.139 10.9175V10.6607L282.609 10.2754C284.049 10.0185 285.239 11.1744 285.359 12.705Z'
          fill='#A2C97C'
        />
        <path
          d='M271.719 41.7747C271.719 42.4169 272.079 42.5453 272.559 42.5453L279.739 41.7747C280.219 41.7747 280.459 41.3894 280.459 40.8756L280.219 37.6754C280.099 36.6479 279.859 35.8772 279.379 35.3742C278.899 34.8604 278.179 34.6036 277.349 34.732C276.749 34.8604 276.149 34.9889 275.789 35.5026C275.429 35.8879 275.189 36.4017 275.069 37.1723C274.949 36.6586 274.589 36.4017 273.989 36.1448L272.189 35.3742C271.949 35.2458 271.709 35.2458 271.469 35.3742C271.229 35.5026 271.109 35.7595 271.229 36.1448C271.229 36.4017 271.349 36.5301 271.469 36.6586C271.589 36.787 271.709 36.9154 271.949 37.0439L274.339 38.1998C274.579 38.0714 274.819 38.3283 274.819 38.4567C274.939 38.7136 275.059 38.9704 275.059 39.2273L275.179 40.5117L272.429 40.7686C271.949 40.897 271.589 41.2823 271.709 41.7961L271.719 41.7747ZM276.389 40.4903L276.149 38.446C276.029 37.1616 276.509 36.5194 277.469 36.4017C278.429 36.2733 278.909 36.787 279.029 38.0714L279.269 40.1157L276.399 40.501L276.389 40.4903Z'
          fill='white'
        />
        <path
          d='M268.73 33.0623C268.73 33.5761 269.09 33.8329 269.57 33.8329L276.51 33.1907C276.99 33.1907 277.23 32.8054 277.23 32.2917C277.23 31.7779 276.87 31.521 276.39 31.521H276.15C276.51 31.2642 276.75 31.0073 276.87 30.622C276.99 30.2367 277.11 29.7229 276.99 29.3376C276.99 28.6954 276.75 28.3101 276.51 27.9248C276.27 27.5395 275.79 27.2826 275.31 27.0257C274.83 26.8973 274.23 26.7689 273.63 26.7689C273.03 26.8973 272.43 27.0257 271.95 27.2826C271.47 27.5395 271.11 27.9248 270.99 28.4386C270.75 28.8239 270.63 29.466 270.75 29.9798C270.75 30.4935 270.87 30.8789 271.11 31.2642C271.35 31.6495 271.59 31.9064 271.95 32.0348L269.44 32.2917C268.84 32.1632 268.6 32.4201 268.72 33.0623H268.73ZM271.84 30.2474C271.84 29.7336 271.96 29.3483 272.2 28.963C272.56 28.5777 273.04 28.4493 273.64 28.3208C274.36 28.1924 274.84 28.3208 275.2 28.5777C275.56 28.8346 275.8 29.2199 275.8 29.7336C275.8 30.2474 275.68 30.6327 275.44 31.018C275.2 31.4033 274.6 31.5318 274 31.6602C273.4 31.7886 272.8 31.6602 272.44 31.4033C272.08 31.1464 271.96 30.7611 271.84 30.2474Z'
          fill='white'
        />
        <path
          d='M264.899 19.4907L262.029 60.473C261.789 63.2879 263.829 65.8567 266.459 66.1135L287.639 67.7832C290.269 68.0401 292.669 65.8567 292.909 62.9133L295.779 21.931C296.019 19.1161 293.979 16.5474 291.229 16.2905L270.049 14.6208C267.419 14.3639 265.139 16.5474 264.899 19.4907Z'
          fill='#87AA5F'
        />
        <path
          d='M265.97 18.4632L263.1 59.4455C262.86 62.2604 264.9 64.8292 267.53 65.086L288.71 66.7557C291.34 67.0126 293.74 64.8292 293.98 61.8858L296.85 20.9035C297.09 18.0886 295.05 15.5199 292.3 15.263L271.12 13.5933C268.49 13.4649 266.09 15.6376 265.97 18.4632Z'
          fill='#A2C97C'
        />
        <path
          d='M268.488 19.6192L265.738 58.0434C265.618 59.9699 266.938 61.6289 268.728 61.7574L287.998 63.2986C289.798 63.4271 291.348 62.0142 291.468 60.0984L294.218 21.6742C294.338 19.7476 293.018 18.0886 291.228 17.9602L271.958 16.4189C270.158 16.2905 268.608 17.7033 268.488 19.6192Z'
          fill='#BEDB8B'
        />
        <path
          d='M288.828 63.6839L284.998 63.4271C285.358 59.8415 288.348 57.155 291.698 57.5403H291.938L291.578 61.1259C291.458 62.6671 290.258 63.6839 288.828 63.6839Z'
          fill='#A2C97C'
        />
        <path
          d='M265.25 59.0709L265.49 54.8432C268.84 55.2285 271.23 58.4287 270.99 62.0143V62.2712L267.52 62.0143C266.2 61.8859 265.13 60.6015 265.25 59.0709Z'
          fill='#A2C97C'
        />
        <path
          d='M270.88 15.9052L274.71 16.2905C274.35 19.8761 271.36 22.5626 268.01 22.1772H267.77L268.01 18.4633C268.25 16.922 269.45 15.7768 270.88 15.9052Z'
          fill='#A2C97C'
        />
        <path
          d='M294.448 20.5182L294.208 24.746C290.858 24.3606 288.468 21.1604 288.708 17.5749V17.318L292.058 17.5749C293.498 17.7033 294.568 18.9877 294.448 20.5182Z'
          fill='#A2C97C'
        />
        <path
          d='M281.759 45.0498C281.659 45.6813 281.379 45.8846 280.909 45.799L273.739 45.0926C273.149 44.9856 272.959 44.6859 273.039 44.1828L273.329 40.9826C273.379 39.9551 273.739 39.238 274.199 38.6707C274.659 38.1035 275.409 37.9857 276.249 38.0178C276.839 38.1249 277.409 38.371 277.839 38.7135C278.249 39.1845 278.419 39.6019 278.529 40.4046C278.729 39.923 279.029 39.5912 279.539 39.4307L281.379 38.6065C281.639 38.5209 281.889 38.446 282.109 38.6065C282.319 38.7778 282.419 38.9276 282.359 39.3022C282.319 39.5591 282.299 39.6768 282.139 39.9123C281.999 40.0193 281.859 40.1157 281.609 40.2013L279.239 41.3144C278.979 41.4 278.709 41.6034 278.659 41.8603C278.499 42.085 278.459 42.3419 278.399 42.7165L278.309 44.0009L281.059 44.2578C281.569 44.0972 281.879 44.4183 281.779 45.0498H281.759ZM277.129 43.6477L277.349 41.6034C277.439 40.319 277.049 39.7303 276.109 39.5484C275.149 39.4949 274.689 40.0621 274.479 41.3251L274.259 43.3694L277.129 43.6584V43.6477Z'
          fill='white'
        />
        <path
          d='M284.909 36.284C284.829 36.787 284.529 37.1188 284.059 37.0332L277.139 36.2412C276.669 36.1555 276.359 35.8344 276.439 35.3314C276.519 34.8283 276.819 34.4966 277.289 34.5822L277.529 34.625C277.199 34.4323 276.999 34.1326 276.829 33.7152C276.659 33.2978 276.599 32.8911 276.689 32.388C276.789 31.7566 276.859 31.3819 277.179 30.9217C277.499 30.4615 277.889 30.2795 278.289 30.0869C278.799 29.9263 279.319 29.7658 280.019 29.8942C280.609 30.0012 281.199 30.119 281.629 30.4615C282.059 30.804 282.369 31.1251 282.659 31.5639C282.829 31.992 282.989 32.5379 282.879 33.1694C282.799 33.6724 282.729 34.047 282.439 34.3895C282.259 34.7427 281.979 34.9568 281.589 35.1387L284.109 35.3528C284.699 35.4598 284.889 35.7595 284.929 36.284H284.909ZM281.729 33.4691C281.809 32.966 281.639 32.5379 281.329 32.2275C281.019 31.9171 280.569 31.6923 279.859 31.5532C279.149 31.4141 278.639 31.5853 278.339 31.9171C277.919 32.2275 277.739 32.5807 277.779 33.1158C277.699 33.6189 277.869 34.047 278.179 34.3574C278.489 34.6785 278.939 34.8926 279.649 35.0317C280.239 35.1387 280.749 34.9782 281.169 34.6678C281.469 34.336 281.649 33.9828 281.729 33.4691Z'
          fill='white'
        />
        <path
          d='M277.938 48.8173C277.938 48.8173 276.618 50.7439 277.938 53.1735C279.258 55.6031 283.328 59.574 283.328 59.574L287.398 67.3873L288.478 65.846L284.168 56.7484L277.948 48.8066L277.938 48.8173Z'
          fill='#87AA5F'
        />
        <path
          d='M312.269 82.3394L309.169 79.1713L305.079 53.6765L294.649 43.5727L294.089 52.7774C296.909 54.5862 298.599 59.4134 298.879 61.0723L280.129 42.8128C277.729 42.9626 275.059 46.2806 278.019 49.5986C279.429 51.1077 282.809 55.1856 284.219 56.6948L288.589 65.5998C280.559 79.182 290.419 93.0533 295.359 95.4722L297.329 96.5318L312.269 82.3502V82.3394Z'
          fill='#F9E3D6'
        />
        <path
          d='M315.09 81.13L310.3 75.0934L290.85 96.2107L294.94 101.798C303.82 97.7198 311.01 90.3347 315.1 81.13H315.09Z'
          fill='#A2C97C'
        />
        <path
          d='M310.3 75.0934L329.64 96.5211L308.48 119.576L290.84 96.2214'
          fill='#A2C97C'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_13_6547'
          x1={164}
          y1={-0.500305}
          x2={164}
          y2={147.203}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#008AC0' />
          <stop offset={1} stopColor='#00ACF0' />
        </linearGradient>
        <clipPath id='clip0_13_6547'>
          <rect
            width={328}
            height={137}
            fill='white'
            transform='translate(0 -0.500305)'
          />
        </clipPath>
        <clipPath id='clip1_13_6547'>
          <rect
            width={221.728}
            height={287.862}
            fill='white'
            transform='translate(169.136 -42.7517)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LimitBackground;
