import { initGoogleAnalytics } from 'utils/Analytics/GoogleAnalytics/config';

const usePageTracking = () => {
  /**
   * initiate google analytics
   */
  initGoogleAnalytics();
};

export default usePageTracking;
