import store from './Store';
import validator from './Validator';
import commons from './Commons';
import string from './String';
import clipboard from './Clipboard';

const utils = {};

utils.store = store;
utils.validator = validator;
utils.commons = commons;
utils.string = string;
utils.clipboard = clipboard;

export default utils;
