import { css } from '@emotion/react';

export const applicationCx = css`
  position: relative;
  z-index: 1;

  background-color: #f7f7f7;
  margin-top: 3.5rem;

  .field-label {
    font-size: 0.75rem !important;
  }
`;
