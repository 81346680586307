import { SVGProps } from 'react';

const Loader = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={100}
    height={100}
    viewBox='0 0 100 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0C77.6142 0 100 22.3858 100 50ZM15 50C15 69.33 30.67 85 50 85C69.33 85 85 69.33 85 50C85 30.67 69.33 15 50 15C30.67 15 15 30.67 15 50Z'
      fill='#B2E6FA'
    />
    <path
      d='M50 0C58.9545 1.06781e-07 67.7446 2.40471 75.4521 6.9629C83.1595 11.5211 89.5015 18.0655 93.8153 25.9123C98.1292 33.7592 100.257 42.6205 99.9753 51.5705C99.7729 58.0115 98.3284 64.3283 95.7461 70.1817C94.0742 73.9714 89.3813 74.9921 85.8839 72.7726C82.3866 70.5532 81.4387 65.9301 82.8636 62.0408C84.1444 58.5452 84.8648 54.8524 84.9827 51.0994C85.1796 44.8344 83.6904 38.6314 80.6707 33.1386C77.651 27.6458 73.2117 23.0648 67.8165 19.874C62.4212 16.6833 56.2681 15 50 15V0Z'
      fill='url(#paint0_linear_3527_42174)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3527_42174'
        x1={79.5}
        y1={69}
        x2={50}
        y2={-0.00000810357}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#00ACF0' />
        <stop offset={1} stopColor='white' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export default Loader;
