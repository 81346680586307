import { css } from '@emotion/react';

export const detailInfoCx = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const detailContentCx = css`
  padding: 10px 30px;

  .content-margin {
    margin: 15px 0;
  }

  .content-button {
    margin-top: 15px;
  }
`;
