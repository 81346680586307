import { css } from '@emotion/react';

export const loanDetailHeaderCx = css`
  padding: 10px 30px;

  .header-title-text {
    color: #181818;
    font-weight: 700;
  }

  .header-subtitle-text {
    color: #9E9E9E; 
    font-weight: 700;

    span {
        color: #616161;
    }
  }
`;
