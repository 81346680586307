import { SVGProps } from 'react';
const ApplicationApproved = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={108}
    height={108}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <g clipPath='url(#b)'>
        <path
          fill='#C0F9FC'
          d='M77.288 104.648H30.627c-16.444 0-29.96-13.176-29.96-29.29V29.873C.667 13.842 14.183.667 30.627.667h46.66c16.53 0 29.961 13.176 29.961 29.205V75.36c.086 16.113-13.43 29.289-29.96 29.289Z'
        />
        <path
          fill='#8CE6F4'
          d='M78.751 98.941H29.766c-11.795 0-21.437-9.4-21.437-20.897v-6.882c0-11.497 9.642-20.897 21.437-20.897h48.986c11.794 0 21.436 9.4 21.436 20.897v6.882c0 11.498-9.642 20.897-21.437 20.897Z'
        />
        <path
          fill='#11748E'
          d='M82.195 12.08h-54.41c-4.562 0-8.35 3.609-8.35 8.14v82.246a30.796 30.796 0 0 0 11.191 2.098h46.662c4.735 0 9.211-1.091 13.172-3.021V20.137c0-4.364-3.702-8.057-8.265-8.057Z'
        />
        <path
          fill='#00ACF0'
          d='M80.043 15.353h-50.19c-3.789 0-6.888 3.022-6.888 6.714v81.574c2.41.671 4.993 1.007 7.662 1.007h46.661c3.358 0 6.63-.588 9.642-1.595V22.067c0-3.692-3.099-6.714-6.887-6.714Z'
        />
        <path
          fill='#fff'
          d='M29.852 15.353c-3.788 0-6.887 3.022-6.887 6.714v81.574c2.41.671 4.993 1.007 7.662 1.007h13.43l35.986-89.295h-50.19Z'
          opacity={0.15}
        />
        <path
          fill='#11748E'
          d='M65.235 19.382h-20.49c-1.808 0-3.357-1.51-3.357-3.273 0-1.763 1.55-3.273 3.357-3.273h20.49c1.808 0 3.357 1.51 3.357 3.273 0 1.762-1.55 3.273-3.357 3.273Z'
        />
        <path
          fill='#2291AA'
          d='M74.706 89.71H38.547c-.344 0-.688-.084-.947-.168-.344-.084-.602-.336-.86-.504-.259-.252-.43-.504-.517-.84-.086-.335-.172-.587-.172-.922V51.86c0-.252.086-.42.172-.671.086-.168.258-.42.344-.588L52.15 35.495c.344-.336.775-.504 1.291-.504h21.265c.344 0 .688.084.947.168.344.084.602.336.86.504.259.252.431.503.517.839.086.336.172.587.172.923v49.767c0 .335-.086.671-.172.923-.086.336-.344.587-.517.84-.258.25-.516.419-.86.503-.259.168-.603.251-.947.251Z'
        />
        <path
          fill='#fff'
          d='M73.242 88.115H37.083c-.344 0-.688-.084-.946-.168-.345-.084-.603-.336-.861-.504-.259-.251-.43-.503-.517-.839-.086-.335-.172-.587-.172-.923V50.266c0-.252.086-.42.172-.672.086-.168.258-.42.344-.587L50.686 33.9c.344-.335.775-.503 1.291-.503h21.35c.345 0 .69.084.948.168.344.084.602.335.86.503.259.252.431.504.517.84.086.335.172.587.172.923v49.766c0 .336-.086.672-.172.923-.086.336-.344.588-.516.84-.258.251-.517.42-.861.503-.345.252-.689.252-1.033.252Z'
        />
        <path
          fill='#D1D3D4'
          d='M34.931 50.014H49.05c.344 0 .689-.084 1.033-.168.345-.084.603-.336.861-.504.258-.252.43-.503.603-.839.172-.336.172-.587.172-.923V33.816c0-.084 0-.167-.086-.167 0-.084-.086-.084-.172-.168h-.173l-.172.084L34.76 49.426c-.086.084-.086.084-.086.168v.168c0 .084.086.084.172.168-.086.084 0 .084.086.084ZM70.056 55.972H40.87v3.441h29.185v-3.44ZM70.056 62.686H40.87v3.441h29.185v-3.44ZM70.056 69.484H40.87v3.44h29.185v-3.44ZM70.056 76.198H40.87v3.44h29.185v-3.44Z'
        />
      </g>
      <path
        fill='#7EAC54'
        d='M58.1 81.9 76 100.1l29.1-29.5-1.2-3.1L76 84.9l-19-6 1.1 3Z'
      />
      <path
        fill='#B8DD82'
        d='M57 78.9 74.9 97 104 67.5 96.5 60 74.9 81.9 64.5 71.3 57 78.9Z'
      />
      <path fill='#7EAC54' d='m74.9 97 1.1 3.1 29.1-29.5-1.1-3.1L74.9 97Z' />
      <path
        fill='#D8ED91'
        d='m87.8 70.6-5.2 16.5 5.7-5.6 5.2-17-5.7 6.1ZM84 74.5l-5.1 15.9 2.6-2.4 5-16.2-2.5 2.7ZM64.5 71.3l10.4 10.6L96.5 60 75 82.6 64.5 71.3Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M.667.667h106.667v106.667H.667z' />
      </clipPath>
      <clipPath id='b'>
        <path fill='#fff' d='M.667.667h106.667v106.667H.667z' />
      </clipPath>
    </defs>
  </svg>
);
export default ApplicationApproved;
