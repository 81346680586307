export const PRIVACY_POLICY = `
  <div class='privacy-policy'>
    <p>
      Baca lebih lengkap di
      <a href='https://www.julo.co.id/privacy-policy' target='_blank'>
        Kebijakan Privasi
      </a>
      JULO
    </p>
  </div>
`;
