import { otherPhoneLabelCx } from './styles';

const OtherPhoneLabel = () => {
  return (
    <div css={otherPhoneLabelCx} className='other-phone-label'>
      <span>Tidak Wajib</span>
    </div>
  );
};

export default OtherPhoneLabel;
