import { css } from '@emotion/css';

export const currencyInputCx = css`
  .currency-input-field {
    width: 100%;

    &.field-group {
      margin: 0;
    }
  }
`;
