import { SVGProps } from 'react';

const LoanConfirmation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={96}
    height={104}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#C0F9FC'
        d='M69 104H27C12.1 104 0 91.8 0 77V35C0 20.1 12.1 8 27 8h42c14.8 0 27 12.1 27 27v42c0 14.8-12.1 27-27 27Z'
      />
      <path
        fill='#8CE6F4'
        d='M65.998 98h-36.1c-13.8 0-25-11.2-25-25v-.1c0-13.8 11.2-24.9 25-24.9h36.1c13.8 0 24.9 11.2 24.9 24.9v.1c0 13.8-11.1 25-24.9 25Z'
      />
      <path
        fill='#3EAFCE'
        d='M72.982 89.844H30.12c-.431 0-.776-.086-1.121-.258-.345-.173-.69-.346-.949-.69a3.83 3.83 0 0 1-.69-.95 2.656 2.656 0 0 1-.258-1.12V43.79c0-.259.086-.517.172-.776s.259-.518.431-.69l18.542-18.37a2.02 2.02 0 0 1 1.466-.603h25.27c.43 0 .775.086 1.12.258.345.173.69.345.95.69.258.259.517.604.69.949.172.345.258.776.258 1.121v60.542c0 .431-.086.776-.259 1.121-.172.345-.345.69-.69.949a3.83 3.83 0 0 1-.949.69c-.344.086-.69.172-1.12.172Z'
      />
      <path
        fill='#fff'
        d='M71.256 87.948H28.394c-.432 0-.777-.086-1.122-.259-.345-.172-.69-.345-.948-.69a3.828 3.828 0 0 1-.69-.949 2.656 2.656 0 0 1-.259-1.12V41.98c0-.258.086-.517.172-.775.087-.26.26-.518.432-.69L44.52 22.059a2.02 2.02 0 0 1 1.466-.604h25.269c.431 0 .776.086 1.12.259.346.172.69.345.95.69.258.259.517.603.69.948.172.345.258.777.258 1.122v60.541c0 .432-.086.777-.258 1.122-.173.345-.346.69-.69.948a3.83 3.83 0 0 1-.95.69c-.258.173-.689.173-1.12.173Z'
      />
      <path
        fill='#BCBEC0'
        d='M25.806 41.636h16.73c.432 0 .777-.087 1.208-.259.345-.172.69-.345 1.035-.69.259-.259.518-.604.69-.949.173-.345.259-.776.259-1.12V21.885c0-.086 0-.172-.086-.258l-.173-.173h-.259c-.086 0-.172.086-.258.086L25.547 40.946c-.086.086-.086.172-.086.259v.258c0 .087.086.173.172.173h.173ZM67.462 48.88H32.879v4.14h34.583v-4.14ZM67.462 57.072H32.879v4.14h34.583v-4.14ZM67.462 65.266H32.879v4.14h34.583v-4.14ZM67.462 73.459H32.879v4.14h34.583v-4.14Z'
      />
      <path
        fill='#D52E57'
        d='M31.26 45.97c-.986.225-1.837.079-2.555-.437-.666-.528-1.185-1.372-1.559-2.533l-9.355-30.31c-.609-1.956-.555-3.638.16-5.047.705-1.462 2.016-2.412 3.936-2.848 1.867-.426 3.409-.125 4.624.9 1.255.96 2.032 2.453 2.33 4.48l4.686 31.373c.218 1.196.115 2.182-.309 2.957-.372.764-1.024 1.253-1.958 1.465Zm3.823 16.794c-1.764.401-3.32.16-4.666-.722-1.295-.894-2.144-2.229-2.549-4.005-.392-1.723-.191-3.24.601-4.553.832-1.378 2.13-2.268 3.894-2.67 1.815-.413 3.345-.166 4.587.74 1.283.84 2.12 2.121 2.512 3.844.405 1.776.217 3.347-.563 4.714-.729 1.354-2 2.238-3.816 2.652Z'
      />
      <path
        fill='#EE7988'
        d='M28.21 44.052c-.986.225-1.838.079-2.556-.437-.666-.528-1.185-1.372-1.558-2.533l-9.355-30.31c-.61-1.956-.556-3.638.16-5.047.704-1.462 2.015-2.411 3.935-2.848 1.867-.426 3.409-.125 4.624.9 1.255.96 2.032 2.453 2.33 4.48l4.686 31.373c.218 1.196.115 2.182-.309 2.958-.371.764-1.024 1.252-1.958 1.464Zm3.822 16.794c-1.764.401-3.319.16-4.666-.722-1.294-.894-2.144-2.229-2.548-4.005-.392-1.722-.192-3.24.6-4.553.832-1.378 2.13-2.268 3.894-2.67 1.816-.413 3.345-.166 4.588.74 1.282.84 2.12 2.121 2.512 3.844.404 1.776.216 3.347-.564 4.714-.728 1.354-2 2.238-3.816 2.652Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h96v104H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default LoanConfirmation;
