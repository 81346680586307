import { css } from '@emotion/react';

export const titleTextCx = css`
  color: #00acf0;
  font-weight: 700 !important;
  text-align: center;
`;

export const descriptionTextCx = css`
  color: #616161;
  text-align: center;
`;
