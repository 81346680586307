import { css } from '@emotion/css';

export const wrapperGuidance = css`
    padding-left: 20px;
`;

export const styleIconChecked = css`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-100%, -50%);
`;