import { css } from '@emotion/css';

export const inputStyle = css`
  border: none;
  color: #000000;
  font-size: 28px;
  font-weight: bold;
  margin-right: 26px;

  &[value=''] {
    border-bottom: 1px solid #e5e5e5;
  }
`;
export const redColor = css`
  color: #ff4646 !important;
`;
