import { css } from '@emotion/css';

export const textVerify = css`
  font-size: 16px !important;
  color: #5e5e5e;
`;

export const inputStyle = css`
  border: none;
  border-bottom: 1px solid #e5e5e5;
  color: #000000;
  font-size: 28px;
  font-weight: bold;
`;
export const redColor = css`
  color: #ff4646 !important;
`;

export const errorMessage = css`
  color: #db4d3d;
`;

export const blueColor = css`
  color: #00acf0;
`;
