const OJKLogo = (props: Omit<React.SVGProps<SVGSVGElement>, 'fill'>) => {
  return (
    <svg
      width={47}
      height={33}
      viewBox='0 0 47 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        x={0.905945}
        y={0.499695}
        width={45.3881}
        height={32}
        rx={4}
        fill='white'
        stroke='#EDEDED'
      />
      <g clipPath='url(#clip0_17_3870)'>
        <path
          d='M14.5774 14.1083V20.6266C14.5407 21.1686 14.3802 21.768 13.7245 21.768C13.4265 21.768 13.2981 21.7063 13.2339 21.5917C13.8004 20.7734 14.1005 19.8114 14.0959 18.8284C14.0958 18.2364 13.9887 17.6489 13.7796 17.092C13.7429 16.9995 13.7062 16.9113 13.6649 16.8232C13.3404 16.0481 12.7865 15.3815 12.0717 14.906C11.3569 14.4305 10.5128 14.1669 9.64373 14.148C7.18608 14.148 5.19153 16.2414 5.19153 18.8284C5.19153 21.4155 7.16774 23.5089 9.64373 23.5089C10.3857 23.5079 11.114 23.3162 11.7529 22.9536C12.2856 23.3261 12.9282 23.5253 13.587 23.5221C14.3147 23.5149 15.0183 23.2702 15.5823 22.8282C16.1463 22.3862 16.5373 21.773 16.6911 21.0893V14.1083H14.5774ZM10.6616 21.6534C10.3537 21.83 10.0023 21.9244 9.64373 21.9267C8.26818 21.9267 7.14023 20.5296 7.14023 18.8064C7.14023 17.0832 8.25901 15.6861 9.64373 15.6861C10.6662 15.6861 11.542 16.4441 11.9363 17.5371C12.0817 17.9457 12.1561 18.3746 12.1564 18.8064C12.1821 19.3643 12.0581 19.9192 11.7964 20.4178C11.5346 20.9164 11.1438 21.3419 10.6616 21.6534Z'
          fill='url(#paint0_radial_17_3870)'
        />
        <path
          d='M17.2826 17.6737V20.5076L18.5664 19.3661L21.4872 23.5397H24.3759L20.2675 17.5812L23.8073 13.9541H20.5885L17.2826 17.6737Z'
          fill='url(#paint1_radial_17_3870)'
        />
        <path
          d='M23.9173 14.0466C23.2495 14.11 22.5769 14.11 21.909 14.0466C21.8664 14.0512 21.8233 14.0512 21.7807 14.0466C18.571 13.1652 17.5852 11.843 15.554 10.349C13.3852 8.71834 8.18562 7.63417 3.49957 11.1908C4.49022 11.772 5.29836 12.6006 5.83801 13.5883L5.87928 13.6632C6.30604 14.5842 6.58582 15.5619 6.70919 16.5631C6.70919 16.5631 9.36401 13.6412 12.6699 13.5222C15.4485 13.4252 18.6673 14.4609 21.2167 14.7341C21.6625 14.7843 22.1114 14.805 22.5601 14.7959C25.2333 14.6989 26.2558 13.2269 26.2558 13.2269C26.2558 13.2269 25.6001 13.8792 23.9173 14.0466Z'
          fill='#2C2C2C'
        />
        <path
          d='M13.619 11.5301C17.5439 12.5349 18.5802 13.8483 21.776 13.8307H21.9274C21.8846 13.833 21.8417 13.833 21.799 13.8307C18.6306 12.958 17.6219 11.7328 15.6044 10.3137C13.4494 8.81969 8.50656 7.72671 3.84802 11.0938C4.72275 11.6976 5.43998 12.4881 5.94345 13.4032C6.07183 13.2577 6.2048 13.1255 6.33319 12.9977C8.88254 10.4636 11.3219 10.944 13.619 11.5301Z'
          fill='url(#paint2_linear_17_3870)'
        />
        <path
          d='M23.9219 13.8395C23.2586 13.9006 22.5907 13.9006 21.9274 13.8395H21.7761C18.5665 13.8395 16.8333 12.1647 13.8667 11.2436C11.6062 10.5429 8.88258 10.4724 6.33322 13.0065C6.20484 13.1343 6.07187 13.2665 5.94348 13.412C5.95381 13.4378 5.96607 13.4628 5.98016 13.4869C6.40867 14.352 6.6904 15.2775 6.81466 16.2282C6.81466 16.2282 9.46031 13.4649 12.7295 13.3547C15.4806 13.2577 18.6903 14.2361 21.2304 14.4961C21.6717 14.5462 22.116 14.5668 22.5601 14.5578C25.2287 14.4653 26.2971 13.1299 26.2971 13.1299C26.2971 13.1299 25.5955 13.6676 23.9219 13.8395Z'
          fill='url(#paint3_linear_17_3870)'
        />
        <path
          d='M26.8243 13.9673H26.3887V23.4207H26.8243V13.9673Z'
          fill='#660812'
        />
        <path
          d='M30.6117 15.36C30.6124 15.4977 30.5843 15.6342 30.5292 15.7614C30.4741 15.8885 30.393 16.0038 30.2907 16.1004C30.1853 16.1991 30.0606 16.277 29.9239 16.3295C29.6519 16.4352 29.3478 16.4352 29.0757 16.3295C28.9416 16.2747 28.8189 16.1971 28.7134 16.1004C28.6097 16.0039 28.5271 15.8886 28.4704 15.761C28.3606 15.5025 28.3606 15.213 28.4704 14.9545C28.5259 14.8263 28.6087 14.7107 28.7134 14.6151C28.8165 14.5156 28.9398 14.4375 29.0757 14.386C29.3493 14.2801 29.6549 14.2801 29.9285 14.386C30.0632 14.4397 30.1861 14.5175 30.2907 14.6151C30.3941 14.7109 30.4754 14.8265 30.5292 14.9545C30.5846 15.083 30.6127 15.2209 30.6117 15.36ZM30.2632 15.36C30.262 15.2603 30.2418 15.1617 30.2036 15.0691C30.1674 14.9778 30.1111 14.8951 30.0386 14.8267C29.9696 14.7574 29.887 14.7019 29.7955 14.6636C29.7027 14.6258 29.6029 14.6063 29.5021 14.6063C29.4013 14.6063 29.3015 14.6258 29.2086 14.6636C29.1163 14.7004 29.0333 14.756 28.9656 14.8267C28.8933 14.8961 28.8356 14.9785 28.796 15.0691C28.7584 15.1617 28.7397 15.2605 28.741 15.36C28.741 15.4579 28.7596 15.555 28.796 15.6464C28.8381 15.7357 28.8954 15.8176 28.9656 15.8888C29.0346 15.9581 29.1172 16.0136 29.2086 16.0519C29.3958 16.1224 29.6038 16.1224 29.791 16.0519C29.8832 16.0126 29.9672 15.9573 30.0386 15.8888C30.1091 15.8188 30.1651 15.7366 30.2036 15.6464C30.2414 15.5552 30.2616 15.4581 30.2632 15.36Z'
          fill='#2C2C2C'
        />
        <path
          d='M31.57 14.6284V16.356H31.2307V14.6284H30.6805V14.3551H32.1202V14.6284H31.57Z'
          fill='#2C2C2C'
        />
        <path
          d='M34.4128 15.36C34.4094 15.6382 34.2925 15.9041 34.0873 16.1004C33.9826 16.198 33.8597 16.2758 33.725 16.3295C33.5895 16.3821 33.4447 16.409 33.2986 16.4089C33.1524 16.4098 33.0075 16.3828 32.8722 16.3295C32.7381 16.2747 32.6154 16.1971 32.51 16.1004C32.4062 16.0039 32.3236 15.8886 32.2669 15.761C32.1571 15.5025 32.1571 15.213 32.2669 14.9545C32.3225 14.8263 32.4052 14.7107 32.51 14.6151C32.613 14.5156 32.7363 14.4375 32.8722 14.386C33.1458 14.2801 33.4514 14.2801 33.725 14.386C33.8597 14.4397 33.9826 14.5175 34.0873 14.6151C34.1921 14.7097 34.2736 14.8257 34.3257 14.9545C34.3848 15.082 34.4145 15.2203 34.4128 15.36ZM34.0597 15.36C34.0609 15.2601 34.0406 15.1611 34.0001 15.0691C33.9639 14.9778 33.9076 14.8951 33.8351 14.8267C33.7661 14.7574 33.6835 14.7019 33.5921 14.6636C33.4992 14.6258 33.3994 14.6063 33.2986 14.6063C33.1978 14.6063 33.098 14.6258 33.0052 14.6636C32.9128 14.7004 32.8299 14.756 32.7621 14.8267C32.6896 14.8951 32.6333 14.9778 32.5971 15.0691C32.5558 15.1608 32.5355 15.26 32.5375 15.36C32.5368 15.4583 32.5571 15.5558 32.5971 15.6464C32.6356 15.7366 32.6916 15.8188 32.7621 15.8888C32.8311 15.9581 32.9137 16.0136 33.0052 16.0519C33.0986 16.0872 33.1982 16.1052 33.2986 16.1048C33.3975 16.1051 33.4956 16.0872 33.5875 16.0519C33.6797 16.0126 33.7637 15.9573 33.8351 15.8888C33.9056 15.8188 33.9616 15.7366 34.0001 15.6464C34.0401 15.5558 34.0604 15.4583 34.0597 15.36Z'
          fill='#2C2C2C'
        />
        <path
          d='M35.1372 15.4569V16.3383H34.8163V14.3551H35.2748C35.384 14.3523 35.4932 14.3612 35.6003 14.3816C35.6705 14.3947 35.7364 14.4233 35.7929 14.4653C35.859 14.5153 35.911 14.5805 35.9442 14.6548C35.9781 14.7345 35.9953 14.8199 35.9947 14.906C36.004 15.0407 35.9616 15.174 35.8755 15.2806C35.7825 15.3772 35.6544 15.4356 35.5178 15.4437L36.2331 16.356H35.8525L35.1602 15.4745L35.1372 15.4569ZM35.1372 15.2057H35.1969C35.3198 15.2197 35.4443 15.1982 35.5545 15.144C35.5868 15.1187 35.612 15.0862 35.628 15.0493C35.644 15.0124 35.6503 14.9723 35.6462 14.9325C35.6512 14.8889 35.645 14.8449 35.6282 14.8042C35.6114 14.7635 35.5845 14.7273 35.5499 14.6989C35.4413 14.6447 35.3187 14.6218 35.1969 14.6328H35.1372V15.2057Z'
          fill='#2C2C2C'
        />
        <path
          d='M36.4349 16.356V14.3551H36.7742V16.356H36.4349Z'
          fill='#2C2C2C'
        />
        <path
          d='M37.9342 14.6284V16.356H37.5949V14.6284H37.0447V14.3551H38.4844V14.6284H37.9342Z'
          fill='#2C2C2C'
        />
        <path
          d='M39.9059 16.356L39.672 15.8404H38.8467L38.6037 16.356H38.2506L39.2639 14.2714L40.2727 16.356H39.9059ZM38.9888 15.5671H39.5711L39.3556 15.1264C39.3556 15.0955 39.3556 15.0603 39.319 15.025C39.2823 14.9898 39.2914 14.9457 39.2777 14.8972C39.2682 14.9391 39.2559 14.9803 39.241 15.0206C39.241 15.0603 39.241 15.0911 39.2043 15.1264L38.9888 15.5671Z'
          fill='#2C2C2C'
        />
        <path
          d='M40.3414 15.9549L40.612 15.8315C40.6312 15.9139 40.6799 15.9872 40.7495 16.0387C40.8272 16.087 40.9183 16.1116 41.0109 16.1092C41.0996 16.1143 41.1868 16.0859 41.2539 16.0298C41.283 16.0015 41.3058 15.9677 41.3208 15.9306C41.3358 15.8934 41.3426 15.8537 41.341 15.8139C41.341 15.6964 41.2401 15.5906 41.0384 15.4966L40.9696 15.4657C40.7988 15.398 40.6431 15.2992 40.5111 15.1749C40.4371 15.0846 40.3982 14.9724 40.401 14.8575C40.3977 14.7836 40.4112 14.7098 40.4405 14.6413C40.4698 14.5728 40.5142 14.5113 40.5707 14.4609C40.6969 14.3541 40.8613 14.2988 41.0292 14.3066C41.1635 14.3022 41.2966 14.3327 41.4144 14.3948C41.5152 14.4469 41.592 14.5334 41.6299 14.6372L41.3639 14.7694C41.3304 14.7137 41.2832 14.6668 41.2264 14.6328C41.1756 14.6023 41.1166 14.5869 41.0567 14.5887C40.9788 14.584 40.9019 14.6076 40.8412 14.6548C40.8142 14.678 40.7928 14.7066 40.7786 14.7386C40.7643 14.7705 40.7575 14.8051 40.7587 14.8399C40.7587 14.9589 40.8779 15.0735 41.1117 15.1749L41.153 15.1969C41.3129 15.2552 41.4582 15.3453 41.5794 15.4613C41.6575 15.5531 41.6982 15.669 41.6941 15.7875C41.6957 15.8694 41.6799 15.9507 41.6476 16.0265C41.6152 16.1023 41.5671 16.171 41.5061 16.2282C41.3684 16.3442 41.1892 16.4042 41.0063 16.3956C40.8439 16.4059 40.6827 16.3641 40.5478 16.2767C40.4414 16.1964 40.3682 16.0823 40.3414 15.9549Z'
          fill='#2C2C2C'
        />
        <path
          d='M28.4612 19.3485C28.4887 19.3838 28.5229 19.4137 28.5621 19.4366C28.594 19.4553 28.6303 19.466 28.6676 19.4675C28.6946 19.4698 28.7218 19.4648 28.7461 19.4531C28.7704 19.4415 28.7908 19.4235 28.8051 19.4014C28.8395 19.3282 28.8537 19.2478 28.8464 19.1678V17.7134H29.1765V19.2736C29.1894 19.4079 29.1484 19.5419 29.0619 19.6482C29.0125 19.6911 28.9544 19.7238 28.8913 19.7443C28.8282 19.7648 28.7614 19.7725 28.6951 19.7672C28.6114 19.7677 28.5286 19.7512 28.4521 19.7187C28.3718 19.6794 28.3001 19.6255 28.2411 19.56L28.4612 19.3485Z'
          fill='#2C2C2C'
        />
        <path
          d='M31.0106 19.7143L30.7768 19.1986H29.9514L29.7084 19.7143H29.3553L30.3503 17.6341L31.3591 19.7143H31.0106ZM30.0936 18.9298H30.6759L30.4604 18.4891C30.4604 18.4582 30.4374 18.4274 30.4237 18.3877C30.4099 18.348 30.3962 18.3084 30.3824 18.2643C30.3729 18.3047 30.3607 18.3444 30.3457 18.3833C30.3457 18.423 30.3228 18.4582 30.3091 18.4891L30.0936 18.9298Z'
          fill='#2C2C2C'
        />
        <path
          d='M31.437 19.3132L31.7075 19.1942C31.7271 19.2753 31.7758 19.3471 31.8451 19.3969C31.9241 19.4461 32.017 19.4707 32.111 19.4675C32.1982 19.4719 32.2838 19.4434 32.3495 19.3881C32.3791 19.3601 32.4021 19.3264 32.4171 19.2891C32.4322 19.2519 32.4388 19.212 32.4366 19.1722C32.4366 19.0546 32.3357 18.9489 32.134 18.8549L32.0652 18.8284C31.8937 18.7597 31.738 18.6594 31.6067 18.5331C31.5264 18.446 31.4852 18.332 31.492 18.2158C31.4887 18.1418 31.5022 18.068 31.5315 17.9996C31.5608 17.9311 31.6052 17.8695 31.6617 17.8192C31.7883 17.7136 31.9529 17.6598 32.1202 17.6693C32.2541 17.6635 32.3871 17.6925 32.5054 17.7531C32.607 17.8062 32.684 17.8944 32.7209 17.9999L32.4549 18.1277C32.42 18.073 32.373 18.0264 32.3174 17.991C32.2657 17.9627 32.2073 17.9475 32.1477 17.947C32.1087 17.9443 32.0696 17.9492 32.0326 17.9613C31.9956 17.9734 31.9614 17.9925 31.9322 18.0175C31.9051 18.0396 31.8835 18.0674 31.8692 18.0987C31.8549 18.13 31.8482 18.164 31.8497 18.1982C31.8497 18.3216 31.9689 18.4318 32.2027 18.5375H32.244C32.4035 18.5987 32.5485 18.6902 32.6704 18.8064C32.75 18.8973 32.791 19.0138 32.7851 19.1325C32.7876 19.2145 32.7721 19.2961 32.7397 19.372C32.7073 19.448 32.6587 19.5165 32.5971 19.5732C32.4594 19.6892 32.2802 19.7493 32.0973 19.7407C31.9349 19.751 31.7736 19.7091 31.6388 19.6217C31.5362 19.5446 31.4649 19.4355 31.437 19.3132Z'
          fill='#2C2C2C'
        />
        <path
          d='M34.5274 19.7143L34.2936 19.1986H33.4683L33.2253 19.7143H32.8722L33.8809 17.6341L34.8851 19.7143H34.5274ZM33.6104 18.9298H34.1744L33.9589 18.4891C33.9589 18.4582 33.936 18.4274 33.9222 18.3877C33.9084 18.348 33.8947 18.3084 33.8809 18.2643C33.8714 18.3047 33.8592 18.3444 33.8442 18.3833C33.8355 18.4196 33.8232 18.455 33.8076 18.4891L33.6104 18.9298Z'
          fill='#2C2C2C'
        />
        <path
          d='M28.4796 23.0725V21.0717H28.8189V21.9002L29.5663 21.0717H29.979L29.1536 21.9531L30.0707 23.0637H29.6351L28.8189 22.0413V23.0637L28.4796 23.0725Z'
          fill='#2C2C2C'
        />
        <path
          d='M30.2861 23.0725V21.0717H31.4691V21.3493H30.6254V21.8518H31.4691V22.1338H30.6254V22.7817H31.4691V23.0725H30.2861Z'
          fill='#2C2C2C'
        />
        <path
          d='M31.8268 21.0717H32.1615V22.2881C32.1511 22.4338 32.1968 22.5781 32.2899 22.6935C32.341 22.7416 32.4023 22.7786 32.4695 22.8021C32.5367 22.8257 32.6083 22.8352 32.6796 22.8302C32.751 22.8358 32.8228 22.8265 32.8901 22.803C32.9574 22.7794 33.0185 22.742 33.0694 22.6935C33.1645 22.5791 33.2104 22.434 33.1977 22.2881V21.0717H33.537V22.3189C33.5422 22.4295 33.5242 22.5399 33.484 22.6437C33.4439 22.7474 33.3824 22.8423 33.3032 22.9227C33.184 23.051 33.026 23.1403 32.8514 23.1779C32.6767 23.2156 32.4942 23.1998 32.3294 23.1327C32.1646 23.0657 32.0257 22.9507 31.9323 22.804C31.8389 22.6572 31.7956 22.4861 31.8084 22.3145L31.8268 21.0717Z'
          fill='#2C2C2C'
        />
        <path
          d='M35.3252 23.0725L35.0914 22.5569H34.2661L34.023 23.0725H33.67L34.6787 20.9923L35.6829 23.0725H35.3252ZM34.4082 22.2881H34.9905L34.775 21.8473L34.7383 21.746C34.7383 21.7107 34.7108 21.6666 34.6971 21.6226C34.6876 21.6644 34.6753 21.7056 34.6604 21.746C34.6509 21.7806 34.6387 21.8145 34.6237 21.8473L34.4082 22.2881Z'
          fill='#2C2C2C'
        />
        <path
          d='M35.8801 23.0725V20.9923L37.2052 22.2175L37.3106 22.3277C37.3519 22.3674 37.3886 22.4115 37.4299 22.4599V21.0717H37.7416V23.1519L36.3936 21.9046C36.355 21.8727 36.3197 21.8372 36.2881 21.7989C36.2538 21.7609 36.2217 21.7212 36.1919 21.6799V23.0725H35.8801Z'
          fill='#2C2C2C'
        />
        <path
          d='M39.374 22.0413H40.181V22.0986C40.181 22.1603 40.181 22.2043 40.181 22.2264C40.1878 22.3466 40.169 22.4669 40.1256 22.5798C40.0822 22.6927 40.0152 22.7959 39.9288 22.883C39.8325 22.9655 39.72 23.0287 39.598 23.0688C39.4759 23.1089 39.3468 23.1252 39.2181 23.1166C39.062 23.1203 38.9066 23.0964 38.7596 23.0461C38.6269 22.9976 38.5066 22.9225 38.4065 22.8257C38.3171 22.7253 38.25 22.6083 38.2093 22.482C38.1054 22.2118 38.1054 21.9148 38.2093 21.6446C38.2632 21.518 38.3445 21.4038 38.4478 21.3097C38.5487 21.2119 38.6708 21.1367 38.8054 21.0893C38.9519 21.0364 39.1075 21.011 39.2639 21.0144C39.4214 21.0083 39.5783 21.0369 39.7224 21.0981C39.8578 21.16 39.9769 21.2504 40.0709 21.3626L39.8233 21.5433C39.7581 21.4685 39.6768 21.4083 39.5849 21.367C39.4905 21.3289 39.3892 21.3094 39.2869 21.3097C39.1849 21.3062 39.0833 21.3232 38.9885 21.3597C38.8938 21.3961 38.808 21.4511 38.7366 21.5212C38.6644 21.5928 38.608 21.6778 38.571 21.771C38.5339 21.8642 38.517 21.9636 38.5211 22.0633C38.5174 22.1636 38.5345 22.2636 38.5715 22.3574C38.6085 22.4513 38.6647 22.5371 38.7366 22.6098C38.8045 22.6792 38.8861 22.7348 38.9767 22.7734C39.0672 22.812 39.1649 22.8328 39.2639 22.8346C39.4274 22.8426 39.5889 22.7976 39.7224 22.7067C39.7767 22.6634 39.8197 22.6087 39.8484 22.5468C39.877 22.4849 39.8904 22.4175 39.8875 22.3498V22.3233H39.374V22.0413Z'
          fill='#2C2C2C'
        />
        <path
          d='M41.937 23.0725L41.6986 22.5569H40.8733L40.6394 23.0725H40.2864L41.2951 20.9923L42.2993 23.0725H41.937ZM41.02 22.2881H41.5977L41.3868 21.8473C41.3707 21.8145 41.3569 21.7806 41.3456 21.746C41.3456 21.7107 41.318 21.6666 41.3043 21.6226C41.3043 21.6666 41.3043 21.7063 41.2722 21.746L41.2309 21.8473L41.02 22.2881Z'
          fill='#2C2C2C'
        />
        <path
          d='M42.4735 23.0725V20.9923L43.7986 22.2175L43.9087 22.3277L44.0233 22.4599V21.0717H44.3351V23.1519L42.9871 21.9046C42.9485 21.8727 42.9132 21.8372 42.8816 21.7989C42.8473 21.7609 42.8151 21.7212 42.7853 21.6799V23.0725H42.4735Z'
          fill='#2C2C2C'
        />
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_17_3870'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(15.444 18.3084) scale(7.94152 7.63325)'
        >
          <stop stopColor='#C40015' />
          <stop offset={0.14} stopColor='#AD0214' />
          <stop offset={0.37} stopColor='#8E0513' />
          <stop offset={0.59} stopColor='#780613' />
          <stop offset={0.81} stopColor='#6B0812' />
          <stop offset={1} stopColor='#660812' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_17_3870'
          cx={0}
          cy={0}
          r={1}
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(15.4439 18.3084) scale(7.94152 7.63325)'
        >
          <stop stopColor='#C40015' />
          <stop offset={0.14} stopColor='#AD0214' />
          <stop offset={0.37} stopColor='#8E0513' />
          <stop offset={0.59} stopColor='#780613' />
          <stop offset={0.81} stopColor='#6B0812' />
          <stop offset={1} stopColor='#660812' />
        </radialGradient>
        <linearGradient
          id='paint2_linear_17_3870'
          x1={20.1254}
          y1={13.1916}
          x2={2.61277}
          y2={9.79026}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E77D62' />
          <stop offset={0.02} stopColor='#D76854' />
          <stop offset={0.04} stopColor='#C24C41' />
          <stop offset={0.07} stopColor='#B23633' />
          <stop offset={0.1} stopColor='#A62628' />
          <stop offset={0.14} stopColor='#9F1D22' />
          <stop offset={0.19} stopColor='#9D1A20' />
          <stop offset={0.22} stopColor='#A01F23' />
          <stop offset={0.26} stopColor='#AA2C2C' />
          <stop offset={0.3} stopColor='#BB423B' />
          <stop offset={0.34} stopColor='#D2614F' />
          <stop offset={0.38} stopColor='#E77D62' />
          <stop offset={0.43} stopColor='#D76854' />
          <stop offset={0.52} stopColor='#C24C41' />
          <stop offset={0.61} stopColor='#B23632' />
          <stop offset={0.71} stopColor='#A62628' />
          <stop offset={0.83} stopColor='#9F1D22' />
          <stop offset={1} stopColor='#9D1A20' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_17_3870'
          x1={-2.05764}
          y1={13.9232}
          x2={22.2454}
          y2={12.9549}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7F8083' />
          <stop offset={0.15} stopColor='#828386' />
          <stop offset={0.26} stopColor='#8B8C8F' />
          <stop offset={0.35} stopColor='#9B9B9E' />
          <stop offset={0.44} stopColor='#B1B1B3' />
          <stop offset={0.52} stopColor='#CDCDCF' />
          <stop offset={0.59} stopColor='#EFEFF0' />
          <stop offset={0.62} stopColor='white' />
          <stop offset={0.64} stopColor='#F2F2F3' />
          <stop offset={0.69} stopColor='#D3D4D6' />
          <stop offset={0.73} stopColor='#BDBEC1' />
          <stop offset={0.77} stopColor='#B0B1B4' />
          <stop offset={0.81} stopColor='#ABADB0' />
          <stop offset={0.87} stopColor='#AEB0B3' />
          <stop offset={0.9} stopColor='#B7B9BB' />
          <stop offset={0.94} stopColor='#C7C8CA' />
          <stop offset={0.97} stopColor='#DDDEDF' />
          <stop offset={1} stopColor='white' />
        </linearGradient>
        <clipPath id='clip0_17_3870'>
          <rect
            width={40.8493}
            height={14.72}
            fill='white'
            transform='translate(3.49957 8.8197)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OJKLogo;
