import iconBackBlue from 'assets/img/icon/ic-arrow_left_blue.svg';
import iconBackBlack from 'assets/img/icon/ic-back-2.svg';

const themeJ1 = {
  colors: {
    error: '#f44336',
    primary: '#1ea7e9',
    white: '#fff',
    backgroundColorPrimary: '#f7f7f7',
    backgroundColorBlueLight: '#f1f7fe',
    borderGreyTransparent: 'hsla(0,0%,100%,.3)',
    borderLight: '#e0e0e0',
  },
  text: {
    primary: '#5e5e5e',
    primaryDisabled: 'hsla(0,0%,100%,.6)',
    blue: '#00acf0',
    danger: '#ff8a8a',
    success: '#5dc096',
    greyLight: '#727272',
  },
  navbar: {
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  helperBar: {
    color: '#5e5e5e',
    backgroundColor: '#fff',
    borderColor: '#e0e0e0',
    iconBack: iconBackBlue,
  },
  helperBarPrimary: {
    color: '#5e5e5e',
    backgroundColor: 'white',
    borderColor: '#e5e5e5',
    iconBack: iconBackBlack,
  },
  buttonPrimary: {
    disabled: '#e5e5e5',
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  buttonSecondary: {
    disabled: '#e5e5e5',
    color: '#1ea7e9',
    backgroundColor: '#fff',
    borderColor: '#fff',
  },
  buttonGrey: {
    disabled: '#e0e0e0',
    color: '#5e5e5e',
    backgroundColor: '#e0e0e0',
    borderColor: '#e0e0e0',
  },
  cardPrimary: {
    disabled: '#fff',
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  carousel: {
    indicator: {
      primary: '#00acf0',
      secondary: 'rgba(0, 172, 240, 0.3)',
    },
  },
  navbarApplication: {
    backgroundColor: '#00acf0',
  },
};

export default themeJ1;
