import iconBackBlue from 'assets/img/icon/ic-arrow_left_blue.svg';

const themeDefault = {
  colors: {
    primary: '#1ea7e9',
    white: '#fff',
    greyLight: '#e0e0e0',
    greyDark: '#5e5e5e',
    backdropCamera: 'rgb(34, 34, 34)',
  },
  text: {
    primary: '#5e5e5e',
    blue: '#00acf0',
    danger: '#ff8a8a',
    success: '#5dc096',
    greyLight: '#727272',
    glowBlue: '#52b7dfd5',
  },
  navbar: {
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  helperBar: {
    color: '#5e5e5e',
    backgroundColor: '#fff',
    borderColor: '#e0e0e0',
    iconBack: iconBackBlue,
  },
  buttonPrimary: {
    disabled: '#e5e5e5',
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
  cardPrimary: {
    disabled: '#fff',
    color: '#fff',
    backgroundColor: '#1ea7e9',
    borderColor: '#1ea7e9',
  },
};

export default themeDefault;
