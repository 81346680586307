import { css } from '@emotion/react';

export const contactCx = css`
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: 0.5rem;

  .contact-alert {
    margin-bottom: 1rem;
    padding: 0.5rem 0.75rem;

    background-color: #eaf0fe;
    border: 1px solid #83a7fa;
    border-radius: 0.5rem;

    color: #2656c6;
  }
`;
