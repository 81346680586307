import { SVGProps } from 'react';

const LoanVerifying = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={104}
    height={105}
    fill='none'
    {...props}
  >
    <g clipPath='url(#a)'>
      <path
        fill='#C0F9FC'
        d='M69.05 96.592H27.02C12.109 96.592 0 84.481 0 69.566v-42.04C0 12.612 12.109.5 27.02.5h42.03c14.81 0 27.02 12.112 27.02 27.026v42.04c0 14.915-12.11 27.026-27.02 27.026Z'
      />
      <path
        fill='#8CE6F4'
        d='M66.047 90.587H29.92c-13.81 0-25.019-11.21-25.019-24.924v-.1C4.902 51.75 16.11 40.54 29.92 40.54h36.127c13.81 0 24.918 11.21 24.918 24.924v.1c0 13.813-11.108 25.024-24.918 25.024Z'
      />
      <path
        fill='#7EAC54'
        d='m75.553 64.36-51.438 5.206c-3.602.4-6.905-2.303-7.205-5.906l-2.902-28.427c-.4-3.604 2.302-6.807 5.904-7.207L71.35 22.82c3.602-.4 6.905 2.302 7.205 5.905l2.902 28.428c.3 3.603-2.302 6.906-5.904 7.206Z'
      />
      <path
        fill='#98CB72'
        d='m76.654 63.06-51.437 5.205c-3.603.4-6.905-2.302-7.205-5.906L15.11 33.932c-.4-3.604 2.301-6.807 5.904-7.207L72.55 21.52c3.603-.4 6.905 2.302 7.206 5.905l2.902 28.428c.3 3.603-2.302 6.806-6.005 7.207Z'
      />
      <path
        fill='#B8DD82'
        d='m75.15 59.657-48.235 4.805c-2.402.2-4.503-1.502-4.703-3.904L19.61 34.633c-.2-2.302 1.5-4.504 3.903-4.705l48.234-4.904c2.402-.2 4.504 1.501 4.704 3.903l2.602 25.925a4.445 4.445 0 0 1-3.903 4.805Z'
      />
      <path
        fill='#98CB72'
        d='m19.113 33.53.5 5.206c4.503-.6 7.706-4.605 7.306-9.21v-.3l-4.704.501c-1.901.2-3.302 1.902-3.102 3.804ZM25.616 65.162l5.203-.5c-.6-4.405-4.703-7.708-9.206-7.208h-.3l.5 4.605c.2 1.902 1.901 3.303 3.803 3.103ZM79.754 56.353l-.5-5.205c-4.504.601-7.707 4.605-7.306 9.21v.3l4.703-.5c1.902-.301 3.303-2.003 3.103-3.805ZM73.15 24.623l-5.205.5c.6 4.404 4.704 7.707 9.207 7.207h.3l-.5-4.604c-.2-1.902-1.901-3.304-3.803-3.103Z'
      />
      <path
        fill='#fff'
        d='M37.127 52.148c-1.001.1-1.602-.4-1.702-1.401l-1.4-14.014c-.1-1 .4-1.601 1.4-1.701l5.805-.6c1.9-.201 3.402.1 4.503.9 1.1.8 1.701 2.002 1.901 3.603.1 1.302-.1 2.303-.7 3.204-.6.9-1.501 1.501-2.702 2.002.9.2 1.6.7 2.201 1.601l2.102 3.203c.3.5.4 1.001.3 1.401-.1.4-.5.701-1.2.801-.401 0-.801 0-1.102-.2-.3-.1-.5-.4-.8-.8l-2.902-4.205c-.3-.5-.7-.9-1.201-1-.4-.1-.9-.2-1.501-.1l-2.302.2.5 5.404c.3 1.002-.2 1.602-1.2 1.702Zm.6-9.409 3.803-.4c2.301-.2 3.302-1.301 3.102-3.103-.2-1.802-1.401-2.603-3.703-2.402l-3.803.4.601 5.505ZM53.837 54.852c-1 .1-1.6-.3-1.7-1.3l-1.402-13.414c-.1-1 .4-1.601 1.301-1.601 1.001-.1 1.601.3 1.701 1.301l.1.5c.3-.7.701-1.2 1.401-1.701.601-.4 1.402-.7 2.302-.8 1-.101 2.002.1 2.802.5.8.4 1.501 1.1 2.002 1.901.5.901.9 1.902 1 3.203.1 1.302 0 2.403-.3 3.304-.3 1-.9 1.701-1.6 2.302-.702.6-1.602.9-2.603 1-.8.1-1.601 0-2.302-.3-.7-.3-1.3-.7-1.7-1.3l.5 4.804c-.1.9-.5 1.501-1.502 1.601Zm3.703-7.006c.9-.1 1.601-.5 2.102-1.201.5-.701.7-1.702.5-3.003-.1-1.302-.5-2.203-1.1-2.803-.601-.6-1.402-.8-2.302-.7-.901.1-1.602.5-2.102 1.2-.5.701-.7 1.702-.5 3.003.1 1.302.5 2.202 1.1 2.803.701.5 1.502.8 2.302.7Z'
      />
      <path
        fill='#FAEFC3'
        d='M64.06 58.105v11.528l3.45 7.26 6.843 1.885-7.4 3.824-3.226 7.371.055 8.48 23.534.443s-.668-8.757-.612-8.812c.056-.056-1.168-5.21-1.168-5.21l-3.505-3.88-3.784-1.884s4.173-2.55 4.785-2.826c.612-.333 4.228-5.376 4.228-5.376V58.216l-23.2-.11Z'
        opacity={0.67}
      />
      <path
        fill='#F6C957'
        d='M63.503 99.614h1.391v-8.202c0-5.376 3.783-10.032 9.069-11.14a1.282 1.282 0 0 0 1.057-1.386c-.056-.665-.612-1.219-1.28-1.219-5.118-.277-9.124-4.489-9.124-9.588v-9.366h21.308v9.31c0 5.1-4.006 9.312-9.124 9.589-.668.055-1.224.554-1.28 1.22a1.282 1.282 0 0 0 1.058 1.385c5.285 1.108 9.068 5.764 9.068 11.14v6.927H63.503v1.33h1.391-1.39v1.33H86.98c.334 0 .724-.166.946-.388.278-.277.39-.61.39-.942v-8.202c0-6.65-4.674-12.36-11.183-13.745l-.278 1.33.055 1.33c6.565-.332 11.684-5.708 11.684-12.248V57.383c0-.333-.167-.72-.39-.942a1.455 1.455 0 0 0-.945-.388H63.28c-.333 0-.723.166-.945.388-.223.221-.39.61-.39.942v10.64c0 6.54 5.119 11.916 11.684 12.249l.055-1.33-.278-1.33c-6.51 1.33-11.183 7.094-11.183 13.744v8.258c0 .333.167.721.39.942.223.222.612.388.946.388v-1.33h-.056Z'
      />
      <path
        fill='#F09537'
        d='M88.986 58.825H61.67c-.89 0-1.669-.72-1.669-1.662a1.67 1.67 0 0 1 1.67-1.663h27.372c.89 0 1.669.72 1.669 1.663-.056.942-.779 1.662-1.725 1.662ZM88.986 101.276H61.67c-.89 0-1.669-.72-1.669-1.662a1.67 1.67 0 0 1 1.67-1.663h27.372c.89 0 1.669.72 1.669 1.663-.056.887-.779 1.662-1.725 1.662Z'
      />
      <path
        fill='#F9DD60'
        d='M66.344 67.195c0 4.988 4.061 8.979 9.013 8.979 4.951 0 9.013-4.046 9.013-8.979M83.81 96.236H66.954v-4.323a1.99 1.99 0 0 1 2.003-1.995h12.796a1.99 1.99 0 0 1 2.003 1.995v4.323h.056Z'
      />
      <path
        fill='#fff'
        d='M70.622 60.103v9.589s.501 4.766 4.284 7.149c0 0-8.345-.333-9.402-9.865v-6.817l5.118-.056ZM70.793 97.346v-9.09s.501-4.489 4.062-6.817c0 0-7.9.278-8.902 9.367v6.484l4.84.055Z'
        opacity={0.5}
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 .5h104v104H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default LoanVerifying;
