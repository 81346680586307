const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={104}
      height={52}
      viewBox='0 0 104 52'
      xmlns='http://www.w3.org/2000/svg'
      fill='#00ACF0'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M85.617 24.1243C86.8869 23.5803 88.2485 23.3151 89.6187 23.3448C91.6613 23.3356 93.6608 23.9654 95.3646 25.1548C97.0684 26.3441 98.4001 28.0396 99.1917 30.0273C99.9832 32.0149 100.199 34.2056 99.812 36.3226C99.425 38.4397 98.4524 40.3882 97.017 41.9222C95.5816 43.4563 93.7477 44.507 91.747 44.9417C89.7463 45.3765 87.6684 45.1758 85.7757 44.365C83.8829 43.5542 82.2602 42.1696 81.1124 40.3861C79.9646 38.6026 79.3431 36.5001 79.3265 34.344C79.2814 32.8982 79.5158 31.4578 80.0154 30.1108C80.515 28.7637 81.2693 27.5382 82.2324 26.5089C83.1955 25.4797 84.3471 24.6684 85.617 24.1243ZM86.2822 42.7081C87.3112 43.1573 88.4166 43.3782 89.5299 43.3572V43.3458C90.655 43.386 91.7759 43.18 92.822 42.7407C93.868 42.3015 94.8167 41.6385 95.6082 40.7935C96.3998 39.9485 97.0171 38.9397 97.4214 37.8306C97.8256 36.7216 98.0081 35.5362 97.9573 34.3491C97.984 33.1753 97.788 32.0077 97.3809 30.9151C96.9737 29.8224 96.3637 28.8267 95.5866 27.9866C94.8096 27.1465 93.8812 26.4789 92.8561 26.023C91.831 25.5672 90.7298 25.3324 89.6175 25.3324C88.5051 25.3324 87.404 25.5672 86.3789 26.023C85.3538 26.4789 84.4254 27.1465 83.6483 27.9866C82.8713 28.8267 82.2612 29.8224 81.8541 30.9151C81.447 32.0077 81.251 33.1753 81.2777 34.3491C81.2204 35.5228 81.3924 36.6964 81.7832 37.797C82.1739 38.8975 82.775 39.9014 83.549 40.7463C84.3229 41.5912 85.2533 42.259 86.2822 42.7081ZM63.9771 23.8173H61.9372L61.8484 44.8684L75.9553 44.9633V43.0634L63.8919 42.9722L63.9771 23.8173ZM51.379 36.2401C51.379 40.1273 49.3391 43.2571 44.8992 43.2571C40.4593 43.2571 38.5105 40.3173 38.5105 36.2401V23.9122H36.5593V36.3338C36.5593 41.4547 39.3109 45.247 44.9003 45.247C50.5786 45.247 53.4202 41.1697 53.4202 36.4288V24.0072H51.469L51.379 36.2401Z'
      />
      <path d='M4.62232 4H4.00073V4.6637H4.62232V4Z' />
      <path d='M7.01597 4.85376H6.39917V5.51746H7.02076L7.01597 4.85376Z' />
      <path d='M9.50121 5.896H8.87964V6.5597H9.50121V5.896Z' />
      <path d='M11.9856 6.84491H11.364V7.50861H11.9856V6.84491Z' />
      <path d='M14.4691 7.88745H13.8523V8.55116H14.4727L14.4691 7.88745Z' />
      <path d='M16.8645 8.74097H16.2429V9.40467H16.8645V8.74097Z' />
      <path d='M19.1719 9.68958H18.5515V10.3533H19.1719V9.68958Z' />
      <path d='M21.3879 10.4482H20.7664V11.1119H21.3879V10.4482Z' />
      <path d='M23.4309 11.2058H22.8093V11.8695H23.4309V11.2058Z' />
      <path d='M7.19653 6.46484H6.57495V7.12855H7.19653V6.46484Z' />
      <path d='M9.32424 7.22345H8.70386V7.88715H9.32424V7.22345Z' />
      <path d='M11.543 7.9823H10.9226V8.646H11.543V7.9823Z' />
      <path d='M13.7629 8.83582H13.1414V9.49952H13.7629V8.83582Z' />
      <path d='M15.9805 9.68958H15.3601V10.3533H15.9805V9.68958Z' />
      <path d='M18.1965 10.4482H17.575V11.1119H18.1965V10.4482Z' />
      <path d='M20.3242 11.2058H19.7039V11.8695H20.3242V11.2058Z' />
      <path d='M22.2774 11.9644H21.657V12.6307H22.2774V11.9644Z' />
      <path d='M24.2278 12.5333H23.6062V13.197H24.2278V12.5333Z' />
      <path d='M9.58987 8.74097H8.96948V9.40467H9.58987V8.74097Z' />
      <path d='M11.4504 9.40491H10.8289V10.0686H11.4504V9.40491Z' />
      <path d='M13.4075 10.0696H12.7859V10.7333H13.4075V10.0696Z' />
      <path d='M15.4465 10.7332H14.825V11.3969H15.4465V10.7332Z' />
      <path d='M17.3985 11.3958H16.7781V12.0595H17.3985V11.3958Z' />
      <path d='M19.4387 12.1544H18.8171V12.8181H19.4387V12.1544Z' />
      <path d='M21.302 12.7233H20.6804V13.387H21.302V12.7233Z' />
      <path d='M23.1653 13.388H22.5437V14.0517H23.1653V13.388Z' />
      <path d='M24.9387 13.9571H24.3171V14.6208H24.9387V13.9571Z' />
      <path d='M11.6301 10.9221H11.0085V11.5858H11.6301V10.9221Z' />
      <path d='M13.3164 11.3958H12.696V12.0595H13.3164V11.3958Z' />
      <path d='M15.0911 11.9644H14.4695V12.6307H15.0911V11.9644Z' />
      <path d='M16.9544 12.5333H16.3328V13.197H16.9544V12.5333Z' />
      <path d='M18.7317 13.1018H18.1101V13.7655H18.7317V13.1018Z' />
      <path d='M20.5051 13.6719H19.8835V14.3356H20.5051V13.6719Z' />
      <path d='M22.2774 14.2405H21.657V14.9042H22.2774V14.2405Z' />
      <path d='M24.0508 14.7157H23.4304V15.3794H24.0508V14.7157Z' />
      <path d='M25.6497 15.1891H25.0281V15.8529H25.6497V15.1891Z' />
      <path d='M13.4973 12.913H12.8757V13.5767H13.4973V12.913Z' />
      <path d='M15.0051 13.2932H14.3835V13.9569H15.0051V13.2932Z' />
      <path d='M16.5989 13.7668H15.9773V14.4305H16.5989V13.7668Z' />
      <path d='M18.2864 14.2405H17.6648V14.9042H18.2864V14.2405Z' />
      <path d='M19.884 14.7157H19.2625V15.3794H19.884V14.7157Z' />
      <path d='M21.5676 15.1891H20.946V15.8529H21.5676V15.1891Z' />
      <path d='M23.1653 15.6628H22.5437V16.3265H23.1653V15.6628Z' />
      <path d='M24.7617 16.0416H24.1414V16.7053H24.7617V16.0416Z' />
      <path d='M26.2656 16.5166H25.6453V17.1803H26.2656V16.5166Z' />
      <path d='M15.0911 14.8106H14.4695V15.4743H15.0911V14.8106Z' />
      <path d='M16.509 15.0945H15.8875V15.7582H16.509V15.0945Z' />
      <path d='M17.9309 15.4743H17.3093V16.138H17.9309V15.4743Z' />
      <path d='M19.4387 15.8531H18.8171V16.5169H19.4387V15.8531Z' />
      <path d='M20.9504 16.2319H20.3289V16.8982H20.9504V16.2319Z' />
      <path d='M22.4544 16.6118H21.8328V17.2755H22.4544V16.6118Z' />
      <path d='M23.9622 16.9918H23.3406V17.6555H23.9622V16.9918Z' />
      <path d='M25.384 17.3706H24.7625V18.0343H25.384V17.3706Z' />
      <path d='M26.802 17.7491H26.1804V18.4128H26.802V17.7491Z' />
      <path d='M16.427 16.5166H15.8054V17.1803H16.427V16.5166Z' />
      <path d='M17.7491 16.799H17.1335V17.4627H17.7539L17.7491 16.799Z' />
      <path d='M19.0832 17.0867H18.4617V17.7504H19.0832V17.0867Z' />
      <path d='M20.5051 17.3706H19.8835V18.0343H20.5051V17.3706Z' />
      <path d='M21.9222 17.7491H21.3054V18.4128H21.9258L21.9222 17.7491Z' />
      <path d='M23.2551 18.0341H22.6335V18.6978H23.2551V18.0341Z' />
      <path d='M24.6731 18.3177H24.0515V18.9814H24.6731V18.3177Z' />
      <path d='M26.0051 18.6029H25.3835V19.2666H26.0051V18.6029Z' />
      <path d='M27.3372 18.8878H26.7156V19.5515H27.3372V18.8878Z' />
      <path d='M17.5793 18.1328H16.9578V18.7965H17.5793V18.1328Z' />
      <path d='M18.8203 18.3177H18.2V18.9814H18.8203V18.3177Z' />
      <path d='M20.1457 18.6029H19.5242V19.2666H20.1457V18.6029Z' />
      <path d='M21.3879 18.7927H20.7664V19.4564H21.3879V18.7927Z' />
      <path d='M22.72 19.0764H22.0984V19.7401H22.72V19.0764Z' />
      <path d='M23.9622 19.3615H23.3406V20.0252H23.9622V19.3615Z' />
      <path d='M25.2942 19.5516H24.6726V20.2153H25.2942V19.5516Z' />
      <path d='M26.5364 19.835H25.9148V20.4974H26.5364V19.835Z' />
      <path d='M27.7774 20.0252H27.157V20.6889H27.7774V20.0252Z' />
      <path d='M18.6394 19.6467H18.0203V20.3104H18.6418L18.6394 19.6467Z' />
      <path d='M19.7942 19.7403H19.1726V20.404H19.7942V19.7403Z' />
      <path d='M20.9504 20.0252H20.3289V20.6889H20.9504V20.0252Z' />
      <path d='M22.1887 20.215H21.5671V20.8787H22.1887V20.215Z' />
      <path d='M23.3438 20.4002H22.7234V21.0639H23.3438V20.4002Z' />
      <path d='M24.5842 20.5938H23.9578V21.2575H24.5793L24.5842 20.5938Z' />
      <path d='M25.8176 20.7841H25.196V21.4478H25.8176V20.7841Z' />
      <path d='M26.9817 20.9726H26.3601V21.6363H26.9805L26.9817 20.9726Z' />
      <path d='M28.1328 21.1639H27.5125V21.8314H28.1328V21.1639Z' />
      <path d='M19.4387 20.9726H18.8171V21.6363H19.4387V20.9726Z' />
      <path d='M20.5911 21.1639H19.9695V21.8314H20.5911V21.1639Z' />
      <path d='M21.6536 21.2575H21.032V21.9212H21.6536V21.2575Z' />
      <path d='M22.8086 21.4476H22.1882V22.1113H22.8098L22.8086 21.4476Z' />
      <path d='M23.9622 21.6364H23.3406V22.3001H23.9622V21.6364Z' />
      <path d='M25.1133 21.8264H24.4929V22.4901H25.1133V21.8264Z' />
      <path d='M26.2656 21.9215H25.6453V22.5852H26.2656V21.9215Z' />
      <path d='M27.3372 22.111H26.7156V22.7747H27.3372V22.111Z' />
      <path d='M28.3997 22.2062H27.7781V22.8699H28.3997V22.2062Z' />
      <path d='M20.1457 22.3003H19.5242V22.964H20.1457V22.3003Z' />
      <path d='M21.2122 22.399H20.5906V23.0628H21.2122V22.399Z' />
      <path d='M22.2774 22.585H21.657V23.2487H22.2774V22.585Z' />
      <path d='M23.3438 22.6801H22.7234V23.3438H23.3438V22.6801Z' />
      <path d='M24.4063 22.775H23.7859V23.4387H24.4063V22.775Z' />
      <path d='M25.5586 22.965H24.9382V23.6287H25.5586V22.965Z' />
      <path d='M26.625 23.0651H26.0046V23.7288H26.625V23.0651Z' />
      <path d='M27.6887 23.1538H27.0671V23.8175H27.6887V23.1538Z' />
      <path d='M28.6653 23.2487H28.0437V23.9124H28.6653V23.2487Z' />
      <path d='M20.7696 23.5336H20.1492V24.1973H20.7696V23.5336Z' />
      <path d='M21.7473 23.6285H21.1257V24.2922H21.7473V23.6285Z' />
      <path d='M22.72 23.7236H22.0984V24.3873H22.72V23.7236Z' />
      <path d='M23.7903 23.8175H23.1687V24.4812H23.7903V23.8175Z' />
      <path d='M24.8528 23.9124H24.2312V24.5761H24.8528V23.9124Z' />
      <path d='M25.8176 24.0073H25.196V24.671H25.8176V24.0073Z' />
      <path d='M26.8918 24.1025H26.2703V24.7649H26.8918V24.1025Z' />
      <path d='M27.8642 24.1976H27.239V24.8613H27.8606L27.8642 24.1976Z' />
      <path d='M28.9309 24.2925H28.3093V24.9562H28.9309V24.2925Z' />
      <path d='M21.2122 24.7659H20.5906V25.4309H21.2122V24.7659Z' />
      <path d='M22.1887 24.7659H21.5671V25.4309H22.1887V24.7659Z' />
      <path d='M23.1653 24.8598H22.5437V25.5235H23.1653V24.8598Z' />
      <path d='M24.1418 24.9562H23.5203V25.6199H24.1418V24.9562Z' />
      <path d='M25.1133 24.9562H24.4929V25.6199H25.1133V24.9562Z' />
      <path d='M26.0926 25.0511H25.4734V25.7148H26.095L26.0926 25.0511Z' />
      <path d='M27.1575 25.146H26.5359V25.8097H27.1575V25.146Z' />
      <path d='M28.1328 25.2399H27.5125V25.9036H28.1328V25.2399Z' />
      <path d='M29.0208 25.2399H28.3992V25.9036H29.0208V25.2399Z' />
      <path d='M21.5676 25.8097H20.946V26.4734H21.5676V25.8097Z' />
      <path d='M22.4544 25.9046H21.8328V26.5683H22.4544V25.9046Z' />
      <path d='M23.4309 25.9046H22.8093V26.5683H23.4309V25.9046Z' />
      <path d='M24.4063 25.9985H23.7859V26.6622H24.4063V25.9985Z' />
      <path d='M25.384 25.9985H24.7625V26.6622H25.384V25.9985Z' />
      <path d='M26.3567 26.0936H25.7351V26.7637H26.3567V26.0936Z' />
      <path d='M27.3372 26.0936H26.7156V26.7637H27.3372V26.0936Z' />
      <path d='M28.22 26.1885H27.5984V26.8522H28.22V26.1885Z' />
      <path d='M29.1953 26.1885H28.575V26.8522H29.1953V26.1885Z' />
      <path d='M21.8321 26.8525H21.2117V27.5162H21.8321V26.8525Z' />
      <path d='M22.72 26.9471H22.0984V27.6109H22.72V26.9471Z' />
      <path d='M23.6977 26.9471H23.075V27.6109H23.6953L23.6977 26.9471Z' />
      <path d='M24.5842 26.9471H23.9578V27.6109H24.5793L24.5842 26.9471Z' />
      <path d='M25.5586 27.042H24.9382V27.7057H25.5586V27.042Z' />
      <path d='M26.5364 27.042H25.9148V27.7057H26.5364V27.042Z' />
      <path d='M27.4258 27.1359H26.8054V27.7996H27.4258V27.1359Z' />
      <path d='M28.3137 27.1359H27.6921V27.7996H28.3137V27.1359Z' />
      <path d='M29.2864 27.1359H28.6648V27.7996H29.2864V27.1359Z' />
      <path d='M22.0129 27.8948H21.3914V28.5585H22.0129V27.8948Z' />
      <path d='M22.8997 27.8948H22.2781V28.5585H22.8997V27.8948Z' />
      <path d='M23.8723 27.9899H23.2507V28.6536H23.8723V27.9899Z' />
      <path d='M24.7617 27.9899H24.1414V28.6536H24.7617V27.9899Z' />
      <path d='M25.7395 27.9899H25.1179V28.6536H25.7395V27.9899Z' />
      <path d='M26.625 27.9899H26.0046V28.6536H26.625V27.9899Z' />
      <path d='M27.509 28.0858H26.8875V28.7495H27.509V28.0858Z' />
      <path d='M28.3997 28.0858H27.7781V28.7495H28.3997V28.0858Z' />
      <path d='M29.3765 28.0858H28.7585V28.7495H29.3801L29.3765 28.0858Z' />
      <path d='M22.1887 28.8434H21.5671V29.5071H22.1887V28.8434Z' />
      <path d='M23.0754 28.8434H22.4539V29.5071H23.0754V28.8434Z' />
      <path d='M23.9622 28.9321H23.3406V29.5958H23.9622V28.9321Z' />
      <path d='M24.8528 28.9321H24.2312V29.5958H24.8528V28.9321Z' />
      <path d='M25.8176 28.9321H25.196V29.5958H25.8176V28.9321Z' />
      <path d='M26.711 28.9321H26.0906V29.5958H26.711V28.9321Z' />
      <path d='M27.5989 28.9321H26.9773V29.5958H27.5989V28.9321Z' />
      <path d='M28.4862 29.0334H27.8718V29.6971H28.4922L28.4862 29.0334Z' />
      <path d='M29.3765 29.0334H28.7585V29.6971H29.3801L29.3765 29.0334Z' />
      <path d='M22.2774 29.7921H21.657V30.4558H22.2774V29.7921Z' />
      <path d='M23.1653 29.7921H22.5437V30.4558H23.1653V29.7921Z' />
      <path d='M24.0508 29.8872H23.4304V30.5509H24.0508V29.8872Z' />
      <path d='M24.9387 29.8872H24.3171V30.5509H24.9387V29.8872Z' />
      <path d='M25.8176 29.8872H25.196V30.5509H25.8176V29.8872Z' />
      <path d='M26.711 29.8872H26.0906V30.5509H26.711V29.8872Z' />
      <path d='M27.5989 29.8872H26.9773V30.5509H27.5989V29.8872Z' />
      <path d='M28.4862 29.8872H27.8718V30.5509H28.4922L28.4862 29.8872Z' />
      <path d='M29.3765 29.8872H28.7585V30.5509H29.3801L29.3765 29.8872Z' />
      <path d='M22.3645 30.7407H21.7429V31.4044H22.3645V30.7407Z' />
      <path d='M23.2551 30.7407H22.6335V31.4044H23.2551V30.7407Z' />
      <path d='M24.1418 30.7407H23.5203V31.4044H24.1418V30.7407Z' />
      <path d='M25.0247 30.7407H24.4031V31.4044H25.0247V30.7407Z' />
      <path d='M25.9141 30.7407H25.2937V31.4044H25.9141V30.7407Z' />
      <path d='M26.802 30.7407H26.1804V31.4044H26.802V30.7407Z' />
      <path d='M27.6887 30.7407H27.0671V31.4044H27.6887V30.7407Z' />
      <path d='M28.5794 30.8343H27.9578V31.498H28.5794V30.8343Z' />
      <path d='M29.4661 30.8343H28.8445V31.498H29.4661V30.8343Z' />
      <path d='M22.3645 31.688H21.7429V32.3518H22.3645V31.688Z' />
      <path d='M23.2551 31.688H22.6335V32.3518H23.2551V31.688Z' />
      <path d='M24.1418 31.688H23.5203V32.3518H24.1418V31.688Z' />
      <path d='M25.0247 31.688H24.4031V32.3518H25.0247V31.688Z' />
      <path d='M25.9141 31.688H25.2937V32.3518H25.9141V31.688Z' />
      <path d='M26.802 31.688H26.1804V32.3518H26.802V31.688Z' />
      <path d='M27.6887 31.688H27.0671V32.3518H27.6887V31.688Z' />
      <path d='M28.5794 31.688H27.9578V32.3518H28.5794V31.688Z' />
      <path d='M29.4661 31.688H28.8445V32.3518H29.4661V31.688Z' />
      <path d='M22.4544 32.5421H21.8328V33.2058H22.4544V32.5421Z' />
      <path d='M23.3438 32.5421H22.7234V33.2058H23.3438V32.5421Z' />
      <path d='M24.1418 32.5421H23.5203V33.2058H24.1418V32.5421Z' />
      <path d='M25.0247 32.5421H24.4031V33.2058H25.0247V32.5421Z' />
      <path d='M25.9141 32.5421H25.2937V33.2058H25.9141V32.5421Z' />
      <path d='M26.802 32.5421H26.1804V33.2058H26.802V32.5421Z' />
      <path d='M27.6887 32.5421H27.0671V33.2058H27.6887V32.5421Z' />
      <path d='M28.5794 32.5421H27.9578V33.2058H28.5794V32.5421Z' />
      <path d='M29.4661 32.5421H28.8445V33.2058H29.4661V32.5421Z' />
      <path d='M22.4544 33.4892H21.8328V34.1529H22.4544V33.4892Z' />
      <path d='M23.3438 33.4892H22.7234V34.1529H23.3438V33.4892Z' />
      <path d='M24.2278 33.4892H23.6062V34.1529H24.2278V33.4892Z' />
      <path d='M25.0247 33.4892H24.4031V34.1529H25.0247V33.4892Z' />
      <path d='M25.9141 33.4892H25.2937V34.1529H25.9141V33.4892Z' />
      <path d='M26.802 33.4892H26.1804V34.1529H26.802V33.4892Z' />
      <path d='M27.6887 33.4892H27.0671V34.1529H27.6887V33.4892Z' />
      <path d='M28.5794 33.4892H27.9578V34.1529H28.5794V33.4892Z' />
      <path d='M29.4661 33.4892H28.8445V34.1529H29.4661V33.4892Z' />
      <path d='M22.4544 34.3442H21.8328V35.0079H22.4544V34.3442Z' />
      <path d='M23.3438 34.3442H22.7234V35.0079H23.3438V34.3442Z' />
      <path d='M24.2278 34.3442H23.6062V35.0079H24.2278V34.3442Z' />
      <path d='M25.0247 34.3442H24.4031V35.0079H25.0247V34.3442Z' />
      <path d='M25.9141 34.3442H25.2937V35.0079H25.9141V34.3442Z' />
      <path d='M26.802 34.3442H26.1804V35.0079H26.802V34.3442Z' />
      <path d='M27.6887 34.3442H27.0671V35.0079H27.6887V34.3442Z' />
      <path d='M28.5794 34.3442H27.9578V35.0079H28.5794V34.3442Z' />
      <path d='M29.4661 34.3442H28.8445V35.0079H29.4661V34.3442Z' />
      <path d='M12.8724 35.7653L12.5125 35.9552H12.8724V35.7653Z' />
      <path d='M13.7607 35.9566V35.4816L13.0515 35.7666V35.9566H13.7607Z' />
      <path d='M14.5598 35.9553C14.5508 35.7315 14.5206 35.5092 14.4698 35.2916C14.2934 35.2916 14.1098 35.3866 13.9382 35.3866V35.8616H14.5598V35.9553Z' />
      <path d='M22.4544 35.198H21.8328V35.8617H22.4544V35.198Z' />
      <path d='M23.3438 35.198H22.7234V35.8617H23.3438V35.198Z' />
      <path d='M24.2278 35.198H23.6062V35.8617H24.2278V35.198Z' />
      <path d='M25.0247 35.198H24.4031V35.8617H25.0247V35.198Z' />
      <path d='M25.9141 35.198H25.2937V35.8617H25.9141V35.198Z' />
      <path d='M26.802 35.198H26.1804V35.8617H26.802V35.198Z' />
      <path d='M27.6887 35.198H27.0671V35.8617H27.6887V35.198Z' />
      <path d='M28.5794 35.198H27.9578V35.8617H28.5794V35.198Z' />
      <path d='M29.4661 35.198H28.8445V35.8617H29.4661V35.198Z' />
      <path d='M10.2096 36.7126L10.032 36.8064H10.2096V36.7126Z' />
      <path d='M11.0989 36.809V36.429L10.4773 36.619V36.809H11.0989Z' />
      <path d='M11.9856 36.8088V36.1451L11.364 36.3351V36.81L11.9856 36.8088Z' />
      <path d='M12.8723 36.1451H12.2507V36.8088H12.8723V36.1451Z' />
      <path d='M13.6719 36.1451H13.0515V36.8088H13.6719V36.1451Z' />
      <path d='M14.5598 36.1451H13.9382V36.8088H14.5598V36.1451Z' />
      <path d='M14.9149 36.809C14.9149 36.714 14.9149 36.714 14.825 36.619L14.9149 36.809Z' />
      <path d='M21.5712 36.429C21.5846 36.5629 21.5527 36.6975 21.4812 36.809H21.5712V36.429Z' />
      <path d='M22.4544 36.1451H21.8328V36.8088H22.4544V36.1451Z' />
      <path d='M23.3438 36.1451H22.7234V36.8088H23.3438V36.1451Z' />
      <path d='M24.2278 36.1451H23.6062V36.8088H24.2278V36.1451Z' />
      <path d='M25.0247 36.1451H24.4031V36.8088H25.0247V36.1451Z' />
      <path d='M25.9141 36.1451H25.2937V36.8088H25.9141V36.1451Z' />
      <path d='M26.802 36.1451H26.1804V36.8088H26.802V36.1451Z' />
      <path d='M27.6887 36.1451H27.0671V36.8088H27.6887V36.1451Z' />
      <path d='M28.5794 36.1451H27.9578V36.8088H28.5794V36.1451Z' />
      <path d='M29.4661 36.1451H28.8445V36.8088H29.4661V36.1451Z' />
      <path d='M8.52752 37.6627V37.3777L7.81714 37.5677V37.6627H8.52752Z' />
      <path d='M9.32424 37.6628V36.9991L8.70386 37.2841V37.6641L9.32424 37.6628Z' />
      <path d='M10.2082 36.9991H9.58667V37.6628H10.2082V36.9991Z' />
      <path d='M11.0989 36.9991H10.4773V37.6628H11.0989V36.9991Z' />
      <path d='M11.9856 36.9991H11.364V37.6628H11.9856V36.9991Z' />
      <path d='M12.8723 36.9991H12.2507V37.6628H12.8723V36.9991Z' />
      <path d='M13.6719 36.9991H13.0515V37.6628H13.6719V36.9991Z' />
      <path d='M14.5598 36.9991H13.9382V37.6628H14.5598V36.9991Z' />
      <path d='M14.825 37.6628H15.2701C15.1801 37.4729 15.0925 37.1879 15.0037 36.9991H14.8262L14.825 37.6628Z' />
      <path d='M21.5667 36.9991C21.4779 37.1891 21.3903 37.4741 21.3015 37.6628H21.5667V36.9991Z' />
      <path d='M22.4544 36.9991H21.8328V37.6628H22.4544V36.9991Z' />
      <path d='M23.3438 36.9991H22.7234V37.6628H23.3438V36.9991Z' />
      <path d='M24.2278 36.9991H23.6062V37.6628H24.2278V36.9991Z' />
      <path d='M25.0247 36.9991H24.4031V37.6628H25.0247V36.9991Z' />
      <path d='M25.9141 36.9991H25.2937V37.6628H25.9141V36.9991Z' />
      <path d='M26.802 36.9991H26.1804V37.6628H26.802V36.9991Z' />
      <path d='M27.6887 36.9991H27.0671V37.6628H27.6887V36.9991Z' />
      <path d='M28.5794 36.9991H27.9578V37.6628H28.5794V36.9991Z' />
      <path d='M28.8445 36.9991V37.6628H29.4661C29.4661 37.4729 29.5549 37.1879 29.5549 36.9991H28.8445Z' />
      <path d='M7.63512 37.8514H7.19234C7.18043 37.851 7.16857 37.8532 7.1575 37.8579C7.14643 37.8625 7.13639 37.8695 7.12796 37.8784C7.11954 37.8873 7.11292 37.8979 7.10852 37.9096C7.10411 37.9213 7.102 37.9338 7.10233 37.9464C7.10233 38.1363 7.19234 38.3263 7.19234 38.5151H7.72392L7.63512 37.8514Z' />
      <path d='M8.43871 37.8516H7.81714V38.5153H8.43871V37.8516Z' />
      <path d='M9.32424 37.8516H8.70386V38.5153H9.32424V37.8516Z' />
      <path d='M10.2082 37.8516H9.58667V38.5153H10.2082V37.8516Z' />
      <path d='M11.0989 37.8516H10.4773V38.5153H11.0989V37.8516Z' />
      <path d='M11.9856 37.8516H11.364V38.5153H11.9856V37.8516Z' />
      <path d='M12.8723 37.8516H12.2507V38.5153H12.8723V37.8516Z' />
      <path d='M13.6719 37.8516H13.0515V38.5153H13.6719V37.8516Z' />
      <path d='M14.5598 37.8516H13.9382V38.5153H14.5598V37.8516Z' />
      <path d='M14.825 38.6103H15.4465V38.0416C15.4465 37.9466 15.3577 37.9466 15.3577 37.8516H14.825V38.6103Z' />
      <path d='M15.892 38.6102L15.7156 38.4202V38.6102H15.892Z' />
      <path d='M21.5684 38.6102V37.9465H21.2084C21.1223 38.1864 21.0008 38.4104 20.8484 38.6102H21.5684Z' />
      <path d='M22.4544 37.8516H21.8328V38.5153H22.4544V37.8516Z' />
      <path d='M23.3438 37.8516H22.7234V38.5153H23.3438V37.8516Z' />
      <path d='M24.2278 37.8516H23.6062V38.5153H24.2278V37.8516Z' />
      <path d='M25.0247 37.8516H24.4031V38.5153H25.0247V37.8516Z' />
      <path d='M25.9141 37.8516H25.2937V38.5153H25.9141V37.8516Z' />
      <path d='M26.802 37.8516H26.1804V38.5153H26.802V37.8516Z' />
      <path d='M27.6887 37.8516H27.0671V38.5153H27.6887V37.8516Z' />
      <path d='M28.5794 37.8516H27.9578V38.5153H28.5794V37.8516Z' />
      <path d='M28.8445 38.6102H29.2873C29.3608 38.398 29.3915 38.1719 29.3773 37.9465H28.8445V38.6102Z' />
      <path d='M7.63882 38.7987H7.19604C7.28484 38.9887 7.28483 39.2737 7.37243 39.4624H7.73242L7.63882 38.7987Z' />
      <path d='M8.43871 38.7987H7.81714V39.4624H8.43871V38.7987Z' />
      <path d='M9.32424 38.7987H8.70386V39.4624H9.32424V38.7987Z' />
      <path d='M10.2082 38.7987H9.58667V39.4624H10.2082V38.7987Z' />
      <path d='M11.0989 38.7987H10.4773V39.4624H11.0989V38.7987Z' />
      <path d='M11.9856 38.7987H11.364V39.4624H11.9856V38.7987Z' />
      <path d='M12.8723 38.7987H12.2507V39.4624H12.8723V38.7987Z' />
      <path d='M13.6719 38.7987H13.0515V39.4624H13.6719V38.7987Z' />
      <path d='M14.5598 38.7987H13.9382V39.4624H14.5598V38.7987Z' />
      <path d='M15.4465 38.7987H14.825V39.4624H15.4465V38.7987Z' />
      <path d='M15.7165 39.4641H16.3369V38.9891L16.0716 38.7041H15.7117V39.4641H15.7165Z' />
      <path d='M17.1351 39.4638L16.6023 39.1788V39.4638H17.1351Z' />
      <path d='M20.6797 39.4649V38.7987C20.4954 38.9874 20.2865 39.1474 20.0593 39.2737V39.4637L20.6797 39.4649Z' />
      <path d='M21.5676 38.7987H20.946V39.4624H21.5676V38.7987Z' />
      <path d='M22.4544 38.7987H21.8328V39.4624H22.4544V38.7987Z' />
      <path d='M23.3438 38.7987H22.7234V39.4624H23.3438V38.7987Z' />
      <path d='M24.2278 38.7987H23.6062V39.4624H24.2278V38.7987Z' />
      <path d='M25.0247 38.7987H24.4031V39.4624H25.0247V38.7987Z' />
      <path d='M25.9141 38.7987H25.2937V39.4624H25.9141V38.7987Z' />
      <path d='M26.802 38.7987H26.1804V39.4624H26.802V38.7987Z' />
      <path d='M27.6887 38.7987H27.0671V39.4624H27.6887V38.7987Z' />
      <path d='M28.5794 38.7987H27.9578V39.4624H28.5794V38.7987Z' />
      <path d='M28.8445 39.465H29.1109C29.1997 39.275 29.1997 38.99 29.2873 38.8013H28.8445V39.465Z' />
      <path d='M7.63822 39.6527H7.37183C7.46062 39.8427 7.46062 40.1277 7.54942 40.3164H7.63822V39.6527Z' />
      <path d='M8.43871 39.6527H7.81714V40.3164H8.43871V39.6527Z' />
      <path d='M9.32424 39.6527H8.70386V40.3164H9.32424V39.6527Z' />
      <path d='M10.2082 39.6527H9.58667V40.3164H10.2082V39.6527Z' />
      <path d='M11.0989 39.6527H10.4773V40.3164H11.0989V39.6527Z' />
      <path d='M11.9856 39.6527H11.364V40.3164H11.9856V39.6527Z' />
      <path d='M12.8723 39.6527H12.2507V40.3164H12.8723V39.6527Z' />
      <path d='M13.6719 39.6527H13.0515V40.3164H13.6719V39.6527Z' />
      <path d='M14.5598 39.6527H13.9382V40.3164H14.5598V39.6527Z' />
      <path d='M15.4465 39.6527H14.825V40.3164H15.4465V39.6527Z' />
      <path d='M16.336 39.6527H15.7156V40.3164H16.336V39.6527Z' />
      <path d='M17.2239 39.6527H16.6023V40.3164H17.2239V39.6527Z' />
      <path d='M17.4851 40.3174H18.1055V39.6537H17.4851V40.3174Z' />
      <path d='M18.9924 40.3174V39.6537H18.282V40.3174H18.9924Z' />
      <path d='M19.7942 39.6527H19.1726V40.3164H19.7942V39.6527Z' />
      <path d='M20.6797 39.6527H20.0593V40.3164H20.6797V39.6527Z' />
      <path d='M21.5676 39.6527H20.946V40.3164H21.5676V39.6527Z' />
      <path d='M22.4544 39.6527H21.8328V40.3164H22.4544V39.6527Z' />
      <path d='M23.3438 39.6527H22.7234V40.3164H23.3438V39.6527Z' />
      <path d='M24.2278 39.6527H23.6062V40.3164H24.2278V39.6527Z' />
      <path d='M25.0247 39.6527H24.4031V40.3164H25.0247V39.6527Z' />
      <path d='M25.9141 39.6527H25.2937V40.3164H25.9141V39.6527Z' />
      <path d='M26.802 39.6527H26.1804V40.3164H26.802V39.6527Z' />
      <path d='M27.6887 39.6527H27.0671V40.3164H27.6887V39.6527Z' />
      <path d='M28.5794 39.6527H27.9578V40.3164H28.5794V39.6527Z' />
      <path d='M28.8445 40.3174C28.944 40.1105 29.0044 39.8851 29.0221 39.6537H28.8445V40.3174Z' />
      <path d='M8.43871 40.5065H7.81714V41.1702H8.43871V40.5065Z' />
      <path d='M9.32424 40.5065H8.70386V41.1702H9.32424V40.5065Z' />
      <path d='M10.2082 40.5065H9.58667V41.1702H10.2082V40.5065Z' />
      <path d='M11.0989 40.5065H10.4773V41.1702H11.0989V40.5065Z' />
      <path d='M11.9856 40.5065H11.364V41.1702H11.9856V40.5065Z' />
      <path d='M12.8723 40.5065H12.2507V41.1702H12.8723V40.5065Z' />
      <path d='M13.6719 40.5065H13.0515V41.1702H13.6719V40.5065Z' />
      <path d='M14.5598 40.5065H13.9382V41.1702H14.5598V40.5065Z' />
      <path d='M15.4465 40.5065H14.825V41.1702H15.4465V40.5065Z' />
      <path d='M16.336 40.5065H15.7156V41.1702H16.336V40.5065Z' />
      <path d='M17.2239 40.5065H16.6023V41.1702H17.2239V40.5065Z' />
      <path d='M18.1055 40.5065H17.4851V41.1702H18.1055V40.5065Z' />
      <path d='M18.9024 40.5065H18.282V41.1702H18.9024V40.5065Z' />
      <path d='M19.7942 40.5065H19.1726V41.1702H19.7942V40.5065Z' />
      <path d='M20.6797 40.5065H20.0593V41.1702H20.6797V40.5065Z' />
      <path d='M21.5676 40.5065H20.946V41.1702H21.5676V40.5065Z' />
      <path d='M22.4544 40.5065H21.8328V41.1702H22.4544V40.5065Z' />
      <path d='M23.3438 40.5065H22.7234V41.1702H23.3438V40.5065Z' />
      <path d='M24.2278 40.5065H23.6062V41.1702H24.2278V40.5065Z' />
      <path d='M25.0247 40.5065H24.4031V41.1702H25.0247V40.5065Z' />
      <path d='M25.9141 40.5065H25.2937V41.1702H25.9141V40.5065Z' />
      <path d='M26.802 40.5065H26.1804V41.1702H26.802V40.5065Z' />
      <path d='M27.6887 40.5065H27.0671V41.1702H27.6887V40.5065Z' />
      <path d='M27.9578 40.5062V41.1699H28.4894C28.4894 41.0749 28.5794 40.9799 28.5794 40.8849V40.5049L27.9578 40.5062Z' />
      <path d='M8.52737 41.4562H7.90698C7.99578 41.6462 8.08337 41.9312 8.17217 42.1199H8.53216L8.52737 41.4562Z' />
      <path d='M9.32424 41.4562H8.70386V42.1199H9.32424V41.4562Z' />
      <path d='M10.2082 41.4562H9.58667V42.1199H10.2082V41.4562Z' />
      <path d='M11.0989 41.4562H10.4773V42.1199H11.0989V41.4562Z' />
      <path d='M11.9856 41.4562H11.364V42.1199H11.9856V41.4562Z' />
      <path d='M12.8723 41.4562H12.2507V42.1199H12.8723V41.4562Z' />
      <path d='M13.6719 41.4562H13.0515V42.1199H13.6719V41.4562Z' />
      <path d='M14.5598 41.4562H13.9382V42.1199H14.5598V41.4562Z' />
      <path d='M15.4465 41.4562H14.825V42.1199H15.4465V41.4562Z' />
      <path d='M16.336 41.4562H15.7156V42.1199H16.336V41.4562Z' />
      <path d='M17.2239 41.4562H16.6023V42.1199H17.2239V41.4562Z' />
      <path d='M18.1055 41.4562H17.4851V42.1199H18.1055V41.4562Z' />
      <path d='M18.9024 41.4562H18.282V42.1199H18.9024V41.4562Z' />
      <path d='M19.7942 41.4562H19.1726V42.1199H19.7942V41.4562Z' />
      <path d='M20.6797 41.4562H20.0593V42.1199H20.6797V41.4562Z' />
      <path d='M21.5676 41.4562H20.946V42.1199H21.5676V41.4562Z' />
      <path d='M22.4544 41.4562H21.8328V42.1199H22.4544V41.4562Z' />
      <path d='M23.3438 41.4562H22.7234V42.1199H23.3438V41.4562Z' />
      <path d='M24.2278 41.4562H23.6062V42.1199H24.2278V41.4562Z' />
      <path d='M25.0247 41.4562H24.4031V42.1199H25.0247V41.4562Z' />
      <path d='M25.9141 41.4562H25.2937V42.1199H25.9141V41.4562Z' />
      <path d='M26.802 41.4562H26.1804V42.1199H26.802V41.4562Z' />
      <path d='M27.6887 41.4562H27.0671V42.1199H27.6887V41.4562Z' />
      <path d='M27.9578 42.1199C28.1342 41.9217 28.2833 41.6983 28.4006 41.4562H27.9578V42.1199Z' />
      <path d='M8.26245 42.3087C8.35125 42.4986 8.43886 42.5936 8.52766 42.7836V42.3087H8.26245Z' />
      <path d='M9.32424 42.3087H8.70386V42.9724H9.32424V42.3087Z' />
      <path d='M10.2082 42.3087H9.58667V42.9724H10.2082V42.3087Z' />
      <path d='M11.0989 42.3087H10.4773V42.9724H11.0989V42.3087Z' />
      <path d='M11.9856 42.3087H11.364V42.9724H11.9856V42.3087Z' />
      <path d='M12.8723 42.3087H12.2507V42.9724H12.8723V42.3087Z' />
      <path d='M13.6719 42.3087H13.0515V42.9724H13.6719V42.3087Z' />
      <path d='M14.5598 42.3087H13.9382V42.9724H14.5598V42.3087Z' />
      <path d='M15.4465 42.3087H14.825V42.9724H15.4465V42.3087Z' />
      <path d='M16.336 42.3087H15.7156V42.9724H16.336V42.3087Z' />
      <path d='M17.2239 42.3087H16.6023V42.9724H17.2239V42.3087Z' />
      <path d='M18.1055 42.3087H17.4851V42.9724H18.1055V42.3087Z' />
      <path d='M18.9024 42.3087H18.282V42.9724H18.9024V42.3087Z' />
      <path d='M19.7942 42.3087H19.1726V42.9724H19.7942V42.3087Z' />
      <path d='M20.6797 42.3087H20.0593V42.9724H20.6797V42.3087Z' />
      <path d='M21.5676 42.3087H20.946V42.9724H21.5676V42.3087Z' />
      <path d='M22.4544 42.3087H21.8328V42.9724H22.4544V42.3087Z' />
      <path d='M23.3438 42.3087H22.7234V42.9724H23.3438V42.3087Z' />
      <path d='M24.2278 42.3087H23.6062V42.9724H24.2278V42.3087Z' />
      <path d='M25.0247 42.3087H24.4031V42.9724H25.0247V42.3087Z' />
      <path d='M25.9141 42.3087H25.2937V42.9724H25.9141V42.3087Z' />
      <path d='M26.802 42.3087H26.1804V42.9724H26.802V42.3087Z' />
      <path d='M27.0671 42.3086V42.9723H27.5999C27.6887 42.8773 27.6887 42.7824 27.7763 42.7824V42.3074L27.0671 42.3086Z' />
      <path d='M9.32424 43.1627H8.70386C8.88025 43.3527 8.96904 43.6376 9.14663 43.8264H9.32303L9.32424 43.1627Z' />
      <path d='M10.2082 43.1627H9.58667V43.8302H10.2082V43.1627Z' />
      <path d='M11.0989 43.1627H10.4773V43.8302H11.0989V43.1627Z' />
      <path d='M11.9856 43.1627H11.364V43.8302H11.9856V43.1627Z' />
      <path d='M12.8723 43.1627H12.2507V43.8302H12.8723V43.1627Z' />
      <path d='M13.6719 43.1627H13.0515V43.8302H13.6719V43.1627Z' />
      <path d='M14.5598 43.1627H13.9382V43.8302H14.5598V43.1627Z' />
      <path d='M15.4465 43.1627H14.825V43.8302H15.4465V43.1627Z' />
      <path d='M16.336 43.1627H15.7156V43.8302H16.336V43.1627Z' />
      <path d='M17.2239 43.1627H16.6023V43.8302H17.2239V43.1627Z' />
      <path d='M18.1055 43.1627H17.4851V43.8302H18.1055V43.1627Z' />
      <path d='M18.9024 43.1627H18.282V43.8302H18.9024V43.1627Z' />
      <path d='M19.7942 43.1627H19.1726V43.8302H19.7942V43.1627Z' />
      <path d='M20.6797 43.1627H20.0593V43.8302H20.6797V43.1627Z' />
      <path d='M21.5676 43.1627H20.946V43.8302H21.5676V43.1627Z' />
      <path d='M22.4544 43.1627H21.8328V43.8302H22.4544V43.1627Z' />
      <path d='M23.3438 43.1627H22.7234V43.8302H23.3438V43.1627Z' />
      <path d='M24.2278 43.1627H23.6062V43.8302H24.2278V43.1627Z' />
      <path d='M25.0247 43.1627H24.4031V43.8302H25.0247V43.1627Z' />
      <path d='M25.9141 43.1627H25.2937V43.8302H25.9141V43.1627Z' />
      <path d='M26.802 43.1627H26.1804V43.8302H26.802V43.1627Z' />
      <path d='M27.0671 43.7313C27.1647 43.5553 27.2859 43.3953 27.4271 43.2563H27.0671V43.7313Z' />
      <path d='M10.2082 44.0162H9.58667V44.3962L9.85306 44.6811H10.2131L10.2082 44.0162Z' />
      <path d='M11.0989 44.0162H10.4773V44.6799H11.0989V44.0162Z' />
      <path d='M11.9856 44.0162H11.364V44.6799H11.9856V44.0162Z' />
      <path d='M12.8723 44.0162H12.2507V44.6799H12.8723V44.0162Z' />
      <path d='M13.6719 44.0162H13.0515V44.6799H13.6719V44.0162Z' />
      <path d='M14.5598 44.0162H13.9382V44.6799H14.5598V44.0162Z' />
      <path d='M15.4465 44.0162H14.825V44.6799H15.4465V44.0162Z' />
      <path d='M16.336 44.0162H15.7156V44.6799H16.336V44.0162Z' />
      <path d='M17.2239 44.0162H16.6023V44.6799H17.2239V44.0162Z' />
      <path d='M18.1055 44.0162H17.4851V44.6799H18.1055V44.0162Z' />
      <path d='M18.9024 44.0162H18.282V44.6799H18.9024V44.0162Z' />
      <path d='M19.7942 44.0162H19.1726V44.6799H19.7942V44.0162Z' />
      <path d='M20.6797 44.0162H20.0593V44.6799H20.6797V44.0162Z' />
      <path d='M21.5676 44.0162H20.946V44.6799H21.5676V44.0162Z' />
      <path d='M22.4544 44.0162H21.8328V44.6799H22.4544V44.0162Z' />
      <path d='M23.3438 44.0162H22.7234V44.6799H23.3438V44.0162Z' />
      <path d='M24.2278 44.0162H23.6062V44.6799H24.2278V44.0162Z' />
      <path d='M25.0247 44.0162H24.4031V44.6799H25.0247V44.0162Z' />
      <path d='M25.9141 44.0162H25.2937V44.6799H25.9141V44.0162Z' />
      <path d='M26.1804 44.6799L26.802 44.0162H26.1804V44.6799Z' />
      <path d='M10.032 44.9635L10.2096 45.1535V44.9635H10.032Z' />
      <path d='M11.0989 44.9635H10.4773V45.4397L10.7437 45.7247H11.1037L11.0989 44.9635Z' />
      <path d='M11.9856 44.9635H11.364V45.6272H11.9856V44.9635Z' />
      <path d='M12.8723 44.9635H12.2507V45.6272H12.8723V44.9635Z' />
      <path d='M13.6719 44.9635H13.0515V45.6272H13.6719V44.9635Z' />
      <path d='M14.5598 44.9635H13.9382V45.6272H14.5598V44.9635Z' />
      <path d='M15.4465 44.9635H14.825V45.6272H15.4465V44.9635Z' />
      <path d='M16.336 44.9635H15.7156V45.6272H16.336V44.9635Z' />
      <path d='M17.2239 44.9635H16.6023V45.6272H17.2239V44.9635Z' />
      <path d='M18.1055 44.9635H17.4851V45.6272H18.1055V44.9635Z' />
      <path d='M18.9024 44.9635H18.282V45.6272H18.9024V44.9635Z' />
      <path d='M19.7942 44.9635H19.1726V45.6272H19.7942V44.9635Z' />
      <path d='M20.6797 44.9635H20.0593V45.6272H20.6797V44.9635Z' />
      <path d='M21.5676 44.9635H20.946V45.6272H21.5676V44.9635Z' />
      <path d='M22.4544 44.9635H21.8328V45.6272H22.4544V44.9635Z' />
      <path d='M23.3438 44.9635H22.7234V45.6272H23.3438V44.9635Z' />
      <path d='M24.2278 44.9635H23.6062V45.6272H24.2278V44.9635Z' />
      <path d='M25.0247 44.9635H24.4031V45.6272H25.0247V44.9635Z' />
      <path d='M25.2937 45.5322C25.519 45.3664 25.727 45.1758 25.9141 44.9635H25.2937V45.5322Z' />
      <path d='M11.0085 45.8173L11.0961 45.9097V45.8173H11.0085Z' />
      <path d='M11.364 45.8173V46.1023C11.5503 46.2637 11.7602 46.3921 11.9856 46.4822V45.8185L11.364 45.8173Z' />
      <path d='M12.8723 45.8173H12.2507V46.481H12.8723V45.8173Z' />
      <path d='M13.6719 45.8173H13.0515V46.481H13.6719V45.8173Z' />
      <path d='M14.5598 45.8173H13.9382V46.481H14.5598V45.8173Z' />
      <path d='M15.4465 45.8173H14.825V46.481H15.4465V45.8173Z' />
      <path d='M16.336 45.8173H15.7156V46.481H16.336V45.8173Z' />
      <path d='M17.2239 45.8173H16.6023V46.481H17.2239V45.8173Z' />
      <path d='M18.1055 45.8173H17.4851V46.481H18.1055V45.8173Z' />
      <path d='M18.9024 45.8173H18.282V46.481H18.9024V45.8173Z' />
      <path d='M19.7942 45.8173H19.1726V46.481H19.7942V45.8173Z' />
      <path d='M20.6797 45.8173H20.0593V46.481H20.6797V45.8173Z' />
      <path d='M21.5676 45.8173H20.946V46.481H21.5676V45.8173Z' />
      <path d='M22.4544 45.8173H21.8328V46.481H22.4544V45.8173Z' />
      <path d='M23.3438 45.8173H22.7234V46.481H23.3438V45.8173Z' />
      <path d='M23.6062 45.8176V46.4813H23.8726C24.049 46.3863 24.139 46.2913 24.3154 46.2913V45.8163L23.6062 45.8176Z' />
      <path d='M24.4031 46.1023C24.5807 46.0073 24.6695 45.9123 24.8459 45.8173H24.4031V46.1023Z' />
      <path d='M12.3406 46.6644L12.8722 46.9494V46.6644H12.3406Z' />
      <path d='M13.0515 46.6644V47.0444C13.2279 47.1393 13.4955 47.2343 13.6719 47.3293V46.7606L13.0515 46.6644Z' />
      <path d='M14.5598 46.6644H13.9382V47.3281H14.5598V46.6644Z' />
      <path d='M15.4465 46.6644H14.825V47.3281H15.4465V46.6644Z' />
      <path d='M16.336 46.6644H15.7156V47.3281H16.336V46.6644Z' />
      <path d='M17.2239 46.6644H16.6023V47.3281H17.2239V46.6644Z' />
      <path d='M18.1055 46.6644H17.4851V47.3281H18.1055V46.6644Z' />
      <path d='M18.9024 46.6644H18.282V47.3281H18.9024V46.6644Z' />
      <path d='M19.7942 46.6644H19.1726V47.3281H19.7942V46.6644Z' />
      <path d='M20.6797 46.6644H20.0593V47.3281H20.6797V46.6644Z' />
      <path d='M21.5676 46.6644H20.946V47.3281H21.5676V46.6644Z' />
      <path d='M21.8328 46.6644V47.3281C22.0259 47.2217 22.2372 47.1571 22.4544 47.1381V46.7581L21.8328 46.6644Z' />
      <path d='M22.7234 46.6644V46.9494C22.8998 46.8544 23.1662 46.7594 23.3438 46.6644H22.7234Z' />
      <path d='M14.825 47.6197C15.0025 47.7147 15.2701 47.7147 15.4465 47.8097V47.6197H14.825Z' />
      <path d='M15.7156 47.6197V47.8097C15.892 47.8097 16.1584 47.9047 16.336 47.9047V47.6197H15.7156Z' />
      <path d='M16.6023 47.6197V47.9047H17.2239V47.6197H16.6023Z' />
      <path d='M17.4851 47.6197V47.9997H18.1055V47.6197H17.4851Z' />
      <path d='M18.282 47.6197V47.9047H18.9024V47.6197H18.282Z' />
      <path d='M19.1726 47.6197V47.9047C19.3838 47.9198 19.5957 47.8874 19.7942 47.8097V47.6197H19.1726Z' />
      <path d='M20.0593 47.6197V47.8097C20.2357 47.8097 20.5021 47.7147 20.6797 47.7147H20.0593V47.6197Z' />
    </svg>
  );
};

export default Logo;
