import { css } from '@emotion/react';

export const maxPlatformLoanDialogCx = css`
  .cannot-do-transaction-image {
    margin-bottom: 1rem;
    border-radius: 0.5rem;
  }

  p {
    color: #404040;
    text-align: center;
  }
`;
