import { css } from '@emotion/react';

export const navbarCx = css`
  background: #1ea7e9;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 3.5rem;
  z-index: 500;
  padding: 0 1rem;

  display: flex;
  align-items: center;
`;
