import { css } from '@emotion/react';

export const otherPhoneLabelCx = css`
  margin-left: 0.25rem;
  padding: 0 0.25rem 0 0.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 6.25rem;
  background-color: #ededed;

  span {
    font-size: 0.625rem;
  }
`;
