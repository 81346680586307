const BannerIllustration = (
  props: Omit<React.SVGProps<SVGSVGElement>, 'fill'>,
) => {
  return (
    <svg
      width={296}
      height={101}
      viewBox='0 0 296 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_267_17128)'>
        <path
          d='M0 6.62247C0 2.96471 2.9652 -0.000488281 6.62295 -0.000488281H289.377C293.035 -0.000488281 296 2.96471 296 6.62246V93.6877C296 97.3454 293.035 100.311 289.377 100.311H6.62296C2.96521 100.311 0 97.3454 0 93.6877V6.62247Z'
          fill='#00ACF0'
        />
        <path
          d='M296 48.7132V173.294C296 187.565 284.237 199.138 269.733 199.138H26.7279C12.2233 199.138 0.460449 187.565 0.460449 173.294V24.7722H29.6225C29.7816 24.7722 29.9088 24.9036 29.9088 25.0538V47.7118C29.9088 47.8683 30.0297 47.9872 30.1951 47.9872H38.4844C38.6434 47.9872 38.7707 47.8683 38.7707 47.7118V25.1352C38.7707 25.0163 38.847 24.9099 38.9615 24.8661L55.1584 19.3518C55.3493 19.2892 55.5338 19.4269 55.5338 19.621V51.9367C55.5338 52.0932 55.6674 52.2183 55.82 52.2183H59.6053C59.7643 52.2183 59.8915 52.0869 59.8915 51.9367V36.6394C59.8915 36.4767 60.0124 36.3578 60.1715 36.3578H83.9452C84.1042 36.3578 84.2314 36.4767 84.2314 36.6394V43.055C84.2314 43.2115 84.365 43.3367 84.5177 43.3367H87.0242C87.1833 43.3367 87.3105 43.4681 87.3105 43.6183V78.9009C88.7037 78.9072 90.1287 78.9072 91.5601 78.9072C133.503 78.9072 185.739 71.7843 223.699 74.2817V65.0432L232.968 55.9237V58.709H235.494V40.7141H246.309V66.0134H250.437L255.743 60.7871V50.6974H257.804V24.6971H262.957V12.5356H265.273V19.3268H270.47V29.6668H274.033V63.2782H277.227V71.3774H289.091V50.3469H291.77V48.7258H296V48.7132Z'
          fill='#008AC0'
        />
        <path
          d='M79.4552 107.832L220.534 109.773L291.019 -0.637207H12.1409L79.4552 107.832Z'
          fill='url(#paint0_linear_267_17128)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.0965 81.3242L30.7939 87.0187C29.2361 93.8398 39.0497 101.902 52.6919 105.018C66.334 108.133 78.6648 105.123 80.2227 98.3022L81.5253 92.6076L32.0965 81.333'
          fill='#FFC739'
        />
        <path
          d='M30.9786 90.6624L33.0558 81.5441L32.5629 81.4297L30.7146 89.527C30.7762 89.9055 30.8554 90.2839 30.9786 90.6624Z'
          fill='#F09537'
        />
        <path
          d='M31.8674 92.6868L34.3406 81.8346L33.8478 81.7202L31.5066 91.9915C31.621 92.2203 31.7354 92.4492 31.8674 92.678'
          fill='#F09537'
        />
        <path
          d='M33.3284 94.7551L36.1801 82.2571L35.4848 82.0986L32.7563 94.0421C32.9324 94.2798 33.126 94.5174 33.3196 94.7551'
          fill='#F09537'
        />
        <path
          d='M35.2737 96.7445L38.4598 82.7766L37.6237 82.583L34.552 96.0668C34.7896 96.2956 35.0273 96.5244 35.2825 96.7533'
          fill='#F09537'
        />
        <path
          d='M41.3905 83.4457L40.4048 83.2169L42.4819 74.0986L43.4677 74.3275L41.3905 83.4457Z'
          fill='#F09537'
        />
        <path
          d='M37.8875 98.7953L41.3904 83.4456L40.4047 83.2168L36.9985 98.144C37.289 98.364 37.5882 98.5753 37.8875 98.7865'
          fill='#F09537'
        />
        <path
          d='M44.4095 84.1323L43.3181 83.8858L45.3953 74.7676L46.4866 75.014L44.4095 84.1323Z'
          fill='#F09537'
        />
        <path
          d='M40.6777 100.521L44.4183 84.1327L43.3269 83.8862L39.6655 99.9312C39.9912 100.125 40.3344 100.327 40.6777 100.512'
          fill='#F09537'
        />
        <path
          d='M47.5341 84.8452L46.3459 84.5724L48.4231 75.4541L49.6113 75.7269L47.5341 84.8452Z'
          fill='#F09537'
        />
        <path
          d='M43.6263 101.999L47.543 84.8451L46.3548 84.5723L42.4998 101.462C42.8694 101.638 43.2479 101.823 43.6263 101.99'
          fill='#F09537'
        />
        <path
          d='M50.7555 85.584L49.4705 85.2936L51.5476 76.1753L52.8326 76.4657L50.7555 85.584Z'
          fill='#F09537'
        />
        <path
          d='M46.7244 103.258L50.7555 85.5844L49.4704 85.2939L45.4746 102.791C45.8795 102.95 46.3019 103.108 46.7156 103.266'
          fill='#F09537'
        />
        <path
          d='M54.0823 86.3409L52.7004 86.024L54.7776 76.9058L56.1594 77.2226L54.0823 86.3409Z'
          fill='#F09537'
        />
        <path
          d='M49.9809 104.314L54.0824 86.3413L52.7006 86.0244L48.6167 103.9C49.0656 104.041 49.5232 104.182 49.9809 104.314Z'
          fill='#F09537'
        />
        <path
          d='M57.4972 87.1246L56.0186 86.7901L58.0957 77.6719L59.5743 78.0063L57.4972 87.1246Z'
          fill='#F09537'
        />
        <path
          d='M53.3783 105.167L57.4974 87.1245L56.0187 86.79L51.8997 104.833C52.1637 104.895 52.4277 104.956 52.6918 105.018C52.9206 105.071 53.1495 105.123 53.3871 105.167'
          fill='#F09537'
        />
        <path
          d='M60.8154 87.8819L59.4336 87.565L61.5107 78.4468L62.8926 78.7636L60.8154 87.8819Z'
          fill='#F09537'
        />
        <path
          d='M56.7405 105.766L60.8243 87.8818L59.4425 87.5649L55.3411 105.546C55.8163 105.625 56.2828 105.705 56.7493 105.766'
          fill='#F09537'
        />
        <path
          d='M64.0455 88.6118L62.7605 88.3214L64.8376 79.2031L66.1226 79.4936L64.0455 88.6118Z'
          fill='#F09537'
        />
        <path
          d='M60.0497 106.127L64.0456 88.6122L62.7606 88.3218L58.7207 106.013C59.1696 106.057 59.6096 106.101 60.0497 106.127Z'
          fill='#F09537'
        />
        <path
          d='M67.1613 89.3247L65.9731 89.0519L68.0503 79.9336L69.2385 80.2064L67.1613 89.3247Z'
          fill='#F09537'
        />
        <path
          d='M63.306 106.241L67.161 89.3251L65.9728 89.0522L62.0562 106.224C62.4786 106.233 62.8923 106.241 63.2972 106.241'
          fill='#F09537'
        />
        <path
          d='M70.189 90.0117L69.0977 89.7652L71.1748 80.647L72.2662 80.8934L70.189 90.0117Z'
          fill='#F09537'
        />
        <path
          d='M66.519 106.101L70.1892 90.0204L69.0978 89.7739L65.3484 106.189C65.7445 106.171 66.1317 106.136 66.5102 106.109'
          fill='#F09537'
        />
        <path
          d='M73.111 90.6806L72.1252 90.4518L74.2024 81.3335L75.1881 81.5535L73.111 90.6718'
          fill='#F09537'
        />
        <path
          d='M69.6961 105.66L73.1111 90.68L72.1253 90.4512L68.6135 105.836C68.9832 105.783 69.344 105.713 69.6961 105.651'
          fill='#F09537'
        />
        <path
          d='M72.8028 104.859L75.8921 91.3137L75.056 91.1201L71.8523 105.15C72.1779 105.062 72.4948 104.956 72.8028 104.859Z'
          fill='#F09537'
        />
        <path
          d='M75.2848 103.83L78.0309 91.7986L77.3444 91.6401L74.4751 104.217C74.7567 104.094 75.0296 103.962 75.2936 103.83'
          fill='#F09537'
        />
        <path
          d='M77.362 102.527L79.7207 92.1945L79.2279 92.0801L76.7371 102.976C76.9571 102.827 77.1595 102.677 77.362 102.519'
          fill='#F09537'
        />
        <path
          d='M79.052 100.811L80.9531 92.467L80.4603 92.3525L78.3391 101.638C78.6032 101.374 78.832 101.092 79.052 100.802'
          fill='#F09537'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.0963 81.3245C33.6541 74.5034 45.9849 71.4933 59.6271 74.609C73.2693 77.7247 83.0741 85.7868 81.5163 92.6079C79.9584 99.429 67.6276 102.439 53.9854 99.3234C40.3432 96.2077 30.5384 88.1456 32.0963 81.3245Z'
          fill='#F9E469'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M32.0963 81.3245C33.6541 74.5034 45.9849 71.4933 59.6271 74.609C73.2693 77.7247 83.0741 85.7868 81.525 92.6079C79.9672 99.429 67.6364 102.439 53.9942 99.3234C40.352 96.2077 30.5472 88.1456 32.1051 81.3333'
          fill='#F9E469'
        />
        <path
          d='M81.0496 93.9634L58.694 74.4067C57.4178 74.1426 56.1504 73.9226 54.9006 73.7642L79.9231 95.6533C80.3895 95.1252 80.768 94.5619 81.0496 93.9634Z'
          fill='#F9F3D6'
        />
        <path
          d='M77.001 97.9153L48.9597 73.377C46.9441 73.3858 45.0254 73.5442 43.2651 73.8434L72.6883 99.5876C74.3165 99.1651 75.7688 98.6018 77.0098 97.9153'
          fill='#F9F3D6'
        />
        <path
          d='M71.0247 99.9566L41.5664 74.186C40.4486 74.4501 39.41 74.7669 38.4507 75.1542L67.3545 100.441C68.6395 100.344 69.8629 100.185 71.0247 99.9566Z'
          fill='#F9F3D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37.0338 82.4512C35.9424 87.2216 43.9165 93.1185 54.8302 95.6094C65.744 98.1002 75.4872 96.243 76.5697 91.4727C77.6611 86.7023 69.687 80.8054 58.7733 78.3146C47.8595 75.8238 38.1163 77.6809 37.0338 82.4512Z'
          fill='#F09537'
        />
        <mask
          id='mask0_267_17128'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={36}
          y={77}
          width={41}
          height={20}
        >
          <path
            d='M37.0338 82.4512C35.9424 87.2216 43.9165 93.1185 54.8302 95.6094C65.744 98.1002 75.4872 96.243 76.5697 91.4727C77.6611 86.7023 69.687 80.8054 58.7733 78.3146C47.8595 75.8238 38.1163 77.6809 37.0338 82.4512Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_267_17128)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M37.0253 83.4102C38.1167 78.6134 47.8687 76.7387 58.7824 79.2207C69.6962 81.7115 77.6615 87.6261 76.5701 92.4317C75.4787 97.2284 65.7267 99.1031 54.813 96.6211C43.8992 94.1303 35.9339 88.2158 37.0253 83.4102Z'
            fill='#FFDC41'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37.2188 83.129C36.1803 87.6881 43.7495 93.3122 54.1088 95.671C58.8879 96.7624 64.0632 97.1056 67.6805 96.5687C61.942 90.1789 53.431 83.9475 43.9431 78.5698C40.2641 79.3003 37.7293 80.8758 37.2188 83.129Z'
          fill='#FFC739'
        />
        <path
          opacity={0.1}
          d='M71.1539 50.1125C75.0932 48.9724 79.2439 48.8827 83.0872 49.8691C86.924 50.8556 90.274 52.8669 92.7209 55.6468C95.1613 58.4267 96.5833 61.8536 96.8075 65.4983C97.0317 69.1366 96.0453 72.8261 93.9699 76.0992C91.901 79.3724 88.8328 82.0819 85.1689 83.8818C81.4986 85.6817 77.3928 86.4952 73.3638 86.2198C69.3348 85.9443 65.5684 84.5864 62.5387 82.3253C59.5089 80.0642 57.3503 76.996 56.3446 73.5115C54.9867 68.8419 55.7874 63.7496 58.5673 59.3619C61.3472 54.9742 65.8759 51.6498 71.1603 50.1125H71.1539Z'
          fill='url(#paint1_linear_267_17128)'
        />
        <path
          d='M66.5546 83.1257C66.3112 83.1962 66.055 83.2218 65.8052 83.209C65.5554 83.1898 65.312 83.1321 65.0942 83.0232C64.8764 82.9207 64.6843 82.7798 64.5305 82.6069C64.3768 82.4339 64.2679 82.2354 64.2039 82.0176L59.893 67.157C59.4511 64.723 60.0596 65.9784 80.9476 53.8402C81.1654 53.7121 81.4088 53.6288 81.665 53.584C81.9148 53.5392 82.171 53.5456 82.4144 53.584C82.6578 53.6288 82.8884 53.7185 83.087 53.8466C83.2855 53.9747 83.4521 54.1413 83.5802 54.3334L85.8541 57.8308L87.4234 57.376C87.6668 57.3055 87.923 57.2799 88.1729 57.2927C88.4227 57.3119 88.6661 57.3696 88.8839 57.4721C89.1016 57.5746 89.2938 57.7155 89.4475 57.8884C89.6013 58.0614 89.7102 58.2599 89.7742 58.4777L94.085 73.3383C94.2132 73.7738 94.1363 74.2542 93.8737 74.6642C93.611 75.0741 93.1883 75.388 92.6951 75.5289L66.5546 83.1129V83.1257Z'
          fill='url(#paint2_linear_267_17128)'
        />
        <path
          d='M86.4886 69.7576C86.9242 69.8665 87.4046 69.8345 87.8337 69.6551C88.2629 69.4758 88.6216 69.1683 88.8394 68.7904C89.0572 68.4125 89.1148 67.9833 89.0123 67.5798C88.9098 67.1827 88.6472 66.8368 88.2693 66.6126C87.8978 66.3884 87.4366 66.2987 86.9754 66.3564C86.5142 66.414 86.0787 66.6254 85.7584 66.9393C85.4317 67.2531 85.2395 67.6567 85.2075 68.073C85.1755 68.4894 85.31 68.8929 85.5918 69.2132C85.8224 69.4758 86.1363 69.6616 86.495 69.7576H86.4886Z'
          fill='white'
        />
        <path
          d='M86.0465 67.8745L92.0036 66.145L92.3687 67.3941L86.4116 69.1235C86.2259 69.1684 86.0337 69.1491 85.8736 69.0723C85.7135 68.9954 85.5982 68.8545 85.5469 68.6944C85.4957 68.5342 85.5277 68.3549 85.6174 68.2011C85.7135 68.0474 85.8608 67.9321 86.0465 67.8681V67.8745Z'
          fill='white'
        />
        <path
          d='M79.3335 71.8652C79.4103 71.8396 79.4872 71.8332 79.564 71.8396C79.6409 71.846 79.7114 71.8716 79.7754 71.9164C79.9227 72.0125 80.0188 72.1534 80.0573 72.32C80.0893 72.4673 80.0701 72.621 80.0124 72.762C79.9548 72.9093 79.8523 73.0374 79.7178 73.1335C79.4167 73.4025 79.0836 73.6395 78.7249 73.8445C78.3662 74.0494 77.9819 74.216 77.5848 74.3313C77.098 74.4978 76.5919 74.5747 76.0859 74.5619C75.5799 74.5491 75.0931 74.4402 74.6447 74.248C74.2155 74.0366 73.844 73.742 73.5494 73.3833C73.2611 73.0246 73.0562 72.6146 72.9601 72.1727C72.7743 71.5898 72.7551 70.9684 72.9024 70.3535C73.0369 69.7962 73.3444 69.2774 73.7864 68.8547C74.2476 68.4255 74.8176 68.1052 75.4454 67.9323C76.0219 67.7529 76.6304 67.7145 77.2069 67.8234C77.7513 67.9323 78.2381 68.1821 78.6096 68.5472C79.0196 68.9636 79.3142 69.4696 79.4552 70.0204C79.564 70.3856 79.4295 70.6225 79.0708 70.725L74.8305 71.9549C74.9778 72.4289 75.3045 72.826 75.7656 73.0822C75.9962 73.1719 76.2461 73.2231 76.5023 73.2231C76.7585 73.2231 77.0211 73.1783 77.2645 73.095C77.5399 73.0182 77.8026 72.9029 78.046 72.7555C78.2958 72.5954 78.5328 72.4225 78.7634 72.2431C78.9235 72.083 79.1221 71.9485 79.3399 71.8588L79.3335 71.8652ZM75.8169 69.0404C75.5991 69.0917 75.3941 69.1877 75.2084 69.3158C75.029 69.444 74.8753 69.6041 74.7664 69.7834C74.5422 70.1998 74.4782 70.6674 74.5806 71.1029L77.8282 70.1614C77.7193 69.7322 77.4439 69.3607 77.0531 69.1109C76.8738 69.0148 76.6688 68.9635 76.451 68.9507C76.2396 68.9379 76.0219 68.97 75.8169 69.0404Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M229.64 95.9829L231.254 103.068C233.188 111.55 220.99 121.581 204.026 125.449C187.061 129.321 171.716 125.573 169.783 117.091L168.169 110.006L229.646 95.9829'
          fill='#FFC739'
        />
        <path
          d='M231.027 107.596L228.438 96.2536L229.05 96.1128L231.346 106.187C231.276 106.653 231.168 107.124 231.021 107.596'
          fill='#F09537'
        />
        <path
          d='M229.916 110.114L226.84 96.6218L227.452 96.481L230.366 109.258C230.231 109.545 230.079 109.827 229.916 110.114Z'
          fill='#F09537'
        />
        <path
          d='M228.102 112.688L224.554 97.1423L225.415 96.9473L228.806 111.805C228.584 112.103 228.351 112.395 228.102 112.693'
          fill='#F09537'
        />
        <path
          d='M225.681 115.168L221.716 97.792L222.761 97.5537L226.585 114.318C226.293 114.605 225.995 114.886 225.681 115.168Z'
          fill='#F09537'
        />
        <path
          d='M218.076 98.6208L219.306 98.3392L216.717 86.9971L215.487 87.2787L218.076 98.6262'
          fill='#F09537'
        />
        <path
          d='M222.436 117.708L218.082 98.6205L219.311 98.3389L223.547 116.912C223.189 117.183 222.816 117.448 222.436 117.714'
          fill='#F09537'
        />
        <path
          d='M214.317 99.4765L215.671 99.1678L213.082 87.8257L211.728 88.1344L214.317 99.4765Z'
          fill='#F09537'
        />
        <path
          d='M218.964 119.859L214.317 99.4821L215.671 99.1733L220.226 119.133C219.815 119.377 219.398 119.621 218.97 119.859'
          fill='#F09537'
        />
        <path
          d='M210.433 100.365L211.907 100.029L209.318 88.687L207.844 89.0228L210.433 100.365Z'
          fill='#F09537'
        />
        <path
          d='M215.297 121.695L210.428 100.365L211.901 100.029L216.695 121.039C216.234 121.261 215.769 121.483 215.292 121.695'
          fill='#F09537'
        />
        <path
          d='M206.425 101.28L208.023 100.917L205.434 89.5752L203.836 89.9381L206.425 101.286'
          fill='#F09537'
        />
        <path
          d='M202.298 102.223L204.02 101.833L201.431 90.4907L199.709 90.8807L202.298 102.223Z'
          fill='#F09537'
        />
        <path
          d='M198.04 103.192L199.882 102.77L197.293 91.4277L195.451 91.8502L198.04 103.192Z'
          fill='#F09537'
        />
        <path
          d='M193.913 104.134L195.635 103.739L193.046 92.397L191.324 92.787L193.913 104.129'
          fill='#F09537'
        />
        <path
          d='M189.905 105.05L191.503 104.687L188.914 93.3452L187.316 93.7135L189.905 105.056'
          fill='#F09537'
        />
        <path
          d='M186.021 105.933L187.494 105.597L184.905 94.2549L183.432 94.5907L186.021 105.933Z'
          fill='#F09537'
        />
        <path
          d='M182.257 106.794L183.611 106.486L181.022 95.1436L179.668 95.4523L182.257 106.794Z'
          fill='#F09537'
        />
        <path
          d='M178.623 107.623L179.852 107.342L177.263 95.9995L176.033 96.2758L178.623 107.623Z'
          fill='#F09537'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M229.64 95.9824C227.706 87.5002 212.361 83.7574 195.397 87.6302C178.433 91.503 166.229 101.529 168.168 110.011C170.102 118.493 185.447 122.236 202.411 118.363C219.376 114.49 231.579 104.465 229.64 95.9824Z'
          fill='#F9E469'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M229.64 95.9831C227.706 87.5009 212.361 83.7581 195.397 87.6254C178.433 91.4982 166.229 101.524 168.168 110.006C170.102 118.489 185.447 122.231 202.411 118.364C219.376 114.491 231.574 104.465 229.64 95.9831Z'
          fill='#F9E469'
        />
        <path
          d='M168.753 111.701L196.562 87.3704C198.154 87.04 199.73 86.7746 201.279 86.5742L170.162 113.803C169.588 113.147 169.116 112.449 168.759 111.701'
          fill='#F9F3D6'
        />
        <path
          d='M173.791 116.62L208.673 86.0977C211.186 86.1085 213.564 86.3089 215.763 86.6772L179.169 118.694C177.149 118.169 175.34 117.47 173.791 116.62Z'
          fill='#F9F3D6'
        />
        <path
          d='M181.222 119.16L217.865 87.1001C219.251 87.4251 220.551 87.8259 221.737 88.3026L185.783 119.756C184.185 119.637 182.663 119.436 181.222 119.16Z'
          fill='#F9F3D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M223.492 97.3853C224.846 103.322 214.929 110.656 201.355 113.754C187.781 116.852 175.665 114.545 174.311 108.608C172.957 102.672 182.874 95.3379 196.448 92.2397C210.022 89.1414 222.138 91.4489 223.492 97.3853Z'
          fill='#F09537'
        />
        <mask
          id='mask1_267_17128'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={174}
          y={90}
          width={50}
          height={26}
        >
          <path
            d='M223.492 97.3853C224.846 103.322 214.929 110.656 201.355 113.754C187.781 116.852 175.665 114.545 174.311 108.608C172.957 102.672 182.874 95.3379 196.448 92.2397C210.022 89.1414 222.138 91.4489 223.492 97.3853Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask1_267_17128)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M223.509 98.5768C222.144 92.6079 210.022 90.2734 196.453 93.3716C182.88 96.4698 172.973 103.831 174.333 109.8C175.697 115.769 187.82 118.103 201.388 115.005C214.956 111.907 224.868 104.546 223.509 98.5768Z'
            fill='#FFDC41'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M223.27 98.2306C224.565 103.902 215.151 110.894 202.26 113.835C196.318 115.19 189.872 115.617 185.382 114.951C192.521 107.005 203.105 99.2489 214.902 92.5649C219.479 93.4749 222.626 95.4303 223.265 98.236L223.27 98.2306Z'
          fill='#FFC739'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.5724 34L59.0577 36.0497C62.0298 38.5094 60.4541 45.3311 55.5412 51.2818C50.6282 57.2324 44.2164 60.0636 41.2443 57.6103L38.759 55.5606L56.5724 34Z'
          fill='#FFC739'
        />
        <path
          d='M60.2043 37.7083L56.2266 34.4224L56.4059 34.2046L59.9417 37.1255C60.0442 37.3112 60.1339 37.5034 60.2107 37.7083'
          fill='#F09537'
        />
        <path
          d='M60.4926 38.8936L55.759 34.9863L55.9384 34.7686L60.4222 38.4709C60.4478 38.6118 60.4734 38.7527 60.4926 38.8936Z'
          fill='#F09537'
        />
        <path
          d='M60.5504 40.2899L55.0994 35.7869L55.3492 35.4858L60.5568 39.7903C60.5568 39.9568 60.5568 40.1233 60.5504 40.2899Z'
          fill='#F09537'
        />
        <path
          d='M60.3708 41.8143L54.2793 36.7797L54.5804 36.4146L60.4605 41.2762C60.4349 41.4556 60.4029 41.635 60.3708 41.8207'
          fill='#F09537'
        />
        <path
          d='M53.2226 38.0545L53.5813 37.6253L49.6036 34.3394L49.2449 34.7685L53.2226 38.0545Z'
          fill='#F09537'
        />
        <path
          d='M59.9163 43.5889L53.2227 38.0546L53.5814 37.6255L60.0957 43.006C60.0444 43.1982 59.9868 43.3903 59.9227 43.5889'
          fill='#F09537'
        />
        <path
          d='M52.1335 39.3742L52.5242 38.9002L48.5465 35.6143L48.1558 36.0883L52.1335 39.3742Z'
          fill='#F09537'
        />
        <path
          d='M59.2756 45.2797L52.1272 39.3739L52.5179 38.8999L59.519 44.684C59.4422 44.8825 59.3589 45.0811 59.2756 45.2797Z'
          fill='#F09537'
        />
        <path
          d='M51.0063 40.7384L51.4355 40.2196L47.4577 36.9336L47.0286 37.4524L51.0063 40.7384Z'
          fill='#F09537'
        />
        <path
          d='M58.4879 46.9193L51.0063 40.7381L51.4355 40.2192L58.8017 46.3044C58.6992 46.5093 58.5967 46.7079 58.4879 46.9129'
          fill='#F09537'
        />
        <path
          d='M49.8469 42.1411L50.3081 41.5838L46.3303 38.2979L45.8691 38.8551L49.8469 42.1411Z'
          fill='#F09537'
        />
        <path
          d='M57.5527 48.5141L49.8406 42.1408L50.3018 41.5835L57.937 47.8928C57.8153 48.1042 57.6872 48.3092 57.5527 48.5205'
          fill='#F09537'
        />
        <path
          d='M48.6491 43.5888L49.1488 42.9867L45.171 39.7007L44.6714 40.3028L48.6491 43.5888Z'
          fill='#F09537'
        />
        <path
          d='M56.4891 50.0707L48.6489 43.5884L49.1485 42.9863L56.9503 49.4302C56.803 49.6415 56.6493 49.8593 56.4955 50.0707'
          fill='#F09537'
        />
        <path
          d='M47.4128 45.0814L47.9445 44.4344L43.9667 41.1484L43.4351 41.7954L47.4128 45.0814Z'
          fill='#F09537'
        />
        <path
          d='M55.2851 51.5889L47.4128 45.0874L47.9445 44.4404L55.8167 50.9419C55.7206 51.0572 55.631 51.1725 55.5349 51.2878C55.4516 51.3903 55.3683 51.4864 55.2851 51.5889Z'
          fill='#F09537'
        />
        <path
          d='M46.2151 46.5287L46.7147 45.9266L42.7369 42.6406L42.2373 43.2427L46.2151 46.5287Z'
          fill='#F09537'
        />
        <path
          d='M54.0232 52.9791L46.2214 46.5289L46.7211 45.9268L54.5677 52.409C54.3883 52.6012 54.209 52.7934 54.0296 52.9791'
          fill='#F09537'
        />
        <path
          d='M45.0556 47.938L45.5168 47.3807L41.5391 44.0947L41.0779 44.6584L45.0556 47.9444'
          fill='#F09537'
        />
        <path
          d='M52.6973 54.2474L45.0557 47.9381L45.5169 47.3809L53.2354 53.7542C53.056 53.9208 52.8767 54.0873 52.6973 54.2474Z'
          fill='#F09537'
        />
        <path
          d='M43.9284 49.3024L44.3576 48.7835L40.3798 45.4976L39.9507 46.0164L43.9284 49.3024Z'
          fill='#F09537'
        />
        <path
          d='M51.3072 55.3936L43.9282 49.2957L44.3574 48.7769L51.8453 54.9645C51.6659 55.1118 51.4866 55.2527 51.3072 55.3872'
          fill='#F09537'
        />
        <path
          d='M42.8393 50.6154L43.2301 50.1414L39.2523 46.8555L38.8616 47.3295L42.8393 50.6154Z'
          fill='#F09537'
        />
        <path
          d='M49.8533 56.4125L42.8394 50.6156L43.2301 50.1416L50.3913 56.0602C50.212 56.1819 50.0326 56.3036 49.8533 56.4125Z'
          fill='#F09537'
        />
        <path
          d='M41.7888 51.8968L42.1475 51.4676L38.1697 48.1816L37.811 48.6108L41.7888 51.8968Z'
          fill='#F09537'
        />
        <path
          d='M48.3223 57.2899L41.7888 51.8901L42.1475 51.4609L48.8604 57.008C48.681 57.1041 48.5017 57.2002 48.3223 57.2899Z'
          fill='#F09537'
        />
        <path
          d='M46.6953 57.9877L40.7896 53.1068L41.0906 52.7417L47.2078 57.7956C47.0348 57.866 46.8619 57.9301 46.6953 57.9877Z'
          fill='#F09537'
        />
        <path
          d='M45.2607 58.3724L40.0146 54.0359L40.2645 53.7349L45.7475 58.2699C45.5809 58.3083 45.4208 58.3468 45.2607 58.3724Z'
          fill='#F09537'
        />
        <path
          d='M43.9154 58.5004L39.406 54.7788L39.5854 54.561L44.3382 58.4875C44.1908 58.4939 44.0499 58.5004 43.9154 58.5004Z'
          fill='#F09537'
        />
        <path
          d='M42.6024 58.321L38.9641 55.3169L39.1435 55.0991L43.1981 58.4491C42.9931 58.4171 42.7945 58.3787 42.6088 58.321'
          fill='#F09537'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.5724 34.0003C53.6003 31.5407 47.1885 34.3783 42.2756 40.3289C37.3626 46.2795 35.7805 53.1076 38.759 55.5609C41.7311 58.0206 48.1429 55.183 53.0559 49.2324C57.9688 43.2818 59.5509 36.46 56.5724 34.0003Z'
          fill='#F9E469'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M56.5724 34.0003C53.6003 31.5407 47.1885 34.3783 42.2756 40.3289C37.3626 46.2795 35.7805 53.1076 38.759 55.5609C41.7311 58.0206 48.1429 55.183 53.0559 49.2324C57.9688 43.2818 59.5509 36.46 56.5724 34.0003Z'
          fill='#F9E469'
        />
        <path
          d='M39.4189 55.9963L42.6152 39.9252C43.0828 39.3807 43.5696 38.8555 44.0565 38.3687L40.4822 56.355C40.1043 56.2781 39.7456 56.1629 39.4189 55.9963Z'
          fill='#F9F3D6'
        />
        <path
          d='M42.5191 56.3676L46.5225 36.2034C47.4064 35.5308 48.2968 34.9544 49.1679 34.4932L44.966 55.6438C44.114 56.0025 43.2877 56.2523 42.5127 56.3676'
          fill='#F9F3D6'
        />
        <path
          d='M45.8243 55.2469L50.0326 34.0643C50.6091 33.8016 51.1728 33.5903 51.7172 33.4365L47.5858 54.222C46.9901 54.6128 46.4008 54.9523 45.8179 55.2469'
          fill='#F9F3D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.7917 36.1588C56.8734 37.8818 55.3745 43.1407 51.4416 47.8999C47.5087 52.6591 42.6278 55.1252 40.5396 53.4085C38.4579 51.6855 39.9568 46.4266 43.8897 41.6674C47.8226 36.9082 52.7035 34.4421 54.7917 36.1588Z'
          fill='#F09537'
        />
        <mask
          id='mask2_267_17128'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={39}
          y={35}
          width={17}
          height={19}
        >
          <path
            d='M54.7917 36.1588C56.8734 37.8818 55.3745 43.1407 51.4416 47.8999C47.5087 52.6591 42.6278 55.1252 40.5396 53.4085C38.4579 51.6855 39.9568 46.4266 43.8897 41.6674C47.8226 36.9082 52.7035 34.4421 54.7917 36.1588Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask2_267_17128)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M55.1183 36.5685C53.0237 34.8391 48.13 37.2988 44.1971 42.058C40.2642 46.8172 38.7717 52.0888 40.8663 53.8183C42.9608 55.5477 47.8546 53.0881 51.7875 48.3288C55.7204 43.5696 57.2128 38.298 55.1183 36.5685Z'
            fill='#FFDC41'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M54.9391 36.5174C56.9248 38.1635 55.5092 43.1662 51.7749 47.6884C50.0518 49.7701 47.906 51.6661 46.1445 52.6462C46.5032 47.919 48.1238 42.3335 50.4682 36.7928C52.3193 35.8768 53.9591 35.7103 54.9456 36.5238L54.9391 36.5174Z'
          fill='#FFC739'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M257.588 57.7012L255.965 59.0398C254.024 60.6462 255.053 65.1015 258.261 68.9878C261.47 72.8741 265.657 74.7231 267.598 73.1209L269.222 71.7822L257.588 57.7012Z'
          fill='#FFC739'
        />
        <path
          d='M255.22 60.1232L257.818 57.9772L257.701 57.835L255.392 59.7426C255.325 59.8639 255.266 59.9894 255.216 60.1232'
          fill='#F09537'
        />
        <path
          d='M255.028 60.8928L258.119 58.341L258.002 58.1987L255.074 60.6167C255.057 60.7087 255.04 60.8008 255.028 60.8928Z'
          fill='#F09537'
        />
        <path
          d='M254.99 61.8089L258.55 58.868L258.387 58.6714L254.986 61.4826C254.986 61.5913 254.986 61.7001 254.99 61.8089Z'
          fill='#F09537'
        />
        <path
          d='M255.107 62.8049L259.085 59.5168L258.889 59.2783L255.048 62.4535C255.065 62.5706 255.086 62.6877 255.107 62.809'
          fill='#F09537'
        />
        <path
          d='M259.776 60.3487L259.541 60.0684L262.139 57.9224L262.373 58.2027L259.776 60.3487Z'
          fill='#F09537'
        />
        <path
          d='M255.404 63.9591L259.776 60.3447L259.541 60.0645L255.287 63.5784C255.32 63.7039 255.358 63.8294 255.4 63.9591'
          fill='#F09537'
        />
        <path
          d='M260.487 61.211L260.231 60.9014L262.829 58.7554L263.084 59.0649L260.487 61.211Z'
          fill='#F09537'
        />
        <path
          d='M255.822 65.068L260.491 61.2109L260.236 60.9014L255.663 64.6789C255.714 64.8086 255.768 64.9383 255.822 65.068Z'
          fill='#F09537'
        />
        <path
          d='M261.223 62.0977L260.943 61.7588L263.54 59.6128L263.821 59.9516L261.223 62.0977Z'
          fill='#F09537'
        />
        <path
          d='M256.337 66.1345L261.223 62.0976L260.943 61.7588L256.132 65.7329C256.199 65.8668 256.266 65.9965 256.337 66.1304'
          fill='#F09537'
        />
        <path
          d='M261.98 63.0178L261.679 62.6539L264.277 60.5078L264.578 60.8718L261.98 63.0178Z'
          fill='#F09537'
        />
        <path
          d='M256.948 67.1763L261.984 63.0139L261.683 62.6499L256.697 66.7705C256.776 66.9085 256.86 67.0424 256.948 67.1804'
          fill='#F09537'
        />
        <path
          d='M262.763 63.9631L262.436 63.5699L265.034 61.4238L265.36 61.8171L262.763 63.9631Z'
          fill='#F09537'
        />
        <path
          d='M257.642 68.1927L262.762 63.9592L262.436 63.5659L257.341 67.7743C257.437 67.9124 257.537 68.0546 257.638 68.1927'
          fill='#F09537'
        />
        <path
          d='M263.57 64.9382L263.223 64.5157L265.821 62.3696L266.168 62.7921L263.57 64.9382Z'
          fill='#F09537'
        />
        <path
          d='M258.429 69.1842L263.57 64.9381L263.223 64.5156L258.081 68.7617C258.144 68.837 258.203 68.9123 258.265 68.9876C258.32 69.0545 258.374 69.1173 258.429 69.1842Z'
          fill='#F09537'
        />
        <path
          d='M264.352 65.8835L264.026 65.4903L266.624 63.3442L266.95 63.7375L264.352 65.8835Z'
          fill='#F09537'
        />
        <path
          d='M259.253 70.0961L264.348 65.8835L264.022 65.4902L258.897 69.7238C259.014 69.8493 259.131 69.9748 259.249 70.0961'
          fill='#F09537'
        />
        <path
          d='M265.109 66.7995L264.808 66.4356L267.406 64.2896L267.707 64.6577L265.109 66.8037'
          fill='#F09537'
        />
        <path
          d='M260.118 70.9245L265.109 66.8039L264.808 66.4399L259.767 70.6024C259.884 70.7111 260.001 70.8199 260.118 70.9245Z'
          fill='#F09537'
        />
        <path
          d='M265.846 67.6905L265.565 67.3516L268.163 65.2056L268.444 65.5444L265.846 67.6905Z'
          fill='#F09537'
        />
        <path
          d='M261.026 71.6734L265.845 67.6909L265.565 67.3521L260.675 71.3931C260.792 71.4894 260.909 71.5814 261.026 71.6692'
          fill='#F09537'
        />
        <path
          d='M266.557 68.5523L266.302 68.2427L268.899 66.0967L269.155 66.4062L266.557 68.5523Z'
          fill='#F09537'
        />
        <path
          d='M261.976 72.3386L266.557 68.5527L266.302 68.2432L261.625 72.1086C261.742 72.188 261.859 72.2675 261.976 72.3386Z'
          fill='#F09537'
        />
        <path
          d='M267.247 69.3848L267.013 69.1045L269.611 66.9585L269.845 67.2388L267.247 69.3848Z'
          fill='#F09537'
        />
        <path
          d='M262.98 72.9113L267.247 69.3848L267.013 69.1045L262.629 72.7272C262.746 72.79 262.863 72.8527 262.98 72.9113Z'
          fill='#F09537'
        />
        <path
          d='M264.043 73.3632L267.9 70.1755L267.703 69.937L263.708 73.2377C263.821 73.2837 263.934 73.3255 264.043 73.3632Z'
          fill='#F09537'
        />
        <path
          d='M264.975 73.6186L268.402 70.7865L268.238 70.5898L264.657 73.5516C264.766 73.5767 264.871 73.6018 264.975 73.6186Z'
          fill='#F09537'
        />
        <path
          d='M265.854 73.6977L268.799 71.2672L268.682 71.125L265.578 73.6894C265.674 73.6936 265.766 73.6977 265.854 73.6977Z'
          fill='#F09537'
        />
        <path
          d='M266.712 73.5808L269.088 71.6188L268.971 71.4766L266.323 73.6644C266.456 73.6435 266.586 73.6184 266.707 73.5808'
          fill='#F09537'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M257.588 57.7013C259.529 56.0949 263.716 57.9481 266.925 61.8344C270.134 65.7207 271.167 70.1801 269.222 71.7823C267.281 73.3887 263.093 71.5355 259.884 67.6492C256.676 63.7629 255.643 59.3076 257.588 57.7013Z'
          fill='#F9E469'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M257.588 57.7013C259.529 56.0949 263.716 57.9481 266.925 61.8344C270.134 65.7207 271.167 70.1801 269.222 71.7823C267.281 73.3887 263.093 71.5355 259.884 67.6492C256.676 63.7629 255.643 59.3076 257.588 57.7013Z'
          fill='#F9E469'
        />
        <path
          d='M268.791 72.0667L266.703 61.5707C266.398 61.2152 266.08 60.8721 265.762 60.5542L268.097 72.301C268.343 72.2508 268.578 72.1755 268.791 72.0667Z'
          fill='#F9F3D6'
        />
        <path
          d='M266.766 72.3051L264.152 59.136C263.574 58.6967 262.993 58.3202 262.424 58.019L265.168 71.8324C265.724 72.0666 266.264 72.2298 266.77 72.3051'
          fill='#F9F3D6'
        />
        <path
          d='M264.607 71.5772L261.859 57.743C261.483 57.5715 261.114 57.4334 260.759 57.333L263.457 70.9079C263.846 71.1631 264.231 71.3848 264.612 71.5772'
          fill='#F9F3D6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M258.751 59.111C257.391 60.2363 258.37 63.6708 260.938 66.779C263.507 69.8872 266.695 71.4978 268.058 70.3767C269.418 69.2514 268.439 65.8168 265.871 62.7086C263.302 59.6004 260.114 57.9898 258.751 59.111Z'
          fill='#F09537'
        />
        <mask
          id='mask3_267_17128'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={258}
          y={58}
          width={11}
          height={13}
        >
          <path
            d='M258.751 59.111C257.391 60.2363 258.37 63.6708 260.938 66.779C263.507 69.8872 266.695 71.4978 268.058 70.3767C269.418 69.2514 268.439 65.8168 265.871 62.7086C263.302 59.6004 260.114 57.9898 258.751 59.111Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask3_267_17128)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M258.537 59.3785C259.905 58.249 263.101 59.8554 265.67 62.9636C268.239 66.0718 269.213 69.5147 267.845 70.6442C266.477 71.7737 263.281 70.1673 260.713 67.0591C258.144 63.9509 257.169 60.508 258.537 59.3785Z'
            fill='#FFDC41'
          />
        </g>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M258.654 59.3411C257.358 60.4162 258.282 63.6834 260.721 66.6369C261.846 67.9964 263.248 69.2347 264.398 69.8748C264.164 66.7875 263.105 63.1396 261.574 59.521C260.365 58.9228 259.294 58.814 258.65 59.3453L258.654 59.3411Z'
          fill='#FFC739'
        />
        <path
          opacity={0.1}
          d='M216.444 57.3818C220.524 58.727 224.118 61.2507 226.77 64.6327C229.415 68.0148 231.004 72.1142 231.324 76.3994C231.644 80.6847 230.69 84.9699 228.576 88.7106C226.462 92.4514 223.285 95.4811 219.448 97.422C215.612 99.3564 211.288 100.112 207.022 99.5934C202.756 99.0746 198.74 97.3003 195.486 94.4947C192.225 91.6891 189.875 87.9868 188.722 83.8425C187.569 79.7046 187.671 75.3105 189.016 71.2303C190.816 65.7537 194.717 61.2187 199.867 58.6245C205.011 56.0303 210.974 55.5819 216.451 57.3818H216.444Z'
          fill='url(#paint3_linear_267_17128)'
        />
        <path
          d='M204.94 92.0924L201.872 86.0201C201.789 85.8599 201.757 85.6806 201.776 85.5012C201.795 85.3219 201.866 85.1553 201.981 85.0144C202.096 84.8735 202.243 84.771 202.416 84.7198C202.589 84.6621 202.769 84.6621 202.942 84.707L212.121 87.7239C212.236 87.7623 212.345 87.8264 212.441 87.9033C212.537 87.9865 212.614 88.0826 212.665 88.1915C212.723 88.3004 212.755 88.4221 212.761 88.5438C212.768 88.6655 212.755 88.7936 212.71 88.9089C212.671 89.0242 212.614 89.1267 212.531 89.2164C212.454 89.306 212.358 89.3829 212.249 89.4342L210.699 90.2156L206.17 92.5023C206.061 92.56 205.939 92.592 205.818 92.5984C205.696 92.6048 205.574 92.5984 205.459 92.5536C205.344 92.5087 205.235 92.4575 205.145 92.3742C205.055 92.2974 204.979 92.1949 204.921 92.086H204.927L204.94 92.0924ZM198.227 81.4594C198.054 81.4018 197.888 81.3313 197.721 81.2545C197.029 80.9406 196.459 80.4089 196.094 79.7492C195.729 79.083 195.582 78.3208 195.678 77.5714C195.678 77.5393 195.678 77.5137 195.684 77.4817C194.973 77.0461 194.422 76.38 194.134 75.5921C193.846 74.8042 193.827 73.9459 194.089 73.1516L197.58 62.5379C197.888 61.6027 198.56 60.8276 199.438 60.3793C200.322 59.9373 201.34 59.854 202.275 60.1615L223.509 67.1498C224.323 67.4188 225.027 67.9633 225.489 68.6871C225.95 69.4109 226.148 70.2756 226.046 71.1275C226.123 71.1724 226.2 71.2236 226.27 71.2748C226.885 71.6656 227.359 72.2421 227.622 72.921C227.891 73.6 227.936 74.343 227.75 75.0476C227.724 75.1885 227.686 75.3231 227.641 75.4576L224.15 86.0713L224.105 86.2122C223.804 87.1026 223.17 87.8328 222.337 88.262C221.504 88.6911 220.537 88.7744 219.641 88.499L219.455 88.4413L198.221 81.4594H198.227Z'
          fill='url(#paint4_linear_267_17128)'
        />
        <path
          d='M200.001 76.0727C200.117 76.1111 200.238 76.1239 200.36 76.1175C200.482 76.1111 200.603 76.0727 200.712 76.0215C200.821 75.9638 200.917 75.8934 201.001 75.7973C201.077 75.7076 201.142 75.5987 201.18 75.4834C201.257 75.2464 201.238 74.9966 201.129 74.7724C201.02 74.5546 200.821 74.3881 200.591 74.3112L197.939 73.4401C197.702 73.3632 197.452 73.3824 197.228 73.4913C197.01 73.6002 196.843 73.7988 196.767 74.0294C196.728 74.1447 196.715 74.2664 196.722 74.3881C196.728 74.5098 196.767 74.6315 196.818 74.734C196.876 74.8429 196.946 74.939 197.042 75.0222C197.132 75.0991 197.241 75.1631 197.356 75.2016L200.008 76.0727H200.001Z'
          fill='white'
        />
        <path
          d='M219.416 70.6923L222.068 71.5634C222.183 71.6019 222.305 71.6147 222.427 71.6083C222.548 71.6019 222.67 71.5634 222.779 71.5122C222.888 71.4609 222.984 71.3841 223.067 71.288C223.144 71.1983 223.208 71.0894 223.247 70.9741C223.323 70.7371 223.304 70.4873 223.195 70.2631C223.086 70.0454 222.888 69.8788 222.657 69.8019L220.005 68.9308C219.768 68.8539 219.519 68.8732 219.294 68.9821C219.077 69.091 218.91 69.2895 218.833 69.5201C218.795 69.6354 218.782 69.7571 218.788 69.8788C218.795 70.0005 218.833 70.1222 218.885 70.2247C218.942 70.3336 219.013 70.4297 219.109 70.5129C219.198 70.5898 219.307 70.6539 219.423 70.6923H219.416Z'
          fill='white'
        />
        <path
          d='M205.17 73.7603C205.343 73.8179 205.536 73.8051 205.702 73.7219C205.869 73.6386 205.99 73.4913 206.048 73.3183L206.592 71.6593L207.841 72.0693L208.175 74.3496C208.194 74.4777 208.245 74.5994 208.335 74.6955C208.418 74.7916 208.527 74.8684 208.649 74.9069C208.751 74.9389 208.86 74.9517 208.969 74.9325C209.059 74.9197 209.148 74.8876 209.225 74.8428C209.302 74.798 209.372 74.7339 209.43 74.657C209.488 74.5866 209.526 74.4969 209.545 74.4136C209.565 74.324 209.571 74.2343 209.558 74.1382L209.244 72.0052C209.622 71.8835 209.968 71.6721 210.25 71.3967C210.532 71.1149 210.743 70.7754 210.871 70.3975C211.083 69.7569 211.025 69.0651 210.724 68.463C210.423 67.8609 209.891 67.4061 209.257 67.2012L207.515 66.6311C207.342 66.5734 207.15 66.5862 206.983 66.6695C206.817 66.7528 206.695 66.9001 206.637 67.0731L204.728 72.8763C204.671 73.0493 204.69 73.2415 204.773 73.408C204.857 73.5745 205.004 73.6962 205.177 73.7539L205.17 73.7603ZM208.822 68.5271C209.11 68.6232 209.347 68.8281 209.481 69.0907C209.616 69.3598 209.641 69.6736 209.545 69.9555C209.449 70.2437 209.244 70.4807 208.975 70.6152C208.706 70.7498 208.392 70.7754 208.11 70.6793L207.034 70.327L207.745 68.1684L208.822 68.5271Z'
          fill='white'
        />
        <path
          d='M210.212 77.9942C210.384 78.0518 210.577 78.039 210.743 77.9557C210.91 77.8725 211.031 77.7251 211.089 77.5522L211.634 75.8932L212.71 76.2455C213.35 76.4569 214.042 76.4056 214.644 76.0982C215.246 75.7971 215.701 75.2655 215.906 74.6313C216.117 73.9908 216.06 73.299 215.759 72.6969C215.458 72.0948 214.926 71.64 214.292 71.435L212.55 70.865C212.377 70.8073 212.184 70.8201 212.018 70.9034C211.851 70.9867 211.73 71.134 211.672 71.3069L209.763 77.1102C209.706 77.2832 209.718 77.4753 209.802 77.6419C209.885 77.8084 210.032 77.9301 210.205 77.9878L210.212 77.9942ZM214.586 74.1958C214.49 74.484 214.285 74.721 214.016 74.8555C213.747 74.99 213.433 75.0157 213.152 74.9196L212.075 74.5673L212.787 72.4087L213.863 72.761C214.004 72.8058 214.138 72.8827 214.247 72.9787C214.362 73.0748 214.452 73.1965 214.522 73.331C214.586 73.4656 214.631 73.6065 214.638 73.7602C214.65 73.9075 214.631 74.0613 214.586 74.2022V74.1958Z'
          fill='white'
        />
        <path
          d='M116.734 71.2057C115.645 72.4291 114.422 73.2618 113.167 73.8063C107.844 76.1058 101.759 73.2939 101.759 73.2939L101.381 69.694L101.336 69.2841L100.971 65.8187L115.812 63.7178C115.812 63.7178 116.132 65.1077 116.465 67.9966C116.555 68.9062 116.645 69.9758 116.741 71.2057H116.734Z'
          fill='#FFB798'
        />
        <path
          d='M120.36 57.3185C119.854 62.5517 117.555 65.5751 114.749 67.1956C111.149 69.2902 106.697 69.0724 104.071 68.2141C99.3889 66.664 94.9883 62.4492 94.604 59.8294C94.5848 59.6629 94.5143 59.4964 94.4247 59.3298C94.3734 59.2209 94.3094 59.1184 94.2197 59.0096C94.1941 58.9647 94.1556 58.9135 94.1044 58.8814C93.0988 57.7221 90.7031 56.5883 88.6022 54.5386C86.8022 52.8283 85.1881 50.484 84.7077 46.9354C83.4907 38.0383 96.9292 19.3858 114.384 34.2783C114.384 34.2783 114.954 35.1751 115.742 36.7316L115.755 36.7444C115.806 36.8405 115.857 36.9366 115.902 37.0326L115.94 37.1031C116.248 37.7116 116.568 38.3906 116.901 39.1528C118.823 43.5149 121.033 50.279 120.36 57.3249V57.3185Z'
          fill='#FFB798'
        />
        <path
          d='M83.1511 46.5383C85.5275 58.2666 92.8041 61.5141 92.8041 61.5141L93.8097 60.1562L94.2004 59.6309L94.4246 59.3299L94.7833 58.8559V58.8367L96.5128 56.5371L96.3014 52.3608C96.4423 51.8996 96.2886 50.5993 96.1285 48.9275V48.9083C95.6417 43.8993 95.0011 35.5594 101.137 36.2576C109.324 37.1928 114.563 34.6243 114.563 34.6243L115.748 36.7316H115.755L115.902 37.0263L115.947 37.1032L116.44 37.9871C116.44 37.9871 116.831 37.8974 117.356 37.5579C118.163 37.0391 119.278 35.9374 119.7 33.6442C119.803 33.1126 119.86 32.6002 119.86 32.1069C119.989 27.0403 115.684 23.6902 107.69 22.8768C105.576 22.6654 103.194 22.6269 100.58 22.7871C87.084 23.5942 87.0648 33.8108 87.0648 33.8108C87.0648 33.8108 80.7747 34.8228 83.1511 46.5383Z'
          fill='#231F20'
        />
        <path
          d='M98.0309 52.9625C98.3 56.1076 96.3847 58.8491 93.7521 59.0797C91.1131 59.3103 88.7623 56.9467 88.4869 53.8016C88.2178 50.6566 90.1331 47.9151 92.7657 47.6845C95.4047 47.4539 97.7555 49.8175 98.0309 52.9625Z'
          fill='#FFB798'
        />
        <path
          d='M116.107 50.4842C114.602 50.7853 113.128 49.8244 112.834 48.3576L112.219 45.3022C111.924 43.829 112.795 41.7536 114.301 41.4526C115.806 41.1515 117.394 42.7401 117.689 44.2133L118.304 47.2687C118.599 48.7419 117.612 50.1895 116.107 50.4906V50.4842Z'
          fill='#FFF2EE'
        />
        <mask
          id='mask4_267_17128'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={112}
          y={42}
          width={7}
          height={9}
        >
          <path
            d='M116.107 50.484C114.602 50.7851 113.129 49.8243 112.834 48.3574L112.219 45.302C111.924 43.8288 112.911 42.3812 114.416 42.0801C115.921 41.7791 117.395 42.7399 117.689 44.2067L118.304 47.2621C118.599 48.7353 117.612 50.183 116.107 50.484Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask4_267_17128)'>
          <path
            d='M116.645 45.7375C116.748 46.961 115.832 48.0435 114.602 48.1524C113.365 48.2613 112.277 47.3581 112.174 46.1347C112.072 44.9112 112.988 43.8287 114.217 43.7198C115.454 43.6109 116.543 44.5141 116.645 45.7375Z'
            fill='#231F20'
          />
          <path
            d='M113.897 45.0394C113.923 45.3597 113.686 45.6415 113.365 45.6671C113.045 45.6927 112.757 45.4621 112.731 45.1419C112.706 44.8216 112.943 44.5398 113.263 44.5141C113.583 44.4885 113.871 44.7191 113.897 45.0394Z'
            fill='#FFF2EE'
          />
        </g>
        <path
          d='M94.5994 50.2853C94.5994 50.2853 93.6706 49.9009 92.6073 49.9906C91.9924 50.0419 91.3134 50.2596 90.7497 50.8297C90.1861 51.3934 89.7569 52.3094 89.616 53.6673C89.5968 53.8595 89.7056 54.0388 89.853 54.0709C90.0067 54.1029 90.1476 53.9748 90.1668 53.7826C90.2949 52.5592 90.6601 51.8354 91.1084 51.3806C91.5568 50.9258 92.1205 50.74 92.6649 50.6888C93.1325 50.6504 93.5809 50.7144 93.914 50.7913C94.0805 50.8297 94.2151 50.8682 94.3047 50.9002C94.3496 50.913 94.388 50.9258 94.4072 50.9386L94.4328 50.9514H94.4392C94.5866 51.0155 94.7403 50.913 94.7851 50.7336C94.83 50.5479 94.7403 50.3557 94.593 50.2917L94.5994 50.2853Z'
          fill='#231F20'
        />
        <path
          d='M113.955 38.3585C113.955 38.3585 114.07 38.3137 114.243 38.2688C114.538 38.192 115.012 38.1279 115.454 38.2112C115.671 38.256 115.883 38.3265 116.081 38.4546C116.28 38.5827 116.466 38.762 116.645 39.0375C116.85 39.3641 117.286 39.4602 117.612 39.2617C117.945 39.0567 118.041 38.6275 117.836 38.3008C117.561 37.8589 117.215 37.5258 116.85 37.2888C116.293 36.9301 115.697 36.802 115.185 36.7828C114.666 36.7636 114.217 36.8404 113.903 36.9173C113.628 36.9877 113.455 37.0518 113.429 37.0646C113.071 37.2119 112.891 37.6155 113.039 37.9742C113.186 38.3329 113.596 38.5058 113.955 38.3585Z'
          fill='#231F20'
        />
        <path
          d='M100.779 39.8187L100.529 39.6778L100.779 39.8251C100.779 39.8251 100.971 39.5113 101.291 39.2166C101.452 39.0693 101.637 38.922 101.849 38.8131C102.06 38.7042 102.278 38.6273 102.528 38.6081C102.765 38.5889 103.021 38.6081 103.335 38.7234C103.7 38.8579 104.103 38.6657 104.238 38.307C104.372 37.9419 104.187 37.5448 103.822 37.4103C103.328 37.2309 102.848 37.1733 102.412 37.2181C101.938 37.2566 101.516 37.4103 101.163 37.596C100.632 37.8843 100.247 38.2558 99.9847 38.5633C99.7221 38.8643 99.5812 39.1013 99.5684 39.1269C99.3762 39.46 99.4915 39.8892 99.8246 40.0813C100.164 40.2735 100.593 40.1582 100.785 39.8251L100.779 39.8187Z'
          fill='#231F20'
        />
        <path
          d='M110.822 49.2603C110.822 49.2603 114.967 46.3907 119.322 48.3572C119.322 48.3572 118.579 51.1243 114.538 50.7976C110.496 50.4645 110.822 49.2603 110.822 49.2603Z'
          fill='#FFB798'
        />
        <path
          d='M105.378 51.3745C103.777 51.6948 102.214 50.6763 101.9 49.1134L101.246 45.8658C100.933 44.3029 101.868 42.1251 103.469 41.8048C105.07 41.4846 106.749 43.1371 107.062 44.7001L107.716 47.9476C108.03 49.5105 106.979 51.0542 105.378 51.3745Z'
          fill='#FFF2EE'
        />
        <path
          d='M106.006 46.1283C106.121 47.4286 105.141 48.5816 103.828 48.6969C102.515 48.8122 101.355 47.8514 101.247 46.5511C101.131 45.2508 102.111 44.0978 103.424 43.9825C104.737 43.8672 105.897 44.828 106.006 46.1283Z'
          fill='#231F20'
        />
        <path
          d='M103.232 45.4493C103.264 45.7887 103.008 46.0898 102.662 46.1154C102.316 46.1474 102.015 45.8976 101.99 45.5581C101.957 45.2187 102.214 44.9176 102.56 44.8856C102.899 44.8536 103.207 45.1034 103.232 45.4428V45.4493Z'
          fill='#FFF2EE'
        />
        <path
          d='M99.7606 50.0803C99.7606 50.0803 104.167 47.025 108.805 49.1195C108.805 49.1195 108.011 52.066 103.713 51.7137C99.4147 51.3614 99.7606 50.0803 99.7606 50.0803Z'
          fill='#FFB798'
        />
        <path
          d='M109.279 48.1225C109.279 48.1225 112.451 47.392 112.472 49.7506C112.493 52.1092 109.543 51.4691 109.543 51.4691'
          fill='#DE7F89'
        />
        <path
          d='M109.439 60.9309C114.884 60.8604 117.523 55.4222 116.306 54.5191C116.062 54.3333 115.812 54.1732 115.569 54.0515C115.197 53.8465 114.845 53.7056 114.486 53.6031C114.32 53.5583 114.153 53.5134 113.987 53.4814C111.463 52.9882 109.106 54.3077 106.883 53.2764C106.781 53.2316 106.678 53.1996 106.582 53.1803C104.001 52.5975 104.199 61.0013 109.439 60.9309Z'
          fill='#231F20'
        />
        <path
          d='M106.947 54.1282C107.966 55.1595 113.634 55.2428 114.461 55.1723C115.159 55.1018 115.473 54.5126 115.562 54.045C115.191 53.84 114.839 53.6991 114.48 53.5966C114.313 53.5517 114.147 53.5069 113.98 53.4749C111.457 52.9817 109.099 54.3012 106.877 53.2699C106.774 53.2251 106.672 53.193 106.576 53.1738C106.409 53.4877 106.172 53.3468 106.947 54.1218V54.1282Z'
          fill='#FFF2EE'
        />
        <path
          d='M106.55 59.4324C106.55 59.4324 106.793 59.0417 107.466 59.362C108.145 59.6822 110.111 60.0601 110.835 59.8231C111.559 59.5861 111.982 60.1434 111.982 60.1434C111.982 60.1434 108.555 61.4629 106.55 59.4324Z'
          fill='#FFF2EE'
        />
        <path
          d='M106.204 57.1837C106.204 57.1837 110.163 56.889 111.104 58.8939C111.104 58.8939 110.823 59.6818 108.632 59.3231C106.441 58.958 105.89 57.7537 106.204 57.1837Z'
          fill='#E97983'
        />
        <path
          d='M101.528 69.207L101.374 69.6938L101.33 69.2839C101.464 69.2391 101.528 69.207 101.528 69.207Z'
          fill='#FFB798'
        />
        <path
          d='M147.506 112.514C147.506 112.514 144.931 103.258 144.399 99.4855L152.214 155.18C145.027 162.034 91.2026 163.668 91.2026 163.668L89.6589 147.552L88.8775 112.36L69.6997 108.562C69.6997 108.562 75.8297 90.1272 79.1989 84.1254C82.3888 78.4502 99.1325 70.0847 101.323 69.2841L101.368 69.694L101.746 73.2938C101.746 73.2938 107.831 76.1058 113.154 73.8063C114.409 73.2618 115.633 72.4291 116.722 71.2057C116.632 69.9823 116.542 68.9061 116.446 67.9966C117.1 68.0286 130.782 69.5531 134.759 70.9046C138.827 72.269 154.904 89.2433 154.904 89.2433L147.493 112.521L147.506 112.514Z'
          fill='#005668'
        />
        <path
          d='M116.735 71.2057C115.646 72.4291 114.422 73.2618 113.167 73.8063L116.453 67.9966H116.466C116.555 68.9061 116.645 69.9758 116.741 71.2057H116.735Z'
          fill='#FFB798'
        />
        <path
          d='M70.3533 125.344C70.3533 125.344 71.282 145.047 89.6143 135.202C106.435 126.17 120.885 110.029 120.885 110.029L118.938 99.9531L70.3533 125.344Z'
          fill='#FFB798'
        />
        <path
          d='M161.56 62.7056C161.56 62.7056 147.167 98.2684 147.506 112.514C147.698 120.521 156.756 126.836 161.38 116.146C166.005 105.455 179.392 66.4208 179.392 66.4208L161.56 62.6992V62.7056Z'
          fill='#FFB798'
        />
        <g clipPath='url(#clip1_267_17128)'>
          <path
            d='M195.231 61.5054C195.597 64.6364 189.866 71.8156 187.293 72.0363C184.721 72.2569 180.831 56.7055 182.397 53.0917C183.962 49.478 196.41 51.5056 196.238 53.8297C195.976 57.2434 188.307 59.9951 188.569 61.6709C188.831 63.3467 194.948 59.0641 195.231 61.5054Z'
            fill='#F4B298'
          />
          <path
            d='M157.06 32.5399C153.28 30.3468 153.011 36.1812 152.646 40.8018C152.28 45.4155 149.832 52.4223 149.411 55.4153C148.984 58.4084 147.549 64.8979 150.508 73.4357C155.708 88.4355 174.08 86.1252 176.08 85.7596C179.045 85.2217 182.445 83.4976 184.356 80.5115C190.224 71.3392 185.687 60.3187 187.19 55.3602C191.238 42.0431 206.831 23.5676 201.714 21.278C198.252 19.7263 188.831 36.0915 181.383 37.2018C169.466 38.9742 157.066 32.5399 157.066 32.5399H157.06Z'
            fill='#FFBC9D'
          />
          <path
            d='M188.066 78.4705L155.191 75.8499C154.342 75.7809 153.708 75.0361 153.777 74.1878L158.735 11.8027C158.804 10.9544 159.549 10.32 160.397 10.3889L193.273 13.0096C194.121 13.0785 194.755 13.8233 194.687 14.6716L189.721 77.0637C189.652 77.9119 188.907 78.5464 188.059 78.4774L188.066 78.4705Z'
            fill='#1E2C42'
          />
          <path
            d='M192.671 17.3924L160.298 14.8162C160.249 14.8123 160.206 14.8491 160.202 14.8985L155.62 72.4741C155.616 72.5234 155.653 72.5666 155.702 72.5706L188.075 75.1468C188.125 75.1507 188.168 75.1139 188.172 75.0645L192.754 17.4889C192.758 17.4396 192.721 17.3964 192.671 17.3924Z'
            fill='#00ACF0'
          />
          <path
            d='M173.625 14.3131C173.604 14.5682 173.383 14.7544 173.128 14.7337C172.873 14.713 172.687 14.4924 172.708 14.2372C172.728 13.982 172.949 13.7958 173.204 13.8165C173.459 13.8372 173.646 14.0579 173.625 14.3131Z'
            fill='white'
          />
          <path
            d='M180.011 15.2853L174.666 14.8578C174.411 14.8371 174.225 14.6164 174.245 14.3612C174.266 14.106 174.487 13.9198 174.742 13.9405L180.087 14.3681C180.342 14.3888 180.528 14.6095 180.507 14.8647C180.487 15.1198 180.266 15.306 180.011 15.2853Z'
            fill='white'
          />
          <path
            d='M195.824 44.6222C195.066 42.0498 186.335 42.2981 185.893 45.767C185.452 49.2359 197.535 50.4359 195.824 44.6222Z'
            fill='#F4B298'
          />
          <path
            d='M196.245 53.1051C196.052 50.4362 188.28 49.2362 187.114 52.5327C185.949 55.8292 196.686 59.1464 196.245 53.1051Z'
            fill='#F4B298'
          />
          <path
            d='M194.852 60.0637C193.68 58.0155 186.28 60.7327 186.659 63.1809C187.032 65.6222 197.349 64.4291 194.852 60.0637Z'
            fill='#F4B298'
          />
          <path
            d='M156.37 32.2157C157.935 33.1743 159.108 43.3879 155.37 44.9051C151.632 46.4223 152.501 29.8433 156.37 32.2157Z'
            fill='#F4B298'
          />
          <path
            d='M167.825 31.478L148.08 40.6503C146.722 41.3123 145.046 40.7192 144.356 39.2917L139.253 28.3539C138.591 26.9953 139.253 25.2919 140.632 24.6988L160.377 15.5265C161.735 14.8645 163.411 15.4576 164.101 16.8851L169.204 27.8229C169.797 29.2091 169.204 30.8849 167.825 31.478Z'
            fill='#87AA5F'
          />
          <path
            d='M168.094 30.7603L148.349 39.9326C146.99 40.5947 145.315 40.0016 144.625 38.574L139.522 27.6363C138.86 26.2777 139.453 24.6018 140.88 23.9122L160.742 14.8503C162.101 14.1882 163.776 14.7813 164.466 16.2089L169.473 27.1052C170.066 28.4914 169.473 30.1673 168.094 30.7603Z'
            fill='#A2C97C'
          />
          <path
            d='M166.983 29.6502L148.487 38.2776C147.57 38.6983 146.494 38.2638 146.094 37.4156L141.439 27.4571C141.018 26.5468 141.453 25.464 142.37 25.0434L160.866 16.4159C161.784 15.9952 162.859 16.4297 163.259 17.278L167.915 27.2364C168.335 28.1468 167.901 29.2295 166.983 29.6502Z'
            fill='#BEDB8B'
          />
          <path
            d='M141.136 27.0365L142.053 29.064C143.722 28.2089 144.48 26.1606 143.667 24.402L143.618 24.271L141.86 25.0848C141.081 25.4572 140.77 26.2572 141.143 27.0365H141.136Z'
            fill='#A2C97C'
          />
          <path
            d='M148.053 38.7466L150.08 37.8294C149.225 36.1604 147.177 35.4018 145.418 36.2156L145.287 36.2639L146.101 38.0225C146.515 38.7121 147.315 39.0225 148.053 38.7397V38.7466Z'
            fill='#A2C97C'
          />
          <path
            d='M168.384 27.6711L167.466 25.6436C165.797 26.4987 165.039 28.547 165.853 30.3055L165.901 30.4366L167.659 29.6228C168.37 29.278 168.721 28.3814 168.377 27.6711H168.384Z'
            fill='#A2C97C'
          />
          <path
            d='M161.397 15.9886L159.37 16.9058C160.225 18.5748 162.273 19.3334 164.032 18.5196L164.163 18.4713L163.349 16.7127C163.004 16.0024 162.108 15.6507 161.397 15.9955V15.9886Z'
            fill='#A2C97C'
          />
          <path
            d='M151.984 30.457C151.715 30.5605 151.466 30.4984 151.363 30.2295L149.66 26.5054C149.556 26.2364 149.646 26.0502 149.887 25.8847L151.404 25.2364C151.915 24.9675 152.37 24.9537 152.715 25.0502C153.06 25.1537 153.384 25.4088 153.563 25.8778C153.687 26.2157 153.749 26.5743 153.687 26.8226C153.653 27.1399 153.439 27.3743 153.149 27.6364C153.446 27.6019 153.625 27.6847 153.928 27.8778L154.791 28.5467C154.908 28.6571 155.025 28.7674 155.011 28.926C154.997 29.0846 154.949 29.1743 154.749 29.2502C154.618 29.2984 154.549 29.326 154.453 29.2846C154.363 29.2433 154.266 29.2019 154.177 29.1605L152.984 28.2295C152.866 28.1191 152.68 28.0364 152.549 28.0847C152.391 28.0709 152.253 28.1191 152.122 28.1674L151.542 28.464L152.232 29.8915C152.335 30.1605 152.246 30.3467 151.984 30.4432V30.457ZM151.225 27.8364L152.204 27.395C152.784 27.0985 153.018 26.7054 152.777 26.264C152.535 25.8226 152.163 25.6502 151.522 25.9744L150.542 26.4157L151.232 27.8433L151.225 27.8364Z'
            fill='white'
          />
          <path
            d='M156.873 29.616C156.604 29.7194 156.418 29.6298 156.253 29.3884L154.598 25.8023C154.494 25.5333 154.584 25.3471 154.825 25.1816C155.094 25.0781 155.28 25.1678 155.446 25.4092L155.494 25.5402C155.487 25.3126 155.57 25.1264 155.722 24.9195C155.873 24.7126 156.046 24.5678 156.253 24.4919C156.522 24.3885 156.791 24.2919 157.039 24.3471C157.287 24.4023 157.57 24.5333 157.777 24.685C158.011 24.9057 158.177 25.1471 158.37 25.4574C158.494 25.7954 158.625 26.1264 158.591 26.4436C158.625 26.7402 158.501 27.016 158.349 27.2229C158.198 27.4298 157.984 27.6643 157.715 27.7677C157.515 27.8436 157.246 27.947 157.06 27.8574C156.832 27.8643 156.646 27.7815 156.439 27.6298L156.984 28.8781C157.204 29.2574 157.142 29.5056 156.873 29.6091V29.616ZM157.239 27.3333C157.508 27.2298 157.66 27.0229 157.715 26.7747C157.777 26.5264 157.742 26.2298 157.611 25.8919C157.48 25.554 157.253 25.3402 157.039 25.1885C156.853 25.1057 156.604 25.0437 156.363 25.2161C156.094 25.3195 155.942 25.5264 155.887 25.7747C155.832 26.0229 155.86 26.3195 155.991 26.6574C156.122 26.9953 156.349 27.2091 156.563 27.3609C156.749 27.4436 156.977 27.4367 157.239 27.3333Z'
            fill='white'
          />
          <path
            d='M141.494 23.8643C141.356 23.1057 141.218 22.4161 141.011 21.6575C140.805 20.9678 140.598 20.3471 140.322 19.6575C139.701 18.4161 138.667 17.6575 137.356 17.3127C136.253 16.9679 135.218 16.761 134.115 16.4851C134.115 16.4851 134.184 16.4851 134.253 16.4162C135.356 16.1403 136.529 15.9334 137.632 15.5886C139.149 15.1059 140.184 14.1404 140.667 12.6231C141.011 11.5887 141.218 10.5542 141.494 9.45078C141.494 9.31285 141.563 9.24389 141.632 9.10596C141.701 9.31285 141.701 9.51974 141.77 9.72664C141.977 10.5542 142.184 11.4507 142.391 12.2783C142.873 14.0024 144.046 15.1748 145.77 15.6576C146.804 15.9334 147.839 16.1403 148.873 16.4162C148.942 16.4162 149.011 16.4162 149.08 16.4851C148.322 16.6231 147.632 16.761 147.011 16.9679C146.322 17.1748 145.701 17.3817 145.08 17.5886C143.632 18.2093 142.804 19.3127 142.391 20.7609C142.115 21.6575 141.908 22.554 141.701 23.4505C141.563 23.5885 141.563 23.7264 141.494 23.8643Z'
            fill='#FAE78D'
          />
          <path
            d='M141.356 12.1401C141.356 12.1401 140.46 16.0021 137.219 16.209C137.219 16.209 141.425 16.8987 141.356 12.1401Z'
            fill='white'
          />
          <path
            d='M172.694 45.1372C172.618 46.0641 172.048 46.7686 170.939 46.6778C169.83 46.587 169.4 45.8461 169.48 44.8739L169.721 41.9344L169.233 41.8945L168.991 44.8564C168.891 46.0774 169.504 47.0379 170.9 47.1523C172.319 47.2684 173.108 46.3544 173.201 45.2239L173.443 42.2621L172.956 42.2222L172.694 45.1372Z'
            fill='white'
          />
          <path
            d='M176.084 42.4327L175.574 42.391L175.141 47.4086L178.663 47.7198L178.7 47.2668L175.689 46.9983L176.084 42.4327Z'
            fill='white'
          />
          <path
            d='M182.499 42.845C182.157 42.8099 181.812 42.8453 181.484 42.949C181.156 43.0527 180.852 43.2226 180.592 43.4484C180.331 43.6741 180.119 43.9508 179.968 44.2618C179.816 44.5728 179.73 44.9115 179.713 45.2571C179.675 45.7716 179.789 46.2856 180.041 46.7343C180.293 47.1831 180.671 47.5464 181.128 47.7785C181.585 48.0105 182.1 48.1009 182.609 48.0381C183.117 47.9754 183.595 47.7624 183.984 47.426C184.372 47.0896 184.653 46.6448 184.791 46.148C184.929 45.6511 184.918 45.1243 184.759 44.6342C184.6 44.1441 184.301 43.7125 183.899 43.3941C183.496 43.0756 183.009 42.8846 182.499 42.845ZM182.086 47.615C181.807 47.5972 181.535 47.5219 181.287 47.3938C181.039 47.2656 180.819 47.0874 180.643 46.8701C180.466 46.6528 180.335 46.4011 180.259 46.1307C180.183 45.8603 180.163 45.5769 180.2 45.2982C180.216 45.0178 180.288 44.7434 180.411 44.4912C180.534 44.239 180.706 44.0141 180.917 43.8297C181.127 43.6452 181.372 43.505 181.637 43.4173C181.902 43.3296 182.182 43.2961 182.459 43.3189C182.737 43.3416 183.008 43.4202 183.255 43.5498C183.502 43.6795 183.721 43.8576 183.899 44.0739C184.076 44.2901 184.209 44.54 184.29 44.8088C184.37 45.0777 184.396 45.3601 184.367 45.6394C184.356 45.9235 184.287 46.2024 184.165 46.4586C184.042 46.7148 183.868 46.9427 183.654 47.128C183.44 47.3133 183.19 47.452 182.92 47.5353C182.65 47.6187 182.366 47.6448 182.086 47.6123L182.086 47.615Z'
            fill='white'
          />
          <path
            d='M161.644 36.4937L161.489 36.481L161.476 36.6392L161.631 36.6519L161.644 36.4937Z'
            fill='white'
          />
          <path
            d='M162.226 36.746L162.072 36.7334L162.059 36.8917L162.214 36.9044L162.226 36.746Z'
            fill='white'
          />
          <path
            d='M162.826 37.0454L162.671 37.0327L162.658 37.191L162.813 37.2037L162.826 37.0454Z'
            fill='white'
          />
          <path
            d='M163.428 37.3223L163.273 37.3096L163.26 37.4678L163.415 37.4805L163.428 37.3223Z'
            fill='white'
          />
          <path
            d='M164.028 37.622L163.874 37.6094L163.861 37.7676L164.016 37.7803L164.028 37.622Z'
            fill='white'
          />
          <path
            d='M164.61 37.8745L164.455 37.8618L164.442 38.0201L164.597 38.0328L164.61 37.8745Z'
            fill='white'
          />
          <path
            d='M165.168 38.1479L165.013 38.1353L165 38.2935L165.155 38.3062L165.168 38.1479Z'
            fill='white'
          />
          <path
            d='M165.707 38.374L165.552 38.3613L165.539 38.5196L165.694 38.5323L165.707 38.374Z'
            fill='white'
          />
          <path
            d='M166.202 38.5962L166.047 38.5835L166.034 38.7418L166.189 38.7545L166.202 38.5962Z'
            fill='white'
          />
          <path
            d='M162.239 37.1338L162.083 37.1211L162.071 37.2793L162.226 37.2921L162.239 37.1338Z'
            fill='white'
          />
          <path
            d='M162.756 37.3584L162.601 37.3457L162.588 37.504L162.743 37.5166L162.756 37.3584Z'
            fill='white'
          />
          <path
            d='M163.296 37.5845L163.141 37.5718L163.128 37.73L163.283 37.7427L163.296 37.5845Z'
            fill='white'
          />
          <path
            d='M163.833 37.8335L163.677 37.8208L163.665 37.9791L163.82 37.9918L163.833 37.8335Z'
            fill='white'
          />
          <path
            d='M164.37 38.0825L164.215 38.0698L164.202 38.2281L164.357 38.2408L164.37 38.0825Z'
            fill='white'
          />
          <path
            d='M164.909 38.3086L164.754 38.2959L164.741 38.4542L164.896 38.4669L164.909 38.3086Z'
            fill='white'
          />
          <path
            d='M165.426 38.5327L165.271 38.52L165.258 38.6783L165.413 38.691L165.426 38.5327Z'
            fill='white'
          />
          <path
            d='M165.899 38.7539L165.744 38.7412L165.731 38.9001L165.886 38.9128L165.899 38.7539Z'
            fill='white'
          />
          <path
            d='M166.376 38.9292L166.22 38.9165L166.207 39.0748L166.363 39.0875L166.376 38.9292Z'
            fill='white'
          />
          <path
            d='M162.793 37.7256L162.638 37.7129L162.625 37.8711L162.78 37.8838L162.793 37.7256Z'
            fill='white'
          />
          <path
            d='M163.245 37.9219L163.09 37.9092L163.077 38.0674L163.232 38.0801L163.245 37.9219Z'
            fill='white'
          />
          <path
            d='M163.72 38.1201L163.565 38.1074L163.552 38.2657L163.707 38.2784L163.72 38.1201Z'
            fill='white'
          />
          <path
            d='M164.217 38.3203L164.061 38.3076L164.048 38.4659L164.204 38.4786L164.217 38.3203Z'
            fill='white'
          />
          <path
            d='M164.691 38.5181L164.536 38.5054L164.523 38.6636L164.678 38.6763L164.691 38.5181Z'
            fill='white'
          />
          <path
            d='M165.186 38.7407L165.031 38.728L165.018 38.8863L165.173 38.899L165.186 38.7407Z'
            fill='white'
          />
          <path
            d='M165.641 38.9146L165.485 38.9019L165.472 39.0601L165.628 39.0728L165.641 38.9146Z'
            fill='white'
          />
          <path
            d='M166.094 39.1113L165.938 39.0986L165.926 39.2569L166.081 39.2696L166.094 39.1113Z'
            fill='white'
          />
          <path
            d='M166.524 39.2832L166.369 39.2705L166.356 39.4288L166.511 39.4415L166.524 39.2832Z'
            fill='white'
          />
          <path
            d='M163.26 38.2871L163.105 38.2744L163.092 38.4327L163.247 38.4454L163.26 38.2871Z'
            fill='white'
          />
          <path
            d='M163.671 38.4346L163.516 38.4219L163.503 38.5801L163.658 38.5928L163.671 38.4346Z'
            fill='white'
          />
          <path
            d='M164.104 38.607L163.948 38.5942L163.935 38.7531L164.091 38.7658L164.104 38.607Z'
            fill='white'
          />
          <path
            d='M164.558 38.7803L164.403 38.7676L164.39 38.9258L164.545 38.9385L164.558 38.7803Z'
            fill='white'
          />
          <path
            d='M164.99 38.9522L164.835 38.9395L164.822 39.0977L164.978 39.1104L164.99 38.9522Z'
            fill='white'
          />
          <path
            d='M165.423 39.1245L165.267 39.1118L165.254 39.2701L165.41 39.2828L165.423 39.1245Z'
            fill='white'
          />
          <path
            d='M165.855 39.2964L165.7 39.2837L165.687 39.4419L165.842 39.4546L165.855 39.2964Z'
            fill='white'
          />
          <path
            d='M166.289 39.4458L166.134 39.4331L166.121 39.5914L166.276 39.6041L166.289 39.4458Z'
            fill='white'
          />
          <path
            d='M166.678 39.5913L166.523 39.5786L166.51 39.7369L166.666 39.7496L166.678 39.5913Z'
            fill='white'
          />
          <path
            d='M163.687 38.8003L163.531 38.7876L163.518 38.9459L163.674 38.9586L163.687 38.8003Z'
            fill='white'
          />
          <path
            d='M164.056 38.9219L163.901 38.9092L163.888 39.0674L164.043 39.0801L164.056 38.9219Z'
            fill='white'
          />
          <path
            d='M164.445 39.0674L164.29 39.0547L164.277 39.2129L164.432 39.2257L164.445 39.0674Z'
            fill='white'
          />
          <path
            d='M164.857 39.2149L164.702 39.2021L164.689 39.3604L164.844 39.3731L164.857 39.2149Z'
            fill='white'
          />
          <path
            d='M165.247 39.3609L165.092 39.3481L165.079 39.5064L165.234 39.5191L165.247 39.3609Z'
            fill='white'
          />
          <path
            d='M165.659 39.5083L165.504 39.4956L165.491 39.6539L165.646 39.6666L165.659 39.5083Z'
            fill='white'
          />
          <path
            d='M166.049 39.6538L165.894 39.6411L165.881 39.7994L166.036 39.8121L166.049 39.6538Z'
            fill='white'
          />
          <path
            d='M166.44 39.7769L166.285 39.7642L166.272 39.9224L166.427 39.9351L166.44 39.7769Z'
            fill='white'
          />
          <path
            d='M166.807 39.9209L166.652 39.9082L166.639 40.0665L166.794 40.0791L166.807 39.9209Z'
            fill='white'
          />
          <path
            d='M164.048 39.2852L163.893 39.2725L163.88 39.4307L164.035 39.4434L164.048 39.2852Z'
            fill='white'
          />
          <path
            d='M164.397 39.3819L164.242 39.3691L164.229 39.5274L164.384 39.5401L164.397 39.3819Z'
            fill='white'
          />
          <path
            d='M164.745 39.5015L164.59 39.4888L164.577 39.647L164.732 39.6597L164.745 39.5015Z'
            fill='white'
          />
          <path
            d='M165.114 39.6226L164.959 39.6099L164.946 39.7681L165.101 39.7808L165.114 39.6226Z'
            fill='white'
          />
          <path
            d='M165.483 39.7442L165.328 39.7314L165.315 39.8903L165.47 39.903L165.483 39.7442Z'
            fill='white'
          />
          <path
            d='M165.853 39.8653L165.697 39.8525L165.684 40.0108L165.84 40.0235L165.853 39.8653Z'
            fill='white'
          />
          <path
            d='M166.222 39.9868L166.067 39.9741L166.054 40.1324L166.209 40.1451L166.222 39.9868Z'
            fill='white'
          />
          <path
            d='M166.569 40.1065L166.414 40.0938L166.401 40.252L166.556 40.2647L166.569 40.1065Z'
            fill='white'
          />
          <path
            d='M166.917 40.2256L166.761 40.2129L166.748 40.3711L166.904 40.3839L166.917 40.2256Z'
            fill='white'
          />
          <path
            d='M164.348 39.7193L164.192 39.7065L164.179 39.8648L164.335 39.8775L164.348 39.7193Z'
            fill='white'
          />
          <path
            d='M164.674 39.8139L164.521 39.8013L164.508 39.9595L164.663 39.9722L164.674 39.8139Z'
            fill='white'
          />
          <path
            d='M165.001 39.9097L164.846 39.897L164.833 40.0552L164.989 40.0679L165.001 39.9097Z'
            fill='white'
          />
          <path
            d='M165.35 40.0064L165.195 39.9937L165.182 40.1519L165.337 40.1646L165.35 40.0064Z'
            fill='white'
          />
          <path
            d='M165.697 40.1259L165.543 40.1133L165.53 40.2715L165.685 40.2842L165.697 40.1259Z'
            fill='white'
          />
          <path
            d='M166.024 40.2207L165.869 40.208L165.856 40.3663L166.011 40.379L166.024 40.2207Z'
            fill='white'
          />
          <path
            d='M166.373 40.3174L166.218 40.3047L166.205 40.4629L166.36 40.4757L166.373 40.3174Z'
            fill='white'
          />
          <path
            d='M166.7 40.4126L166.545 40.3999L166.532 40.5582L166.687 40.5709L166.7 40.4126Z'
            fill='white'
          />
          <path
            d='M167.027 40.5078L166.872 40.4951L166.859 40.6534L167.014 40.6661L167.027 40.5078Z'
            fill='white'
          />
          <path
            d='M164.604 40.1284L164.449 40.1157L164.436 40.274L164.591 40.2867L164.604 40.1284Z'
            fill='white'
          />
          <path
            d='M164.911 40.1977L164.756 40.1851L164.743 40.3433L164.898 40.356L164.911 40.1977Z'
            fill='white'
          />
          <path
            d='M165.238 40.293L165.082 40.2803L165.069 40.4385L165.225 40.4512L165.238 40.293Z'
            fill='white'
          />
          <path
            d='M165.544 40.3638L165.389 40.3511L165.376 40.5093L165.531 40.522L165.544 40.3638Z'
            fill='white'
          />
          <path
            d='M165.871 40.4585L165.716 40.4458L165.703 40.6041L165.858 40.6168L165.871 40.4585Z'
            fill='white'
          />
          <path
            d='M166.176 40.5518L166.021 40.5391L166.008 40.6973L166.163 40.71L166.176 40.5518Z'
            fill='white'
          />
          <path
            d='M166.504 40.6245L166.349 40.6118L166.336 40.7701L166.491 40.7828L166.504 40.6245Z'
            fill='white'
          />
          <path
            d='M166.809 40.7173L166.654 40.7046L166.641 40.8625L166.796 40.8753L166.809 40.7173Z'
            fill='white'
          />
          <path
            d='M167.116 40.7881L166.961 40.7754L166.948 40.9336L167.103 40.9463L167.116 40.7881Z'
            fill='white'
          />
          <path
            d='M164.84 40.5107L164.686 40.498L164.673 40.6563L164.828 40.669L164.84 40.5107Z'
            fill='white'
          />
          <path
            d='M165.127 40.5571L164.971 40.5444L164.958 40.7027L165.114 40.7154L165.127 40.5571Z'
            fill='white'
          />
          <path
            d='M165.409 40.6485L165.254 40.6357L165.241 40.794L165.396 40.8067L165.409 40.6485Z'
            fill='white'
          />
          <path
            d='M165.716 40.7193L165.561 40.7065L165.548 40.8648L165.703 40.8775L165.716 40.7193Z'
            fill='white'
          />
          <path
            d='M166 40.7871L165.845 40.7744L165.832 40.9327L165.987 40.9454L166 40.7871Z'
            fill='white'
          />
          <path
            d='M166.307 40.8585L166.15 40.8457L166.137 41.004L166.293 41.0167L166.307 40.8585Z'
            fill='white'
          />
          <path
            d='M166.612 40.9292L166.456 40.9165L166.443 41.0748L166.599 41.0875L166.612 40.9292Z'
            fill='white'
          />
          <path
            d='M166.898 40.9976L166.743 40.9849L166.73 41.1431L166.885 41.1558L166.898 40.9976Z'
            fill='white'
          />
          <path
            d='M167.182 41.0669L167.027 41.0542L167.014 41.2134L167.169 41.2261L167.182 41.0669Z'
            fill='white'
          />
          <path
            d='M165.014 40.8433L164.859 40.8306L164.846 40.9888L165.001 41.0015L165.014 40.8433Z'
            fill='white'
          />
          <path
            d='M165.298 40.9126L165.143 40.8999L165.13 41.0591L165.285 41.0718L165.298 40.9126Z'
            fill='white'
          />
          <path
            d='M165.562 40.9571L165.406 40.9443L165.393 41.1026L165.549 41.1153L165.562 40.9571Z'
            fill='white'
          />
          <path
            d='M165.847 41.0259L165.692 41.0132L165.679 41.1714L165.834 41.1842L165.847 41.0259Z'
            fill='white'
          />
          <path
            d='M166.131 41.0943L165.976 41.0815L165.963 41.2398L166.118 41.2525L166.131 41.0943Z'
            fill='white'
          />
          <path
            d='M166.416 41.1631L166.261 41.1504L166.248 41.3086L166.403 41.3213L166.416 41.1631Z'
            fill='white'
          />
          <path
            d='M166.702 41.2095L166.547 41.1968L166.534 41.355L166.689 41.3677L166.702 41.2095Z'
            fill='white'
          />
          <path
            d='M166.964 41.2764L166.809 41.2637L166.796 41.4219L166.951 41.4346L166.964 41.2764Z'
            fill='white'
          />
          <path
            d='M167.228 41.3208L167.073 41.3081L167.06 41.4664L167.215 41.4791L167.228 41.3208Z'
            fill='white'
          />
          <path
            d='M165.166 41.1748L165.01 41.1621L164.997 41.3204L165.153 41.3331L165.166 41.1748Z'
            fill='white'
          />
          <path
            d='M165.429 41.2197L165.274 41.207L165.261 41.3653L165.416 41.378L165.429 41.2197Z'
            fill='white'
          />
          <path
            d='M165.692 41.2861L165.537 41.2734L165.524 41.4317L165.679 41.4444L165.692 41.2861Z'
            fill='white'
          />
          <path
            d='M165.956 41.3306L165.801 41.3179L165.788 41.4761L165.943 41.4888L165.956 41.3306Z'
            fill='white'
          />
          <path
            d='M166.22 41.375L166.065 41.3623L166.052 41.5206L166.207 41.5332L166.22 41.375Z'
            fill='white'
          />
          <path
            d='M166.504 41.4438L166.349 41.4312L166.336 41.5894L166.491 41.6021L166.504 41.4438Z'
            fill='white'
          />
          <path
            d='M166.768 41.4893L166.613 41.4766L166.6 41.6348L166.755 41.6475L166.768 41.4893Z'
            fill='white'
          />
          <path
            d='M167.032 41.5322L166.877 41.5195L166.864 41.6778L167.02 41.6905L167.032 41.5322Z'
            fill='white'
          />
          <path
            d='M167.275 41.5747L167.119 41.562L167.106 41.7203L167.262 41.733L167.275 41.5747Z'
            fill='white'
          />
          <path
            d='M165.296 41.4814L165.141 41.4688L165.128 41.627L165.283 41.6397L165.296 41.4814Z'
            fill='white'
          />
          <path
            d='M165.538 41.5239L165.383 41.5112L165.37 41.6695L165.525 41.6822L165.538 41.5239Z'
            fill='white'
          />
          <path
            d='M165.78 41.5669L165.625 41.5542L165.612 41.7125L165.767 41.7252L165.78 41.5669Z'
            fill='white'
          />
          <path
            d='M166.045 41.6109L165.889 41.5981L165.876 41.7564L166.032 41.7691L166.045 41.6109Z'
            fill='white'
          />
          <path
            d='M166.309 41.6553L166.154 41.6426L166.141 41.8008L166.296 41.8135L166.309 41.6553Z'
            fill='white'
          />
          <path
            d='M166.549 41.6978L166.394 41.6851L166.381 41.8433L166.536 41.856L166.549 41.6978Z'
            fill='white'
          />
          <path
            d='M166.814 41.7422L166.659 41.7295L166.646 41.8874L166.802 41.9002L166.814 41.7422Z'
            fill='white'
          />
          <path
            d='M167.057 41.7848L166.901 41.772L166.888 41.9302L167.043 41.9429L167.057 41.7848Z'
            fill='white'
          />
          <path
            d='M167.321 41.8291L167.166 41.8164L167.153 41.9747L167.308 41.9874L167.321 41.8291Z'
            fill='white'
          />
          <path
            d='M165.383 41.7842L165.228 41.7715L165.215 41.93L165.37 41.9428L165.383 41.7842Z'
            fill='white'
          />
          <path
            d='M165.627 41.8042L165.472 41.7915L165.459 41.9501L165.614 41.9628L165.627 41.8042Z'
            fill='white'
          />
          <path
            d='M165.87 41.8467L165.714 41.834L165.701 41.9922L165.857 42.005L165.87 41.8467Z'
            fill='white'
          />
          <path
            d='M166.111 41.8897L165.956 41.877L165.943 42.0352L166.098 42.0479L166.111 41.8897Z'
            fill='white'
          />
          <path
            d='M166.355 41.9097L166.2 41.897L166.187 42.0552L166.342 42.0679L166.355 41.9097Z'
            fill='white'
          />
          <path
            d='M166.597 41.9521L166.442 41.9395L166.429 42.0977L166.584 42.1104L166.597 41.9521Z'
            fill='white'
          />
          <path
            d='M166.861 41.9966L166.706 41.9839L166.693 42.1421L166.848 42.1549L166.861 41.9966Z'
            fill='white'
          />
          <path
            d='M167.103 42.0391L166.948 42.0264L166.935 42.1846L167.09 42.1973L167.103 42.0391Z'
            fill='white'
          />
          <path
            d='M167.324 42.0571L167.169 42.0444L167.156 42.2027L167.312 42.2154L167.324 42.0571Z'
            fill='white'
          />
          <path
            d='M165.451 42.0405L165.296 42.0278L165.283 42.1861L165.438 42.1988L165.451 42.0405Z'
            fill='white'
          />
          <path
            d='M165.671 42.0811L165.516 42.0684L165.503 42.2266L165.658 42.2393L165.671 42.0811Z'
            fill='white'
          />
          <path
            d='M165.915 42.1011L165.76 42.0884L165.747 42.2466L165.902 42.2593L165.915 42.1011Z'
            fill='white'
          />
          <path
            d='M166.157 42.1435L166.002 42.1309L165.989 42.2891L166.144 42.3018L166.157 42.1435Z'
            fill='white'
          />
          <path
            d='M166.401 42.1636L166.246 42.1509L166.233 42.3091L166.388 42.3218L166.401 42.1636Z'
            fill='white'
          />
          <path
            d='M166.643 42.2061L166.488 42.1934L166.474 42.3531L166.63 42.3658L166.643 42.2061Z'
            fill='white'
          />
          <path
            d='M166.887 42.2261L166.731 42.2134L166.718 42.3731L166.874 42.3859L166.887 42.2261Z'
            fill='white'
          />
          <path
            d='M167.106 42.2671L166.95 42.2544L166.937 42.4127L167.093 42.4254L167.106 42.2671Z'
            fill='white'
          />
          <path
            d='M167.35 42.2871L167.195 42.2744L167.182 42.4327L167.337 42.4454L167.35 42.2871Z'
            fill='white'
          />
          <path
            d='M165.498 42.2944L165.343 42.2817L165.33 42.44L165.485 42.4527L165.498 42.2944Z'
            fill='white'
          />
          <path
            d='M165.717 42.3355L165.562 42.3228L165.549 42.481L165.704 42.4937L165.717 42.3355Z'
            fill='white'
          />
          <path
            d='M165.961 42.355L165.805 42.3423L165.792 42.5005L165.947 42.5132L165.961 42.355Z'
            fill='white'
          />
          <path
            d='M166.183 42.3732L166.026 42.3604L166.013 42.5186L166.169 42.5313L166.183 42.3732Z'
            fill='white'
          />
          <path
            d='M166.425 42.416L166.27 42.4033L166.257 42.5616L166.412 42.5743L166.425 42.416Z'
            fill='white'
          />
          <path
            d='M166.668 42.4361L166.513 42.4233L166.5 42.5816L166.655 42.5943L166.668 42.4361Z'
            fill='white'
          />
          <path
            d='M166.888 42.4766L166.733 42.4639L166.72 42.6221L166.875 42.6348L166.888 42.4766Z'
            fill='white'
          />
          <path
            d='M167.11 42.4946L166.955 42.4819L166.942 42.6402L167.097 42.6529L167.11 42.4946Z'
            fill='white'
          />
          <path
            d='M167.354 42.5147L167.199 42.502L167.186 42.6602L167.341 42.6729L167.354 42.5147Z'
            fill='white'
          />
          <path
            d='M165.522 42.5469L165.366 42.5342L165.353 42.6924L165.509 42.7051L165.522 42.5469Z'
            fill='white'
          />
          <path
            d='M165.743 42.565L165.588 42.5522L165.575 42.7105L165.73 42.7232L165.743 42.565Z'
            fill='white'
          />
          <path
            d='M165.985 42.6074L165.83 42.5947L165.817 42.753L165.972 42.7657L165.985 42.6074Z'
            fill='white'
          />
          <path
            d='M166.207 42.6255L166.052 42.6128L166.039 42.771L166.194 42.7837L166.207 42.6255Z'
            fill='white'
          />
          <path
            d='M166.451 42.6455L166.295 42.6328L166.282 42.7911L166.438 42.8038L166.451 42.6455Z'
            fill='white'
          />
          <path
            d='M166.672 42.6636L166.517 42.6509L166.504 42.8091L166.659 42.8218L166.672 42.6636Z'
            fill='white'
          />
          <path
            d='M166.892 42.7046L166.737 42.6919L166.724 42.8502L166.879 42.8629L166.892 42.7046Z'
            fill='white'
          />
          <path
            d='M167.114 42.7227L166.958 42.71L166.945 42.8682L167.101 42.8809L167.114 42.7227Z'
            fill='white'
          />
          <path
            d='M167.357 42.7431L167.203 42.7305L167.19 42.8887L167.345 42.9014L167.357 42.7431Z'
            fill='white'
          />
          <path
            d='M165.547 42.7764L165.392 42.7637L165.379 42.9219L165.534 42.9346L165.547 42.7764Z'
            fill='white'
          />
          <path
            d='M165.769 42.7949L165.614 42.7822L165.601 42.9405L165.756 42.9532L165.769 42.7949Z'
            fill='white'
          />
          <path
            d='M165.989 42.834L165.834 42.8213L165.821 42.9795L165.976 42.9923L165.989 42.834Z'
            fill='white'
          />
          <path
            d='M166.211 42.8521L166.055 42.8394L166.042 42.9976L166.198 43.0103L166.211 42.8521Z'
            fill='white'
          />
          <path
            d='M166.453 42.8721L166.297 42.8594L166.284 43.0176L166.44 43.0303L166.453 42.8721Z'
            fill='white'
          />
          <path
            d='M166.676 42.8901L166.521 42.8774L166.508 43.0357L166.663 43.0484L166.676 42.8901Z'
            fill='white'
          />
          <path
            d='M166.897 42.9082L166.742 42.8955L166.729 43.0538L166.885 43.0665L166.897 42.9082Z'
            fill='white'
          />
          <path
            d='M167.117 42.9506L166.964 42.938L166.951 43.0962L167.106 43.1089L167.117 42.9506Z'
            fill='white'
          />
          <path
            d='M167.339 42.9687L167.184 42.9561L167.171 43.1143L167.327 43.127L167.339 42.9687Z'
            fill='white'
          />
          <path
            d='M165.551 43.0044L165.396 42.9917L165.383 43.15L165.538 43.1626L165.551 43.0044Z'
            fill='white'
          />
          <path
            d='M165.773 43.023L165.618 43.0103L165.605 43.1685L165.76 43.1812L165.773 43.023Z'
            fill='white'
          />
          <path
            d='M165.992 43.0635L165.837 43.0508L165.824 43.209L165.979 43.2217L165.992 43.0635Z'
            fill='white'
          />
          <path
            d='M166.213 43.0816L166.058 43.0688L166.045 43.2271L166.2 43.2398L166.213 43.0816Z'
            fill='white'
          />
          <path
            d='M166.434 43.0996L166.279 43.0869L166.266 43.2452L166.421 43.2579L166.434 43.0996Z'
            fill='white'
          />
          <path
            d='M166.657 43.1182L166.502 43.1055L166.489 43.2637L166.644 43.2764L166.657 43.1182Z'
            fill='white'
          />
          <path
            d='M166.879 43.1363L166.723 43.1235L166.71 43.2818L166.866 43.2945L166.879 43.1363Z'
            fill='white'
          />
          <path
            d='M167.1 43.1542L166.947 43.1416L166.934 43.2999L167.089 43.3125L167.1 43.1542Z'
            fill='white'
          />
          <path
            d='M167.322 43.1723L167.168 43.1597L167.155 43.3179L167.31 43.3306L167.322 43.1723Z'
            fill='white'
          />
          <path
            d='M165.555 43.2324L165.399 43.2197L165.386 43.378L165.542 43.3907L165.555 43.2324Z'
            fill='white'
          />
          <path
            d='M165.776 43.2505L165.621 43.2378L165.608 43.396L165.763 43.4088L165.776 43.2505Z'
            fill='white'
          />
          <path
            d='M165.998 43.2691L165.843 43.2563L165.83 43.4146L165.985 43.4273L165.998 43.2691Z'
            fill='white'
          />
          <path
            d='M166.219 43.2871L166.064 43.2744L166.051 43.4327L166.207 43.4454L166.219 43.2871Z'
            fill='white'
          />
          <path
            d='M166.441 43.3052L166.286 43.2925L166.273 43.4507L166.428 43.4634L166.441 43.3052Z'
            fill='white'
          />
          <path
            d='M166.663 43.3233L166.508 43.3105L166.495 43.4688L166.65 43.4815L166.663 43.3233Z'
            fill='white'
          />
          <path
            d='M166.884 43.3413L166.729 43.3286L166.716 43.4869L166.871 43.4996L166.884 43.3413Z'
            fill='white'
          />
          <path
            d='M167.104 43.3819L166.949 43.3691L166.936 43.5274L167.091 43.5401L167.104 43.3819Z'
            fill='white'
          />
          <path
            d='M167.326 43.3999L167.17 43.3872L167.157 43.5455L167.313 43.5582L167.326 43.3999Z'
            fill='white'
          />
          <path
            d='M165.536 43.4585L165.381 43.4458L165.368 43.6041L165.523 43.6168L165.536 43.4585Z'
            fill='white'
          />
          <path
            d='M165.758 43.4766L165.602 43.4639L165.589 43.6221L165.745 43.6348L165.758 43.4766Z'
            fill='white'
          />
          <path
            d='M165.979 43.4946L165.824 43.4819L165.811 43.6402L165.967 43.6529L165.979 43.4946Z'
            fill='white'
          />
          <path
            d='M166.201 43.5132L166.046 43.5005L166.033 43.6587L166.188 43.6715L166.201 43.5132Z'
            fill='white'
          />
          <path
            d='M166.423 43.5312L166.268 43.5186L166.255 43.6768L166.41 43.6895L166.423 43.5312Z'
            fill='white'
          />
          <path
            d='M166.644 43.5493L166.489 43.5366L166.476 43.6949L166.631 43.7076L166.644 43.5493Z'
            fill='white'
          />
          <path
            d='M166.866 43.5674L166.71 43.5547L166.697 43.7129L166.853 43.7257L166.866 43.5674Z'
            fill='white'
          />
          <path
            d='M167.088 43.5855L166.932 43.5728L166.919 43.731L167.075 43.7437L167.088 43.5855Z'
            fill='white'
          />
          <path
            d='M167.309 43.6035L167.154 43.5908L167.141 43.7491L167.296 43.7618L167.309 43.6035Z'
            fill='white'
          />
          <path
            d='M165.542 43.6636L165.386 43.6509L165.373 43.8091L165.529 43.8218L165.542 43.6636Z'
            fill='white'
          />
          <path
            d='M165.763 43.6821L165.608 43.6694L165.595 43.8277L165.75 43.8404L165.763 43.6821Z'
            fill='white'
          />
          <path
            d='M165.963 43.6983L165.808 43.6855L165.795 43.8438L165.95 43.8565L165.963 43.6983Z'
            fill='white'
          />
          <path
            d='M166.184 43.7163L166.029 43.7036L166.016 43.8619L166.171 43.8746L166.184 43.7163Z'
            fill='white'
          />
          <path
            d='M166.406 43.7344L166.251 43.7217L166.238 43.8799L166.393 43.8926L166.406 43.7344Z'
            fill='white'
          />
          <path
            d='M166.628 43.7529L166.472 43.7402L166.459 43.8985L166.615 43.9112L166.628 43.7529Z'
            fill='white'
          />
          <path
            d='M166.849 43.771L166.694 43.7583L166.681 43.9166L166.836 43.9293L166.849 43.771Z'
            fill='white'
          />
          <path
            d='M167.071 43.7891L166.916 43.7764L166.903 43.9346L167.058 43.9473L167.071 43.7891Z'
            fill='white'
          />
          <path
            d='M167.292 43.8071L167.137 43.7944L167.124 43.9527L167.28 43.9654L167.292 43.8071Z'
            fill='white'
          />
          <path
            d='M165.523 43.8897L165.368 43.877L165.355 44.0352L165.51 44.0479L165.523 43.8897Z'
            fill='white'
          />
          <path
            d='M165.745 43.9077L165.59 43.895L165.577 44.0533L165.732 44.066L165.745 43.9077Z'
            fill='white'
          />
          <path
            d='M165.967 43.9263L165.811 43.9136L165.798 44.0718L165.954 44.0845L165.967 43.9263Z'
            fill='white'
          />
          <path
            d='M166.166 43.9424L166.01 43.9297L165.998 44.0879L166.153 44.1007L166.166 43.9424Z'
            fill='white'
          />
          <path
            d='M166.387 43.9604L166.232 43.9478L166.219 44.106L166.374 44.1187L166.387 43.9604Z'
            fill='white'
          />
          <path
            d='M166.609 43.9785L166.454 43.9658L166.441 44.1241L166.596 44.1368L166.609 43.9785Z'
            fill='white'
          />
          <path
            d='M166.831 43.9971L166.675 43.9844L166.662 44.1426L166.818 44.1553L166.831 43.9971Z'
            fill='white'
          />
          <path
            d='M167.052 44.0152L166.897 44.0024L166.884 44.1607L167.04 44.1734L167.052 44.0152Z'
            fill='white'
          />
          <path
            d='M167.274 44.0332L167.119 44.0205L167.106 44.1788L167.261 44.1915L167.274 44.0332Z'
            fill='white'
          />
          <path
            d='M165.506 44.0938L165.351 44.0811L165.338 44.2393L165.493 44.252L165.506 44.0938Z'
            fill='white'
          />
          <path
            d='M165.728 44.1118L165.573 44.0991L165.56 44.2574L165.715 44.2701L165.728 44.1118Z'
            fill='white'
          />
          <path
            d='M165.95 44.1299L165.794 44.1172L165.781 44.2754L165.937 44.2882L165.95 44.1299Z'
            fill='white'
          />
          <path
            d='M166.149 44.146L165.994 44.1333L165.981 44.2916L166.136 44.3043L166.149 44.146Z'
            fill='white'
          />
          <path
            d='M166.371 44.1645L166.216 44.1519L166.203 44.3101L166.358 44.3228L166.371 44.1645Z'
            fill='white'
          />
          <path
            d='M166.593 44.1826L166.437 44.1699L166.424 44.3282L166.58 44.3409L166.593 44.1826Z'
            fill='white'
          />
          <path
            d='M166.814 44.2007L166.659 44.188L166.646 44.3462L166.801 44.359L166.814 44.2007Z'
            fill='white'
          />
          <path
            d='M167.036 44.2188L166.88 44.2061L166.867 44.3643L167.023 44.377L167.036 44.2188Z'
            fill='white'
          />
          <path
            d='M167.257 44.2368L167.102 44.2241L167.089 44.3824L167.244 44.3951L167.257 44.2368Z'
            fill='white'
          />
          <path
            d='M163.085 44.2364L162.991 44.2743L163.081 44.2817L163.085 44.2364Z'
            fill='white'
          />
          <path
            d='M163.303 44.3001L163.312 44.1869L163.129 44.2403L163.126 44.2856L163.303 44.3001Z'
            fill='white'
          />
          <path
            d='M163.503 44.3165C163.505 44.2629 163.502 44.2093 163.493 44.1564C163.449 44.1528 163.401 44.1717 163.358 44.1682L163.349 44.2814L163.504 44.2941L163.503 44.3165Z'
            fill='white'
          />
          <path
            d='M165.49 44.2974L165.334 44.2847L165.322 44.4429L165.477 44.4556L165.49 44.2974Z'
            fill='white'
          />
          <path
            d='M165.711 44.3154L165.556 44.3027L165.543 44.461L165.698 44.4737L165.711 44.3154Z'
            fill='white'
          />
          <path
            d='M165.933 44.3335L165.778 44.3208L165.765 44.4791L165.92 44.4918L165.933 44.3335Z'
            fill='white'
          />
          <path
            d='M166.132 44.3496L165.977 44.3369L165.964 44.4952L166.119 44.5079L166.132 44.3496Z'
            fill='white'
          />
          <path
            d='M166.354 44.3682L166.199 44.3555L166.186 44.5137L166.341 44.5264L166.354 44.3682Z'
            fill='white'
          />
          <path
            d='M166.576 44.3863L166.42 44.3735L166.407 44.5318L166.563 44.5445L166.576 44.3863Z'
            fill='white'
          />
          <path
            d='M166.797 44.4043L166.642 44.3916L166.629 44.5499L166.784 44.5626L166.797 44.4043Z'
            fill='white'
          />
          <path
            d='M167.019 44.4224L166.864 44.4097L166.851 44.5679L167.006 44.5806L167.019 44.4224Z'
            fill='white'
          />
          <path
            d='M167.24 44.4404L167.085 44.4277L167.072 44.586L167.228 44.5987L167.24 44.4404Z'
            fill='white'
          />
          <path
            d='M162.402 44.4079L162.355 44.4266L162.4 44.4303L162.402 44.4079Z'
            fill='white'
          />
          <path
            d='M162.621 44.449L162.629 44.3584L162.47 44.391L162.466 44.4363L162.621 44.449Z'
            fill='white'
          />
          <path
            d='M162.843 44.4674L162.856 44.3091L162.697 44.3417L162.688 44.4549L162.843 44.4674Z'
            fill='white'
          />
          <path
            d='M163.078 44.3272L162.922 44.3145L162.909 44.4727L163.065 44.4854L163.078 44.3272Z'
            fill='white'
          />
          <path
            d='M163.277 44.3433L163.122 44.3306L163.109 44.4888L163.264 44.5015L163.277 44.3433Z'
            fill='white'
          />
          <path
            d='M163.499 44.3613L163.344 44.3486L163.331 44.5069L163.486 44.5196L163.499 44.3613Z'
            fill='white'
          />
          <path
            d='M163.575 44.5271C163.577 44.5045 163.577 44.5045 163.556 44.48L163.575 44.5271Z'
            fill='white'
          />
          <path
            d='M165.244 44.5726C165.245 44.6048 165.234 44.6363 165.214 44.6614L165.237 44.6632L165.244 44.5726Z'
            fill='white'
          />
          <path
            d='M165.471 44.523L165.316 44.5103L165.303 44.6685L165.458 44.6812L165.471 44.523Z'
            fill='white'
          />
          <path
            d='M165.693 44.541L165.538 44.5283L165.525 44.6866L165.68 44.6993L165.693 44.541Z'
            fill='white'
          />
          <path
            d='M165.915 44.5591L165.759 44.5464L165.746 44.7046L165.902 44.7174L165.915 44.5591Z'
            fill='white'
          />
          <path
            d='M166.114 44.5757L165.959 44.563L165.946 44.7212L166.101 44.734L166.114 44.5757Z'
            fill='white'
          />
          <path
            d='M166.335 44.5937L166.18 44.5811L166.167 44.7393L166.322 44.752L166.335 44.5937Z'
            fill='white'
          />
          <path
            d='M166.557 44.6118L166.402 44.5991L166.389 44.7574L166.544 44.7701L166.557 44.6118Z'
            fill='white'
          />
          <path
            d='M166.779 44.6299L166.624 44.6172L166.611 44.7754L166.766 44.7882L166.779 44.6299Z'
            fill='white'
          />
          <path
            d='M167 44.6485L166.845 44.6357L166.832 44.794L166.988 44.8067L167 44.6485Z'
            fill='white'
          />
          <path
            d='M167.222 44.6665L167.067 44.6538L167.054 44.8121L167.209 44.8248L167.222 44.6665Z'
            fill='white'
          />
          <path
            d='M161.962 44.6001L161.968 44.5321L161.787 44.5629L161.785 44.5855L161.962 44.6001Z'
            fill='white'
          />
          <path
            d='M162.161 44.6163L162.174 44.458L162.014 44.5133L162.006 44.6039L162.161 44.6163Z'
            fill='white'
          />
          <path
            d='M162.396 44.4761L162.241 44.4634L162.228 44.6216L162.383 44.6343L162.396 44.4761Z'
            fill='white'
          />
          <path
            d='M162.618 44.4942L162.462 44.4814L162.449 44.6397L162.605 44.6524L162.618 44.4942Z'
            fill='white'
          />
          <path
            d='M162.84 44.5127L162.684 44.5L162.671 44.6583L162.827 44.671L162.84 44.5127Z'
            fill='white'
          />
          <path
            d='M163.061 44.5308L162.906 44.5181L162.893 44.6763L163.048 44.689L163.061 44.5308Z'
            fill='white'
          />
          <path
            d='M163.26 44.5469L163.105 44.5342L163.093 44.6924L163.247 44.7051L163.26 44.5469Z'
            fill='white'
          />
          <path
            d='M163.482 44.565L163.327 44.5522L163.314 44.7105L163.469 44.7232L163.482 44.565Z'
            fill='white'
          />
          <path
            d='M163.536 44.7291L163.647 44.7382C163.628 44.691 163.612 44.6213 163.593 44.5745L163.549 44.5708L163.536 44.7291Z'
            fill='white'
          />
          <path
            d='M165.233 44.7085C165.207 44.752 165.179 44.8182 165.154 44.8614L165.22 44.8668L165.233 44.7085Z'
            fill='white'
          />
          <path
            d='M165.455 44.7266L165.299 44.7139L165.286 44.8721L165.442 44.8848L165.455 44.7266Z'
            fill='white'
          />
          <path
            d='M165.676 44.7446L165.521 44.7319L165.508 44.8902L165.663 44.9029L165.676 44.7446Z'
            fill='white'
          />
          <path
            d='M165.898 44.7627L165.743 44.75L165.73 44.9083L165.885 44.921L165.898 44.7627Z'
            fill='white'
          />
          <path
            d='M166.097 44.7793L165.942 44.7666L165.929 44.9249L166.084 44.9376L166.097 44.7793Z'
            fill='white'
          />
          <path
            d='M166.319 44.7974L166.164 44.7847L166.151 44.9429L166.306 44.9556L166.319 44.7974Z'
            fill='white'
          />
          <path
            d='M166.541 44.8154L166.385 44.8027L166.372 44.961L166.528 44.9737L166.541 44.8154Z'
            fill='white'
          />
          <path
            d='M166.762 44.8335L166.607 44.8208L166.594 44.9791L166.749 44.9918L166.762 44.8335Z'
            fill='white'
          />
          <path
            d='M166.984 44.8521L166.829 44.8394L166.816 44.9976L166.971 45.0103L166.984 44.8521Z'
            fill='white'
          />
          <path
            d='M167.05 44.8574L167.037 45.0157L167.192 45.0284C167.196 44.9831 167.224 44.917 167.228 44.872L167.05 44.8574Z'
            fill='white'
          />
          <path
            d='M161.736 44.6266L161.625 44.6176C161.622 44.6172 161.619 44.6175 161.616 44.6184C161.614 44.6193 161.611 44.6208 161.609 44.6227C161.606 44.6247 161.605 44.627 161.603 44.6297C161.602 44.6324 161.601 44.6354 161.601 44.6384C161.597 44.6837 161.616 44.7308 161.612 44.7758L161.745 44.7867L161.736 44.6266Z'
            fill='white'
          />
          <path
            d='M161.936 44.6431L161.781 44.6304L161.768 44.7886L161.923 44.8013L161.936 44.6431Z'
            fill='white'
          />
          <path
            d='M162.158 44.6611L162.003 44.6484L161.99 44.8067L162.145 44.8194L162.158 44.6611Z'
            fill='white'
          />
          <path
            d='M162.38 44.6797L162.224 44.667L162.211 44.8252L162.367 44.838L162.38 44.6797Z'
            fill='white'
          />
          <path
            d='M162.601 44.6973L162.446 44.6846L162.433 44.8428L162.588 44.8555L162.601 44.6973Z'
            fill='white'
          />
          <path
            d='M162.823 44.7158L162.667 44.7031L162.655 44.8614L162.81 44.8741L162.823 44.7158Z'
            fill='white'
          />
          <path
            d='M163.044 44.7339L162.889 44.7212L162.876 44.8794L163.031 44.8922L163.044 44.7339Z'
            fill='white'
          />
          <path
            d='M163.244 44.75L163.089 44.7373L163.076 44.8956L163.231 44.9083L163.244 44.75Z'
            fill='white'
          />
          <path
            d='M163.466 44.7681L163.31 44.7554L163.297 44.9136L163.453 44.9263L163.466 44.7681Z'
            fill='white'
          />
          <path
            d='M163.517 44.9548L163.672 44.9675L163.683 44.8319C163.685 44.8093 163.663 44.8075 163.665 44.7848L163.532 44.7739L163.517 44.9548Z'
            fill='white'
          />
          <path
            d='M163.783 44.9766L163.742 44.9277L163.739 44.973L163.783 44.9766Z'
            fill='white'
          />
          <path
            d='M165.202 45.0924L165.215 44.9342L165.125 44.9268C165.098 44.9822 165.064 45.0332 165.022 45.0777L165.202 45.0924Z'
            fill='white'
          />
          <path
            d='M165.438 44.9297L165.283 44.917L165.27 45.0752L165.425 45.088L165.438 44.9297Z'
            fill='white'
          />
          <path
            d='M165.659 44.9482L165.504 44.9355L165.491 45.0938L165.646 45.1065L165.659 44.9482Z'
            fill='white'
          />
          <path
            d='M165.881 44.9663L165.726 44.9536L165.713 45.1119L165.868 45.1246L165.881 44.9663Z'
            fill='white'
          />
          <path
            d='M166.081 44.9824L165.925 44.9697L165.912 45.128L166.068 45.1407L166.081 44.9824Z'
            fill='white'
          />
          <path
            d='M166.302 45.0005L166.147 44.9878L166.134 45.146L166.289 45.1587L166.302 45.0005Z'
            fill='white'
          />
          <path
            d='M166.524 45.0191L166.369 45.0063L166.356 45.1646L166.511 45.1773L166.524 45.0191Z'
            fill='white'
          />
          <path
            d='M166.745 45.0371L166.59 45.0244L166.577 45.1827L166.732 45.1954L166.745 45.0371Z'
            fill='white'
          />
          <path
            d='M166.967 45.0552L166.812 45.0425L166.799 45.2007L166.954 45.2135L166.967 45.0552Z'
            fill='white'
          />
          <path
            d='M167.019 45.2413L167.129 45.2503C167.152 45.2012 167.164 45.148 167.165 45.0939L167.032 45.083L167.019 45.2413Z'
            fill='white'
          />
          <path
            d='M161.719 44.8528L161.608 44.8438C161.627 44.8909 161.621 44.9588 161.639 45.0056L161.729 45.013L161.719 44.8528Z'
            fill='white'
          />
          <path
            d='M161.918 44.8692L161.762 44.8564L161.749 45.0147L161.905 45.0274L161.918 44.8692Z'
            fill='white'
          />
          <path
            d='M162.139 44.8872L161.984 44.8745L161.971 45.0328L162.126 45.0455L162.139 44.8872Z'
            fill='white'
          />
          <path
            d='M162.361 44.9053L162.206 44.8926L162.193 45.0508L162.348 45.0635L162.361 44.9053Z'
            fill='white'
          />
          <path
            d='M162.583 44.9234L162.427 44.9106L162.414 45.0689L162.57 45.0816L162.583 44.9234Z'
            fill='white'
          />
          <path
            d='M162.804 44.9414L162.649 44.9287L162.636 45.087L162.791 45.0997L162.804 44.9414Z'
            fill='white'
          />
          <path
            d='M163.026 44.96L162.871 44.9473L162.858 45.1055L163.013 45.1182L163.026 44.96Z'
            fill='white'
          />
          <path
            d='M163.225 44.9761L163.07 44.9634L163.057 45.1216L163.212 45.1343L163.225 44.9761Z'
            fill='white'
          />
          <path
            d='M163.447 44.9942L163.292 44.9814L163.279 45.1397L163.434 45.1524L163.447 44.9942Z'
            fill='white'
          />
          <path
            d='M163.668 45.0122L163.513 44.9995L163.5 45.1578L163.655 45.1705L163.668 45.0122Z'
            fill='white'
          />
          <path
            d='M163.722 45.1764L163.877 45.1891L163.886 45.0759L163.826 45.0025L163.736 44.9951L163.721 45.1763L163.722 45.1764Z'
            fill='white'
          />
          <path
            d='M164.077 45.2053L163.949 45.1265L163.944 45.1944L164.077 45.2053Z'
            fill='white'
          />
          <path
            d='M164.963 45.2785L164.976 45.1196C164.926 45.1609 164.871 45.1947 164.812 45.2202L164.808 45.2655L164.963 45.2785Z'
            fill='white'
          />
          <path
            d='M165.198 45.1377L165.043 45.125L165.03 45.2833L165.185 45.296L165.198 45.1377Z'
            fill='white'
          />
          <path
            d='M165.419 45.1558L165.264 45.1431L165.251 45.3013L165.406 45.314L165.419 45.1558Z'
            fill='white'
          />
          <path
            d='M165.641 45.1738L165.486 45.1611L165.473 45.3194L165.628 45.3321L165.641 45.1738Z'
            fill='white'
          />
          <path
            d='M165.863 45.1919L165.708 45.1792L165.695 45.3375L165.85 45.3502L165.863 45.1919Z'
            fill='white'
          />
          <path
            d='M166.062 45.2085L165.907 45.1958L165.894 45.3541L166.049 45.3668L166.062 45.2085Z'
            fill='white'
          />
          <path
            d='M166.284 45.2266L166.129 45.2139L166.116 45.3721L166.271 45.3848L166.284 45.2266Z'
            fill='white'
          />
          <path
            d='M166.505 45.2446L166.35 45.2319L166.337 45.3902L166.492 45.4029L166.505 45.2446Z'
            fill='white'
          />
          <path
            d='M166.727 45.2627L166.572 45.25L166.559 45.4083L166.714 45.421L166.727 45.2627Z'
            fill='white'
          />
          <path
            d='M166.949 45.2808L166.793 45.2681L166.78 45.4263L166.936 45.439L166.949 45.2808Z'
            fill='white'
          />
          <path
            d='M167.002 45.4454L167.068 45.4508C167.094 45.4073 167.1 45.3394 167.125 45.2962L167.015 45.2871L167.002 45.4454Z'
            fill='white'
          />
          <path
            d='M161.701 45.0562L161.635 45.0508C161.653 45.0979 161.648 45.1659 161.666 45.2127L161.688 45.2145L161.701 45.0562Z'
            fill='white'
          />
          <path
            d='M161.901 45.0728L161.746 45.0601L161.733 45.2183L161.888 45.231L161.901 45.0728Z'
            fill='white'
          />
          <path
            d='M162.123 45.0908L161.968 45.0781L161.955 45.2364L162.11 45.2491L162.123 45.0908Z'
            fill='white'
          />
          <path
            d='M162.344 45.1089L162.189 45.0962L162.176 45.2544L162.332 45.2672L162.344 45.1089Z'
            fill='white'
          />
          <path
            d='M162.566 45.127L162.411 45.1143L162.398 45.2725L162.553 45.2852L162.566 45.127Z'
            fill='white'
          />
          <path
            d='M162.788 45.145L162.632 45.1323L162.619 45.2906L162.775 45.3033L162.788 45.145Z'
            fill='white'
          />
          <path
            d='M163.009 45.1636L162.854 45.1509L162.841 45.3091L162.996 45.3218L163.009 45.1636Z'
            fill='white'
          />
          <path
            d='M163.209 45.1797L163.054 45.167L163.041 45.3252L163.196 45.3379L163.209 45.1797Z'
            fill='white'
          />
          <path
            d='M163.43 45.1978L163.275 45.1851L163.262 45.3433L163.417 45.356L163.43 45.1978Z'
            fill='white'
          />
          <path
            d='M163.652 45.2158L163.497 45.2031L163.484 45.3614L163.639 45.3741L163.652 45.2158Z'
            fill='white'
          />
          <path
            d='M163.873 45.2339L163.719 45.2212L163.706 45.3794L163.861 45.3921L163.873 45.2339Z'
            fill='white'
          />
          <path
            d='M164.095 45.2525L163.94 45.2397L163.927 45.398L164.082 45.4107L164.095 45.2525Z'
            fill='white'
          />
          <path
            d='M164.149 45.4161L164.304 45.4288L164.317 45.2705L164.162 45.2578L164.149 45.4161Z'
            fill='white'
          />
          <path
            d='M164.525 45.4472L164.538 45.2889L164.361 45.2744L164.348 45.4327L164.525 45.4472Z'
            fill='white'
          />
          <path
            d='M164.738 45.3047L164.583 45.292L164.57 45.4502L164.725 45.463L164.738 45.3047Z'
            fill='white'
          />
          <path
            d='M164.959 45.3232L164.804 45.3105L164.791 45.4688L164.946 45.4815L164.959 45.3232Z'
            fill='white'
          />
          <path
            d='M165.181 45.3413L165.026 45.3286L165.013 45.4869L165.168 45.4996L165.181 45.3413Z'
            fill='white'
          />
          <path
            d='M165.403 45.3594L165.248 45.3467L165.235 45.5049L165.39 45.5176L165.403 45.3594Z'
            fill='white'
          />
          <path
            d='M165.624 45.3774L165.469 45.3647L165.456 45.523L165.611 45.5357L165.624 45.3774Z'
            fill='white'
          />
          <path
            d='M165.846 45.3955L165.691 45.3828L165.678 45.5411L165.833 45.5538L165.846 45.3955Z'
            fill='white'
          />
          <path
            d='M166.045 45.4121L165.89 45.3994L165.877 45.5577L166.032 45.5704L166.045 45.4121Z'
            fill='white'
          />
          <path
            d='M166.267 45.4302L166.112 45.4175L166.099 45.5757L166.254 45.5884L166.267 45.4302Z'
            fill='white'
          />
          <path
            d='M166.489 45.4483L166.333 45.4355L166.321 45.5938L166.476 45.6065L166.489 45.4483Z'
            fill='white'
          />
          <path
            d='M166.71 45.4663L166.555 45.4536L166.542 45.6119L166.697 45.6246L166.71 45.4663Z'
            fill='white'
          />
          <path
            d='M166.932 45.4844L166.777 45.4717L166.764 45.6299L166.919 45.6426L166.932 45.4844Z'
            fill='white'
          />
          <path
            d='M166.985 45.6485C167.014 45.6012 167.034 45.5487 167.043 45.4939L166.998 45.4902L166.985 45.6485Z'
            fill='white'
          />
          <path
            d='M161.884 45.2759L161.729 45.2632L161.716 45.4214L161.871 45.4342L161.884 45.2759Z'
            fill='white'
          />
          <path
            d='M162.106 45.2944L161.951 45.2817L161.938 45.44L162.093 45.4527L162.106 45.2944Z'
            fill='white'
          />
          <path
            d='M162.328 45.3125L162.172 45.2998L162.159 45.4581L162.315 45.4708L162.328 45.3125Z'
            fill='white'
          />
          <path
            d='M162.549 45.3306L162.394 45.3179L162.381 45.4761L162.536 45.4888L162.549 45.3306Z'
            fill='white'
          />
          <path
            d='M162.771 45.3487L162.616 45.3359L162.603 45.4942L162.758 45.5069L162.771 45.3487Z'
            fill='white'
          />
          <path
            d='M162.993 45.3667L162.837 45.354L162.824 45.5123L162.98 45.525L162.993 45.3667Z'
            fill='white'
          />
          <path
            d='M163.192 45.3833L163.037 45.3706L163.024 45.5289L163.179 45.5416L163.192 45.3833Z'
            fill='white'
          />
          <path
            d='M163.414 45.4014L163.259 45.3887L163.246 45.5469L163.401 45.5596L163.414 45.4014Z'
            fill='white'
          />
          <path
            d='M163.635 45.4195L163.48 45.4067L163.467 45.565L163.622 45.5777L163.635 45.4195Z'
            fill='white'
          />
          <path
            d='M163.857 45.4375L163.702 45.4248L163.689 45.5831L163.844 45.5957L163.857 45.4375Z'
            fill='white'
          />
          <path
            d='M164.079 45.4561L163.923 45.4434L163.91 45.6016L164.066 45.6143L164.079 45.4561Z'
            fill='white'
          />
          <path
            d='M164.3 45.4741L164.145 45.4614L164.132 45.6197L164.287 45.6324L164.3 45.4741Z'
            fill='white'
          />
          <path
            d='M164.499 45.4902L164.344 45.4775L164.332 45.6358L164.486 45.6485L164.499 45.4902Z'
            fill='white'
          />
          <path
            d='M164.721 45.5083L164.566 45.4956L164.553 45.6539L164.708 45.6666L164.721 45.5083Z'
            fill='white'
          />
          <path
            d='M164.943 45.5269L164.788 45.5142L164.775 45.6724L164.93 45.6851L164.943 45.5269Z'
            fill='white'
          />
          <path
            d='M165.165 45.5449L165.009 45.5322L164.996 45.6905L165.152 45.7032L165.165 45.5449Z'
            fill='white'
          />
          <path
            d='M165.386 45.563L165.231 45.5503L165.218 45.7085L165.373 45.7213L165.386 45.563Z'
            fill='white'
          />
          <path
            d='M165.608 45.581L165.453 45.5684L165.44 45.7266L165.595 45.7393L165.608 45.581Z'
            fill='white'
          />
          <path
            d='M165.829 45.5991L165.674 45.5864L165.661 45.7447L165.816 45.7574L165.829 45.5991Z'
            fill='white'
          />
          <path
            d='M166.029 45.6157L165.874 45.603L165.861 45.7613L166.016 45.774L166.029 45.6157Z'
            fill='white'
          />
          <path
            d='M166.25 45.6338L166.095 45.6211L166.082 45.7793L166.237 45.792L166.25 45.6338Z'
            fill='white'
          />
          <path
            d='M166.472 45.6519L166.317 45.6392L166.304 45.7974L166.459 45.8101L166.472 45.6519Z'
            fill='white'
          />
          <path
            d='M166.694 45.6699L166.538 45.6572L166.525 45.8155L166.681 45.8282L166.694 45.6699Z'
            fill='white'
          />
          <path
            d='M166.76 45.6756L166.747 45.8338L166.88 45.8447C166.882 45.8221 166.906 45.8013 166.908 45.7786L166.916 45.688L166.76 45.6756Z'
            fill='white'
          />
          <path
            d='M161.888 45.5044L161.733 45.4917C161.752 45.5388 161.768 45.6086 161.786 45.6554L161.876 45.6627L161.888 45.5044Z'
            fill='white'
          />
          <path
            d='M162.087 45.521L161.932 45.5083L161.919 45.6666L162.074 45.6792L162.087 45.521Z'
            fill='white'
          />
          <path
            d='M162.309 45.5391L162.154 45.5264L162.141 45.6846L162.296 45.6973L162.309 45.5391Z'
            fill='white'
          />
          <path
            d='M162.531 45.5571L162.375 45.5444L162.363 45.7027L162.518 45.7154L162.531 45.5571Z'
            fill='white'
          />
          <path
            d='M162.752 45.5752L162.597 45.5625L162.584 45.7208L162.739 45.7335L162.752 45.5752Z'
            fill='white'
          />
          <path
            d='M162.974 45.5933L162.819 45.5806L162.806 45.7388L162.961 45.7515L162.974 45.5933Z'
            fill='white'
          />
          <path
            d='M163.173 45.6099L163.018 45.5972L163.005 45.7554L163.16 45.7681L163.173 45.6099Z'
            fill='white'
          />
          <path
            d='M163.395 45.6279L163.24 45.6152L163.227 45.7735L163.382 45.7862L163.395 45.6279Z'
            fill='white'
          />
          <path
            d='M163.617 45.646L163.461 45.6333L163.448 45.7916L163.604 45.8043L163.617 45.646Z'
            fill='white'
          />
          <path
            d='M163.838 45.6641L163.683 45.6514L163.67 45.8096L163.825 45.8223L163.838 45.6641Z'
            fill='white'
          />
          <path
            d='M164.06 45.6821L163.905 45.6694L163.892 45.8277L164.047 45.8404L164.06 45.6821Z'
            fill='white'
          />
          <path
            d='M164.281 45.7007L164.126 45.688L164.114 45.8462L164.268 45.8589L164.281 45.7007Z'
            fill='white'
          />
          <path
            d='M164.481 45.7168L164.326 45.7041L164.313 45.8624L164.468 45.875L164.481 45.7168Z'
            fill='white'
          />
          <path
            d='M164.703 45.7349L164.547 45.7222L164.534 45.8804L164.69 45.8931L164.703 45.7349Z'
            fill='white'
          />
          <path
            d='M164.924 45.7529L164.769 45.7402L164.756 45.8985L164.911 45.9112L164.924 45.7529Z'
            fill='white'
          />
          <path
            d='M165.146 45.7715L164.991 45.7588L164.978 45.917L165.133 45.9298L165.146 45.7715Z'
            fill='white'
          />
          <path
            d='M165.367 45.7896L165.212 45.7769L165.199 45.9351L165.354 45.9478L165.367 45.7896Z'
            fill='white'
          />
          <path
            d='M165.589 45.8076L165.434 45.7949L165.421 45.9532L165.576 45.9659L165.589 45.8076Z'
            fill='white'
          />
          <path
            d='M165.811 45.8257L165.656 45.813L165.643 45.9712L165.798 45.984L165.811 45.8257Z'
            fill='white'
          />
          <path
            d='M166.01 45.8418L165.855 45.8291L165.842 45.9874L165.997 46.0001L166.01 45.8418Z'
            fill='white'
          />
          <path
            d='M166.232 45.8603L166.077 45.8477L166.064 46.0059L166.219 46.0186L166.232 45.8603Z'
            fill='white'
          />
          <path
            d='M166.454 45.8784L166.298 45.8657L166.285 46.024L166.441 46.0367L166.454 45.8784Z'
            fill='white'
          />
          <path
            d='M166.675 45.8965L166.52 45.8838L166.507 46.042L166.662 46.0548L166.675 45.8965Z'
            fill='white'
          />
          <path
            d='M166.729 46.0601C166.777 46.0165 166.818 45.9662 166.852 45.9109L166.742 45.9019L166.729 46.0601Z'
            fill='white'
          />
          <path
            d='M161.805 45.7021C161.823 45.7493 161.843 45.7737 161.862 45.8208L161.871 45.7076L161.805 45.7021Z'
            fill='white'
          />
          <path
            d='M162.071 45.7241L161.916 45.7114L161.903 45.8697L162.058 45.8824L162.071 45.7241Z'
            fill='white'
          />
          <path
            d='M162.292 45.7422L162.137 45.7295L162.124 45.8877L162.28 45.9005L162.292 45.7422Z'
            fill='white'
          />
          <path
            d='M162.514 45.7603L162.359 45.7476L162.346 45.9058L162.501 45.9185L162.514 45.7603Z'
            fill='white'
          />
          <path
            d='M162.736 45.7783L162.581 45.7656L162.568 45.9239L162.723 45.9366L162.736 45.7783Z'
            fill='white'
          />
          <path
            d='M162.957 45.7964L162.802 45.7837L162.789 45.9419L162.944 45.9547L162.957 45.7964Z'
            fill='white'
          />
          <path
            d='M163.157 45.813L163.002 45.8003L162.989 45.9585L163.144 45.9712L163.157 45.813Z'
            fill='white'
          />
          <path
            d='M163.378 45.8311L163.223 45.8184L163.21 45.9766L163.365 45.9893L163.378 45.8311Z'
            fill='white'
          />
          <path
            d='M163.6 45.8491L163.445 45.8364L163.432 45.9947L163.587 46.0074L163.6 45.8491Z'
            fill='white'
          />
          <path
            d='M163.821 45.8672L163.667 45.8545L163.654 46.0127L163.809 46.0254L163.821 45.8672Z'
            fill='white'
          />
          <path
            d='M164.043 45.8858L163.888 45.873L163.875 46.0313L164.03 46.044L164.043 45.8858Z'
            fill='white'
          />
          <path
            d='M164.265 45.9038L164.11 45.8911L164.097 46.0494L164.252 46.0621L164.265 45.9038Z'
            fill='white'
          />
          <path
            d='M164.464 45.9199L164.309 45.9072L164.296 46.0655L164.451 46.0782L164.464 45.9199Z'
            fill='white'
          />
          <path
            d='M164.686 45.938L164.531 45.9253L164.518 46.0835L164.673 46.0963L164.686 45.938Z'
            fill='white'
          />
          <path
            d='M164.908 45.9565L164.753 45.9438L164.74 46.1021L164.895 46.1148L164.908 45.9565Z'
            fill='white'
          />
          <path
            d='M165.129 45.9746L164.974 45.9619L164.961 46.1202L165.116 46.1329L165.129 45.9746Z'
            fill='white'
          />
          <path
            d='M165.351 45.9927L165.196 45.98L165.183 46.1382L165.338 46.151L165.351 45.9927Z'
            fill='white'
          />
          <path
            d='M165.572 46.0107L165.417 45.998L165.405 46.1563L165.559 46.169L165.572 46.0107Z'
            fill='white'
          />
          <path
            d='M165.794 46.0288L165.639 46.0161L165.626 46.1744L165.781 46.1871L165.794 46.0288Z'
            fill='white'
          />
          <path
            d='M165.994 46.0454L165.838 46.0327L165.825 46.191L165.981 46.2037L165.994 46.0454Z'
            fill='white'
          />
          <path
            d='M166.215 46.0635L166.06 46.0508L166.047 46.209L166.202 46.2217L166.215 46.0635Z'
            fill='white'
          />
          <path
            d='M166.437 46.0816L166.282 46.0688L166.269 46.2271L166.424 46.2398L166.437 46.0816Z'
            fill='white'
          />
          <path
            d='M166.503 46.0872L166.49 46.2455L166.623 46.2564C166.647 46.2355 166.649 46.2129 166.671 46.2147L166.68 46.1014L166.503 46.0872Z'
            fill='white'
          />
          <path
            d='M162.054 45.9277L161.899 45.915C161.939 45.9639 161.956 46.0337 161.997 46.0824L162.041 46.086L162.054 45.9277Z'
            fill='white'
          />
          <path
            d='M162.276 45.9458L162.121 45.9331L162.108 46.0923L162.263 46.105L162.276 45.9458Z'
            fill='white'
          />
          <path
            d='M162.497 45.9639L162.342 45.9512L162.329 46.1103L162.484 46.123L162.497 45.9639Z'
            fill='white'
          />
          <path
            d='M162.719 45.982L162.564 45.9692L162.551 46.1284L162.706 46.1411L162.719 45.982Z'
            fill='white'
          />
          <path
            d='M162.941 46.0005L162.785 45.9878L162.772 46.147L162.928 46.1597L162.941 46.0005Z'
            fill='white'
          />
          <path
            d='M163.14 46.0166L162.985 46.0039L162.972 46.1631L163.127 46.1758L163.14 46.0166Z'
            fill='white'
          />
          <path
            d='M163.362 46.0347L163.207 46.022L163.194 46.1811L163.349 46.1938L163.362 46.0347Z'
            fill='white'
          />
          <path
            d='M163.583 46.0528L163.428 46.04L163.415 46.1992L163.57 46.2119L163.583 46.0528Z'
            fill='white'
          />
          <path
            d='M163.805 46.0708L163.65 46.0581L163.637 46.2173L163.792 46.23L163.805 46.0708Z'
            fill='white'
          />
          <path
            d='M164.027 46.0889L163.871 46.0762L163.858 46.2353L164.014 46.248L164.027 46.0889Z'
            fill='white'
          />
          <path
            d='M164.248 46.1074L164.093 46.0947L164.08 46.2539L164.235 46.2666L164.248 46.1074Z'
            fill='white'
          />
          <path
            d='M164.447 46.1235L164.292 46.1108L164.279 46.27L164.434 46.2827L164.447 46.1235Z'
            fill='white'
          />
          <path
            d='M164.669 46.1416L164.514 46.1289L164.501 46.2881L164.656 46.3008L164.669 46.1416Z'
            fill='white'
          />
          <path
            d='M164.891 46.1597L164.736 46.147L164.723 46.3061L164.878 46.3188L164.891 46.1597Z'
            fill='white'
          />
          <path
            d='M165.113 46.1782L164.958 46.1655L164.944 46.3247L165.1 46.3374L165.113 46.1782Z'
            fill='white'
          />
          <path
            d='M165.334 46.1963L165.179 46.1836L165.166 46.3428L165.321 46.3555L165.334 46.1963Z'
            fill='white'
          />
          <path
            d='M165.556 46.2144L165.401 46.2017L165.388 46.3608L165.543 46.3735L165.556 46.2144Z'
            fill='white'
          />
          <path
            d='M165.778 46.2324L165.622 46.2197L165.609 46.3789L165.765 46.3916L165.778 46.2324Z'
            fill='white'
          />
          <path
            d='M165.977 46.249L165.822 46.2363L165.808 46.3955L165.964 46.4082L165.977 46.249Z'
            fill='white'
          />
          <path
            d='M166.198 46.2671L166.043 46.2544L166.03 46.4136L166.185 46.4262L166.198 46.2671Z'
            fill='white'
          />
          <path
            d='M166.42 46.2852L166.265 46.2725L166.252 46.4316L166.407 46.4443L166.42 46.2852Z'
            fill='white'
          />
          <path
            d='M166.475 46.4262C166.503 46.3863 166.537 46.3506 166.575 46.3204L166.485 46.313L166.475 46.4262Z'
            fill='white'
          />
          <path
            d='M162.259 46.1494L162.104 46.1367L162.097 46.2273L162.158 46.3007L162.247 46.3081L162.259 46.1494Z'
            fill='white'
          />
          <path
            d='M162.481 46.1675L162.325 46.1548L162.312 46.313L162.468 46.3258L162.481 46.1675Z'
            fill='white'
          />
          <path
            d='M162.702 46.1856L162.547 46.1729L162.534 46.3311L162.689 46.3438L162.702 46.1856Z'
            fill='white'
          />
          <path
            d='M162.924 46.2036L162.769 46.1909L162.756 46.3492L162.911 46.3619L162.924 46.2036Z'
            fill='white'
          />
          <path
            d='M163.123 46.2202L162.969 46.2075L162.956 46.3658L163.111 46.3785L163.123 46.2202Z'
            fill='white'
          />
          <path
            d='M163.345 46.2383L163.19 46.2256L163.177 46.3838L163.332 46.3966L163.345 46.2383Z'
            fill='white'
          />
          <path
            d='M163.567 46.2564L163.411 46.2437L163.398 46.4019L163.554 46.4146L163.567 46.2564Z'
            fill='white'
          />
          <path
            d='M163.788 46.2744L163.633 46.2617L163.62 46.42L163.775 46.4327L163.788 46.2744Z'
            fill='white'
          />
          <path
            d='M164.01 46.2925L163.855 46.2798L163.842 46.438L163.997 46.4508L164.01 46.2925Z'
            fill='white'
          />
          <path
            d='M164.232 46.311L164.077 46.2983L164.064 46.4566L164.219 46.4693L164.232 46.311Z'
            fill='white'
          />
          <path
            d='M164.431 46.3271L164.276 46.3145L164.263 46.4727L164.418 46.4854L164.431 46.3271Z'
            fill='white'
          />
          <path
            d='M164.653 46.3452L164.498 46.3325L164.485 46.4908L164.64 46.5035L164.653 46.3452Z'
            fill='white'
          />
          <path
            d='M164.874 46.3633L164.719 46.3506L164.706 46.5088L164.861 46.5215L164.874 46.3633Z'
            fill='white'
          />
          <path
            d='M165.096 46.3819L164.941 46.3691L164.928 46.5274L165.083 46.5401L165.096 46.3819Z'
            fill='white'
          />
          <path
            d='M165.318 46.3999L165.162 46.3872L165.149 46.5455L165.305 46.5582L165.318 46.3999Z'
            fill='white'
          />
          <path
            d='M165.539 46.418L165.384 46.4053L165.371 46.5635L165.526 46.5762L165.539 46.418Z'
            fill='white'
          />
          <path
            d='M165.761 46.4361L165.606 46.4233L165.593 46.5816L165.748 46.5943L165.761 46.4361Z'
            fill='white'
          />
          <path
            d='M165.96 46.4527L165.805 46.4399L165.792 46.5982L165.947 46.6109L165.96 46.4527Z'
            fill='white'
          />
          <path
            d='M166.182 46.4707L166.027 46.458L166.014 46.6163L166.169 46.629L166.182 46.4707Z'
            fill='white'
          />
          <path
            d='M166.235 46.6343L166.404 46.4888L166.248 46.4761L166.235 46.6343Z'
            fill='white'
          />
          <path
            d='M162.196 46.3716L162.237 46.4205L162.24 46.3752L162.196 46.3716Z'
            fill='white'
          />
          <path
            d='M162.462 46.3936L162.307 46.3809L162.298 46.4944L162.359 46.5678L162.448 46.5752L162.462 46.3936Z'
            fill='white'
          />
          <path
            d='M162.684 46.4116L162.529 46.3989L162.516 46.5572L162.671 46.5699L162.684 46.4116Z'
            fill='white'
          />
          <path
            d='M162.906 46.4297L162.75 46.417L162.737 46.5752L162.893 46.588L162.906 46.4297Z'
            fill='white'
          />
          <path
            d='M163.105 46.4458L162.95 46.4331L162.937 46.5914L163.092 46.6041L163.105 46.4458Z'
            fill='white'
          />
          <path
            d='M163.327 46.4644L163.171 46.4517L163.158 46.6099L163.314 46.6226L163.327 46.4644Z'
            fill='white'
          />
          <path
            d='M163.548 46.4824L163.393 46.4697L163.38 46.628L163.535 46.6407L163.548 46.4824Z'
            fill='white'
          />
          <path
            d='M163.77 46.5005L163.615 46.4878L163.602 46.646L163.757 46.6587L163.77 46.5005Z'
            fill='white'
          />
          <path
            d='M163.991 46.5186L163.836 46.5059L163.823 46.6641L163.978 46.6768L163.991 46.5186Z'
            fill='white'
          />
          <path
            d='M164.213 46.5366L164.058 46.5239L164.045 46.6822L164.2 46.6949L164.213 46.5366Z'
            fill='white'
          />
          <path
            d='M164.412 46.5532L164.257 46.5405L164.244 46.6988L164.399 46.7115L164.412 46.5532Z'
            fill='white'
          />
          <path
            d='M164.634 46.5713L164.479 46.5586L164.466 46.7168L164.621 46.7296L164.634 46.5713Z'
            fill='white'
          />
          <path
            d='M164.856 46.5894L164.701 46.5767L164.688 46.7349L164.843 46.7476L164.856 46.5894Z'
            fill='white'
          />
          <path
            d='M165.078 46.6074L164.922 46.5947L164.909 46.753L165.065 46.7657L165.078 46.6074Z'
            fill='white'
          />
          <path
            d='M165.299 46.6255L165.144 46.6128L165.131 46.771L165.286 46.7838L165.299 46.6255Z'
            fill='white'
          />
          <path
            d='M165.52 46.6435L165.365 46.6309L165.353 46.7891L165.507 46.8018L165.52 46.6435Z'
            fill='white'
          />
          <path
            d='M165.742 46.6621L165.587 46.6494L165.574 46.8077L165.729 46.8204L165.742 46.6621Z'
            fill='white'
          />
          <path
            d='M165.942 46.6782L165.786 46.6655L165.773 46.8238L165.929 46.8365L165.942 46.6782Z'
            fill='white'
          />
          <path
            d='M165.997 46.8192C166.057 46.7843 166.112 46.7431 166.163 46.6963L166.008 46.6836L165.997 46.8192Z'
            fill='white'
          />
          <path
            d='M162.423 46.5952L162.443 46.6191L162.445 46.597L162.423 46.5952Z'
            fill='white'
          />
          <path
            d='M162.512 46.6025L162.506 46.6705C162.55 46.7128 162.6 46.7477 162.654 46.7738L162.667 46.6156L162.512 46.6025Z'
            fill='white'
          />
          <path
            d='M162.889 46.6333L162.734 46.6206L162.721 46.7789L162.876 46.7916L162.889 46.6333Z'
            fill='white'
          />
          <path
            d='M163.088 46.6494L162.933 46.6367L162.92 46.795L163.075 46.8077L163.088 46.6494Z'
            fill='white'
          />
          <path
            d='M163.31 46.6675L163.155 46.6548L163.142 46.813L163.297 46.8258L163.31 46.6675Z'
            fill='white'
          />
          <path
            d='M163.531 46.6856L163.376 46.6729L163.363 46.8311L163.519 46.8438L163.531 46.6856Z'
            fill='white'
          />
          <path
            d='M163.753 46.7041L163.598 46.6914L163.585 46.8497L163.74 46.8624L163.753 46.7041Z'
            fill='white'
          />
          <path
            d='M163.975 46.7222L163.82 46.7095L163.807 46.8677L163.962 46.8804L163.975 46.7222Z'
            fill='white'
          />
          <path
            d='M164.196 46.7402L164.042 46.7275L164.029 46.8858L164.184 46.8985L164.196 46.7402Z'
            fill='white'
          />
          <path
            d='M164.396 46.7563L164.241 46.7437L164.228 46.9019L164.383 46.9146L164.396 46.7563Z'
            fill='white'
          />
          <path
            d='M164.617 46.7749L164.462 46.7622L164.449 46.9205L164.604 46.9332L164.617 46.7749Z'
            fill='white'
          />
          <path
            d='M164.839 46.793L164.684 46.7803L164.671 46.9385L164.826 46.9512L164.839 46.793Z'
            fill='white'
          />
          <path
            d='M165.061 46.8111L164.906 46.7983L164.893 46.9566L165.048 46.9693L165.061 46.8111Z'
            fill='white'
          />
          <path
            d='M165.282 46.8291L165.127 46.8164L165.114 46.9747L165.27 46.9874L165.282 46.8291Z'
            fill='white'
          />
          <path
            d='M165.504 46.8472L165.349 46.8345L165.336 46.9927L165.491 47.0054L165.504 46.8472Z'
            fill='white'
          />
          <path
            d='M165.571 46.8528L165.558 47.0111L165.624 47.0165C165.67 46.9975 165.694 46.9767 165.738 46.9803L165.748 46.867L165.571 46.8528Z'
            fill='white'
          />
          <path
            d='M165.764 46.9371C165.81 46.9181 165.834 46.8972 165.88 46.8782L165.77 46.8691L165.764 46.9371Z'
            fill='white'
          />
          <path
            d='M162.739 46.8242L162.866 46.903L162.871 46.8351L162.739 46.8242Z'
            fill='white'
          />
          <path
            d='M162.917 46.8389L162.909 46.9295C162.952 46.9557 163.017 46.9839 163.059 47.0101L163.07 46.8745L162.917 46.8389Z'
            fill='white'
          />
          <path
            d='M163.293 46.8696L163.138 46.8569L163.125 47.0152L163.28 47.0279L163.293 46.8696Z'
            fill='white'
          />
          <path
            d='M163.515 46.8877L163.36 46.875L163.347 47.0333L163.502 47.046L163.515 46.8877Z'
            fill='white'
          />
          <path
            d='M163.737 46.9062L163.582 46.8936L163.569 47.0518L163.724 47.0645L163.737 46.9062Z'
            fill='white'
          />
          <path
            d='M163.958 46.9243L163.803 46.9116L163.79 47.0699L163.945 47.0826L163.958 46.9243Z'
            fill='white'
          />
          <path
            d='M164.18 46.9424L164.025 46.9297L164.012 47.0879L164.167 47.1006L164.18 46.9424Z'
            fill='white'
          />
          <path
            d='M164.379 46.9585L164.224 46.9458L164.211 47.1041L164.366 47.1167L164.379 46.9585Z'
            fill='white'
          />
          <path
            d='M164.601 46.9771L164.446 46.9644L164.433 47.1226L164.588 47.1353L164.601 46.9771Z'
            fill='white'
          />
          <path
            d='M164.822 46.9951L164.667 46.9824L164.655 47.1407L164.809 47.1534L164.822 46.9951Z'
            fill='white'
          />
          <path
            d='M165.044 47.0132L164.889 47.0005L164.876 47.1587L165.031 47.1715L165.044 47.0132Z'
            fill='white'
          />
          <path
            d='M165.111 47.0186L165.098 47.1768C165.148 47.1554 165.202 47.1443 165.257 47.1442L165.264 47.0536L165.111 47.0186Z'
            fill='white'
          />
          <path
            d='M165.332 47.0366L165.327 47.1046C165.373 47.0855 165.441 47.0683 165.487 47.0493L165.332 47.0366Z'
            fill='white'
          />
          <path
            d='M163.341 47.103C163.384 47.1293 163.45 47.1348 163.493 47.161L163.496 47.1157L163.341 47.103Z'
            fill='white'
          />
          <path
            d='M163.563 47.1211L163.559 47.1664C163.603 47.17 163.668 47.1981 163.712 47.2017L163.718 47.1338L163.563 47.1211Z'
            fill='white'
          />
          <path
            d='M163.784 47.1392L163.779 47.2071L163.934 47.2198L163.94 47.1519L163.784 47.1392Z'
            fill='white'
          />
          <path
            d='M164.006 47.1572L163.999 47.2478L164.154 47.2605L164.161 47.1699L164.006 47.1572Z'
            fill='white'
          />
          <path
            d='M164.206 47.1738L164.2 47.2418L164.355 47.2545L164.361 47.1865L164.206 47.1738Z'
            fill='white'
          />
          <path
            d='M164.427 47.1919L164.421 47.2598C164.474 47.2678 164.527 47.2644 164.579 47.2499L164.582 47.2046L164.427 47.1919Z'
            fill='white'
          />
          <path
            d='M164.649 47.21L164.645 47.2553C164.689 47.2589 164.758 47.2417 164.802 47.2453L164.647 47.2326L164.649 47.21Z'
            fill='white'
          />
        </g>
        <path
          d='M161.617 68.003L161.56 68.336V68.3937C161.56 68.3937 161.54 68.3745 161.54 68.3168C161.521 68.24 161.483 68.1887 161.444 68.1118C161.406 68.0542 161.387 68.003 161.367 67.9453L161.611 68.003H161.617Z'
          fill='#FFB798'
        />
        <path
          d='M161.617 68.003L161.56 68.336C161.56 68.336 161.56 68.3168 161.54 68.3168C161.521 68.24 161.483 68.1887 161.444 68.1118C161.406 68.0542 161.387 68.003 161.367 67.9453L161.611 68.003H161.617Z'
          fill='#FFB798'
        />
        <path
          d='M118.944 99.9528C118.944 99.9528 119.924 97.2369 121.724 95.2385C123.531 93.2464 127.848 89.5376 128.232 88.4872C128.636 87.3982 128.476 86.1236 129.174 86.0915C130.455 86.0339 131.448 88.0324 131.006 90.0885C130.589 92.0358 128.533 94.2136 128.828 94.8669C129.129 95.5203 132.607 94.3609 138.282 92.9197C143.733 91.5361 145.738 91.6514 145.828 93.2208C145.898 94.4634 142.106 95.3217 139.384 96.1801C136.662 97.0448 134.804 97.7622 134.804 97.7622C134.804 97.7622 137.219 97.506 137.725 99.4532C138.231 101.4 136.803 101.798 136.803 101.798C136.803 101.798 137.731 102.457 137.815 103.662C137.898 104.866 136.482 105.59 136.482 105.59C136.482 105.59 137.302 106.858 136.111 108.088C134.945 109.285 130.019 110.137 128.687 110.368C127.355 110.599 120.879 110.022 120.879 110.022L118.932 99.9464L118.944 99.9528Z'
          fill='#FFB798'
        />
        <path
          d='M28.1438 22.5487L14.7009 31.4363C13.7793 32.0705 12.4873 31.8209 11.8253 30.8518L6.88563 23.4074C6.25145 22.4858 6.54854 21.166 7.49287 20.58L20.9358 11.6924C21.8574 11.0583 23.1494 11.3079 23.8113 12.2769L28.751 19.7214C29.3378 20.6708 29.0881 21.9627 28.1438 22.5487Z'
          fill='#87AA5F'
        />
        <path
          d='M28.2617 21.9942L14.8188 30.8818C13.8972 31.516 12.6052 31.2664 11.9432 30.2973L7.00352 22.8529C6.36934 21.9313 6.61898 20.6393 7.58802 19.9773L21.1289 11.1576C22.0505 10.5234 23.3425 10.7731 24.0044 11.7421L28.8689 19.1669C29.4556 20.1163 29.206 21.4082 28.2617 21.9942Z'
          fill='#A2C97C'
        />
        <path
          d='M27.3317 21.303L14.7426 29.6544C14.1175 30.0633 13.2821 29.8637 12.896 29.2868L8.39221 22.5099C7.98406 21.8898 8.18288 21.0494 8.80797 20.6405L21.3972 12.2891C22.0222 11.8802 22.8576 12.0798 23.2437 12.6567L27.7475 19.4337C28.1557 20.0537 27.9568 20.8941 27.3317 21.303Z'
          fill='#BEDB8B'
        />
        <path
          d='M8.11865 22.2358L9.01326 23.6189C10.1408 22.8093 10.4706 21.2265 9.68128 20.0288L9.63152 19.9382L8.43385 20.7275C7.90441 21.0859 7.76532 21.7056 8.12371 22.235L8.11865 22.2358Z'
          fill='#A2C97C'
        />
        <path
          d='M14.476 30.0454L15.8592 29.1508C15.0495 28.0233 13.4667 27.6935 12.269 28.4828L12.1784 28.5326L12.9677 29.7302C13.3465 30.1895 13.9663 30.3286 14.4753 30.0404L14.476 30.0454Z'
          fill='#A2C97C'
        />
        <path
          d='M28.1388 19.7001L27.2441 18.317C26.1167 19.1266 25.7869 20.7094 26.5761 21.9071L26.6259 21.9977L27.8236 21.2084C28.3056 20.8778 28.4643 20.1829 28.1337 19.7009L28.1388 19.7001Z'
          fill='#A2C97C'
        />
        <path
          d='M21.733 11.913L20.3498 12.8077C21.1595 13.9352 22.7423 14.2649 23.94 13.4757L24.0306 13.4259L23.2413 12.2282C22.9107 11.7462 22.2157 11.5875 21.7337 11.9181L21.733 11.913Z'
          fill='#A2C97C'
        />
        <path
          d='M16.4409 23.5448C16.2554 23.6501 16.0668 23.632 15.9615 23.4465L14.3047 20.9081C14.1993 20.7226 14.2445 20.5764 14.4029 20.4287L15.4421 19.7872C15.7861 19.5341 16.1177 19.4739 16.3808 19.5066C16.6446 19.5444 16.9099 19.6955 17.0928 20.019C17.2209 20.2527 17.3058 20.5083 17.2877 20.6969C17.2974 20.9329 17.1667 21.1281 16.9835 21.3518C17.1968 21.2939 17.3371 21.3348 17.5805 21.4427L18.2852 21.8375C18.3831 21.9053 18.4811 21.9732 18.4885 22.0908C18.4958 22.2084 18.4704 22.2794 18.3323 22.3569C18.2417 22.4067 18.1943 22.4345 18.119 22.4148C18.0489 22.3944 17.9736 22.3747 17.9035 22.3543L16.9276 21.8042C16.8297 21.7363 16.6843 21.6962 16.5937 21.746C16.476 21.7533 16.3804 21.8039 16.2898 21.8536L15.8984 22.1345L16.5604 23.1035C16.6657 23.289 16.6206 23.4352 16.4394 23.5347L16.4409 23.5448ZM15.5971 21.71L16.2653 21.2791C16.6567 20.9983 16.7851 20.6847 16.5598 20.3882C16.3345 20.0917 16.043 20.0065 15.6092 20.3144L14.9409 20.7453L15.6029 21.7143L15.5971 21.71Z'
          fill='white'
        />
        <path
          d='M19.9225 22.3916C19.737 22.4969 19.5909 22.4518 19.4431 22.2933L17.8368 19.8506C17.7315 19.6651 17.7766 19.5189 17.9351 19.3712C18.1205 19.2658 18.2667 19.311 18.4144 19.4694L18.4642 19.56C18.4341 19.3942 18.4742 19.2488 18.5625 19.0806C18.6507 18.9125 18.761 18.7875 18.9041 18.7092C19.0896 18.6039 19.2758 18.5036 19.4636 18.5166C19.6514 18.5297 19.8728 18.5945 20.041 18.6828C20.2369 18.8185 20.3846 18.9769 20.5601 19.1829C20.6882 19.4165 20.8206 19.6444 20.8302 19.8804C20.8881 20.0937 20.8276 20.3093 20.7394 20.4774C20.6511 20.6456 20.5204 20.8408 20.3349 20.9461C20.1969 21.0236 20.0114 21.129 19.8652 21.0838C19.6994 21.1139 19.554 21.0739 19.3858 20.9856L19.922 21.8393C20.1253 22.0927 20.1072 22.2812 19.9218 22.3866L19.9225 22.3916ZM19.9388 20.6804C20.1242 20.5751 20.2125 20.407 20.2256 20.2192C20.2437 20.0306 20.1858 19.8173 20.0527 19.5844C19.9196 19.3515 19.7294 19.22 19.5562 19.1325C19.4108 19.0924 19.2223 19.0743 19.0646 19.2271C18.8791 19.3324 18.7908 19.5006 18.7777 19.6884C18.7647 19.8762 18.8175 20.0902 18.9507 20.3232C19.0838 20.5561 19.2739 20.6875 19.4471 20.775C19.5925 20.8151 19.7583 20.785 19.9388 20.6804Z'
          fill='white'
        />
        <path
          d='M212.285 57.8244C212.202 57.3628 212.118 56.9431 211.992 56.4815C211.866 56.0619 211.74 55.6842 211.572 55.2645C211.194 54.5091 210.565 54.0475 209.768 53.8377C209.096 53.6278 208.467 53.5019 207.795 53.3341C207.795 53.3341 207.837 53.3341 207.879 53.2921C208.551 53.1243 209.264 52.9984 209.935 52.7885C210.859 52.4948 211.488 51.9072 211.782 50.984C211.992 50.3545 212.118 49.725 212.285 49.0536C212.285 48.9696 212.327 48.9277 212.369 48.8438C212.411 48.9696 212.411 49.0955 212.453 49.2214C212.579 49.725 212.705 50.2706 212.831 50.7742C213.125 51.8233 213.838 52.5367 214.887 52.8305C215.517 52.9984 216.146 53.1243 216.776 53.2921C216.818 53.2921 216.86 53.2921 216.902 53.3341C216.44 53.418 216.02 53.5019 215.643 53.6278C215.223 53.7537 214.845 53.8796 214.468 54.0055C213.586 54.3832 213.083 55.0547 212.831 55.936C212.663 56.4815 212.537 57.0271 212.411 57.5726C212.327 57.6565 212.327 57.7405 212.285 57.8244Z'
          fill='#FAE78D'
        />
        <path
          d='M212.202 50.6899C212.202 50.6899 211.656 53.04 209.684 53.1659C209.684 53.1659 212.244 53.5856 212.202 50.6899Z'
          fill='white'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_267_17128'
          x1={152.662}
          y1={116.069}
          x2={151.798}
          y2={22.5119}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset={1} stopColor='white' stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id='paint1_linear_267_17128'
          x1={72.3957}
          y1={49.4229}
          x2={82.5902}
          y2={84.5581}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A286F1' />
          <stop offset={1} stopColor='#7142EB' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_267_17128'
          x1={74.1271}
          y1={55.417}
          x2={80.8442}
          y2={78.5676}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#A286F1' />
          <stop offset={1} stopColor='#7142EB' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_267_17128'
          x1={216.406}
          y1={57.3631}
          x2={202.824}
          y2={98.6472}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#ED7B6D' />
          <stop offset={1} stopColor='#EA4631' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_267_17128'
          x1={204.923}
          y1={92.3703}
          x2={214.231}
          y2={64.0769}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EC8A74' />
          <stop offset={1} stopColor='#EA6A6A' />
        </linearGradient>
        <clipPath id='clip0_267_17128'>
          <path
            d='M0 6.62247C0 2.96471 2.9652 -0.000488281 6.62295 -0.000488281H289.377C293.035 -0.000488281 296 2.96471 296 6.62246V93.6877C296 97.3454 293.035 100.311 289.377 100.311H6.62296C2.96521 100.311 0 97.3454 0 93.6877V6.62247Z'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_267_17128'>
          <rect
            width={78.9435}
            height={76.9918}
            fill='white'
            transform='translate(134.115 9.10596)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BannerIllustration;
