import { SVGProps } from 'react';

const ExclamationCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='#404040'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M12.5256 12.5255C13.7258 11.3253 14.4001 9.69741 14.4001 8.00002C14.4001 6.30264 13.7258 4.67477 12.5256 3.47454C11.3253 2.27431 9.69748 1.60002 8.0001 1.60002C6.30271 1.60002 4.67485 2.27431 3.47461 3.47454C2.27438 4.67477 1.6001 6.30264 1.6001 8.00002C1.6001 9.69741 2.27438 11.3253 3.47461 12.5255C4.67485 13.7257 6.30271 14.4 8.0001 14.4C9.69748 14.4 11.3253 13.7257 12.5256 12.5255ZM8.56578 11.7657C8.41575 11.9157 8.21227 12 8.0001 12C7.78792 12 7.58444 11.9157 7.43441 11.7657C7.28438 11.6157 7.2001 11.4122 7.2001 11.2C7.2001 10.9878 7.28438 10.7844 7.43441 10.6343C7.58444 10.4843 7.78792 10.4 8.0001 10.4C8.21227 10.4 8.41575 10.4843 8.56578 10.6343C8.71581 10.7844 8.8001 10.9878 8.8001 11.2C8.8001 11.4122 8.71581 11.6157 8.56578 11.7657ZM7.43441 4.23434C7.58444 4.08431 7.78792 4.00002 8.0001 4.00002C8.21227 4.00002 8.41575 4.08431 8.56578 4.23434C8.71581 4.38437 8.8001 4.58785 8.8001 4.80002V8.00002C8.8001 8.21219 8.71581 8.41568 8.56578 8.56571C8.41575 8.71574 8.21227 8.80002 8.0001 8.80002C7.78792 8.80002 7.58444 8.71574 7.43441 8.56571C7.28438 8.41568 7.2001 8.21219 7.2001 8.00002V4.80002C7.2001 4.58785 7.28438 4.38437 7.43441 4.23434Z' />
  </svg>
);
export default ExclamationCircle;
